import React from 'react';
import { PublishTimeData } from '../../helpers/types';
import PublishTime from '../Graphs/PublishTime';
import PublishTimeModal from '../Modal/PublishTimeModal';
import Card from './Card';

const PublishTimeCard = () => {
    const data: PublishTimeData = {
        publishTime: 17,
        lastMonthPublishTime: 1,
        graphPublishTime: [
            {
                publishTime: 4000,
                quarter: 4,
                year: 2022,
                carAmount: 12,
            },
            {
                publishTime: 1500,
                quarter: 1,
                year: 2023,
                carAmount: 120,
            },
            {
                publishTime: 324,
                quarter: 2,
                year: 2023,
                carAmount: 1,
            },
            {
                publishTime: 1290,
                quarter: 3,
                year: 2023,
                carAmount: 300,
            },
            {
                publishTime: 1500,
                quarter: 4,
                year: 2023,
                carAmount: 133,
            },
        ],
    };

    return (
        <Card title="Publiseringstid" subTitle="" hasData={true} isLoading={false} toolTipModal={<PublishTimeModal />}>
            {' '}
            <div className="mx-16">
                <h3 className="font-bold m-0">
                    <span>{data.publishTime}</span> dager
                </h3>
                <p className="text-12">
                    {data.lastMonthPublishTime > 0 ? '+' : '-'}
                    {data.lastMonthPublishTime} dag siste 30 dager
                </p>
                <p className="text-12">
                    Forventet publiseringstid for en forhandler er i snitt 1 dag lenger enn for tre måneder siden.
                </p>
            </div>
            <PublishTime data={data} />
        </Card>
    );
};

export default PublishTimeCard;
