import React from 'react';
import PopularityGraph from '../Graphs/PopularityGraph';
import { numberWithSpaces } from '../../helpers/functions';
import { PopularityData } from '../../helpers/types';
import Card from './Card';

const MarketPopularity = ({ popularityYesterday, lastSevenDays, adViews, favourites }: PopularityData) => (
    <Card title="Popularitet på FINN.no" subTitle="" hasData={true} isLoading={false}>
        <div className="flex flex-col mx-16 justify-between h-full">
            <div>
                <p className="text-22 font-bold m-0">
                    <span className="text-blue-500">{numberWithSpaces(popularityYesterday)}</span> visninger
                </p>
                <p className="text-12 mb-12">
                    <span style={{ color: '#238D8E' }}>+{numberWithSpaces(lastSevenDays)}</span> siste sju dager
                </p>
                <p className="text-12">Antall visninger en bil har hatt i snitt den siste uken.</p>
            </div>
            {
                <div className="flex flex-col h-fit mb-20">
                    <PopularityGraph
                        interestValue={adViews.value}
                        marketSubject={adViews.title}
                        fromAvgValue={adViews.avgValue}
                        interestPercentage={adViews.percentage}
                    />
                    <PopularityGraph
                        interestValue={favourites.value}
                        marketSubject={favourites.title}
                        fromAvgValue={favourites.avgValue}
                        interestPercentage={favourites.percentage}
                    />
                </div>
            }
        </div>
    </Card>
);

export default MarketPopularity;
