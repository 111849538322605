import { Button } from '@fabric-ds/react';
import { IconClose16 } from '@fabric-ds/icons/react';
import React, { useState } from 'react';
import InfoModal from './InfoModal';

const PublishTimeModal = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <InfoModal isOpen={isOpen} setIsOpen={setIsOpen} name="publish-time">
            <div className="w-full flex flex-row mb-4">
                <Button
                    pill
                    small
                    className="button ml-auto my-0 mr-0 w-16"
                    aria-label="Close"
                    onClick={() => setIsOpen(false)}
                >
                    <IconClose16 />
                </Button>
            </div>
            <p className="mb-24 text-14">
                For publiseringstid bruker vi median basert på bruktbiler fra forhandlere siste 3 måneder.{' '}
            </p>
            <p className="mb-16 text-14">Grafen viser trend siste år, fordelt på siste fem kvartaler.</p>

            <p className="flex flex-row text-12 m-0 items-center">
                <span className="mi-podlet-legend mi-podlet-dealers" />
                Forhandlere
            </p>
        </InfoModal>
    );
};

export default PublishTimeModal;
