import { VtSchema } from '../helpers/types';

export const defaultVtSchema: VtSchema = {
    name: 'car-used',
    version: '0.5.6',
    legacy_type: 20,
    fields: [
        {
            default: false,
            label: 'Bytterett',
            'legacy-name': 'RIGHT_TO_EXCHANGE',
            name: 'right_to_exchange',
            type: 'boolean',
        },
        {
            label: 'Registreringsnummer',
            'legacy-name': 'REGNO',
            name: 'registration_number',
            type: 'string',
        },
        {
            label: 'Chassis-/understellsnummer',
            'legacy-name': 'CHASSIS_NO',
            name: 'chassis_number',
            type: 'string',
        },
        {
            label: 'Modellår',
            'legacy-name': 'YEAR_MODEL',
            name: 'year',
            type: 'number',
        },
        {
            label: 'Merke og modell',
            name: 'make_and_model',
            options: [
                {
                    label: 'Abarth',
                    legacy_id: 8093,
                    options: [
                        {
                            label: '124 Spider',
                            legacy_id: 2000412,
                            value: '124SPIDER',
                        },
                        {
                            label: '500',
                            legacy_id: 2000436,
                            value: '500',
                        },
                        {
                            label: '595',
                            legacy_id: 2000413,
                            value: '595',
                        },
                        {
                            label: '695',
                            legacy_id: 2000414,
                            value: '695',
                        },
                    ],
                    value: 'ABARTH',
                },
                {
                    label: 'Addax',
                    legacy_id: 8105,
                    options: [
                        {
                            label: 'MT',
                            legacy_id: 2000548,
                            value: 'MT',
                        },
                        {
                            label: 'MTN',
                            legacy_id: 2000549,
                            value: 'MTN',
                        },
                    ],
                    value: 'Addax',
                },
                {
                    label: 'Alfa Romeo',
                    legacy_id: 3233,
                    options: [
                        {
                            label: '145',
                            legacy_id: 2000083,
                            value: '145',
                        },
                        {
                            label: '147',
                            legacy_id: 7176,
                            value: '147',
                        },
                        {
                            label: '155',
                            legacy_id: 7348,
                            value: '155',
                        },
                        {
                            label: '156',
                            legacy_id: 3730,
                            value: '156',
                        },
                        {
                            label: '159',
                            legacy_id: 8169,
                            value: '159',
                        },
                        {
                            label: '159 SportWagon',
                            legacy_id: 6735,
                            value: 'SPORTWAGON',
                        },
                        {
                            label: '164',
                            legacy_id: 3243,
                            value: '164',
                        },
                        {
                            label: '166',
                            legacy_id: 3729,
                            value: '166',
                        },
                        {
                            label: '33',
                            legacy_id: 3236,
                            value: '33',
                        },
                        {
                            label: '4C',
                            legacy_id: 2000289,
                            value: '4c',
                        },
                        {
                            label: '75',
                            legacy_id: 3235,
                            value: '75',
                        },
                        {
                            label: '90',
                            legacy_id: 3244,
                            value: '90',
                        },
                        {
                            label: 'Alfasud',
                            legacy_id: 3241,
                            value: 'ALFASUD',
                        },
                        {
                            label: 'Alfetta',
                            legacy_id: 3240,
                            value: 'ALFETTA',
                        },
                        {
                            label: 'Brera',
                            legacy_id: 8251,
                            value: 'brera',
                        },
                        {
                            label: 'GT',
                            legacy_id: 8082,
                            value: 'GT',
                        },
                        {
                            label: 'GTV',
                            legacy_id: 3239,
                            value: 'GTV',
                        },
                        {
                            label: 'Giulia',
                            legacy_id: 2000373,
                            value: 'Giulia',
                        },
                        {
                            label: 'Giulia Quadrifoglio',
                            legacy_id: 2000535,
                            value: 'GIULIA-QUADRIFOGLIO',
                        },
                        {
                            label: 'Giulietta',
                            legacy_id: 3785,
                            value: 'GIULIETTA',
                        },
                        {
                            label: 'Giulietta (gml. type)',
                            legacy_id: 2000147,
                            value: 'GIULIETTA (GML. TYPE)',
                        },
                        {
                            label: 'MiTo',
                            legacy_id: 2000094,
                            value: 'mito',
                        },
                        {
                            label: 'Spider',
                            legacy_id: 3238,
                            value: 'SPIDER',
                        },
                        {
                            label: 'Sprint',
                            legacy_id: 3237,
                            value: 'SPRINT',
                        },
                        {
                            label: 'Stelvio',
                            legacy_id: 2000407,
                            value: 'STELVIO',
                        },
                        {
                            label: 'Stelvio Quadrifoglio',
                            legacy_id: 2000502,
                            value: 'STELVIOQUADRIFOGLIO',
                        },
                        {
                            label: 'Tonale',
                            legacy_id: 2000597,
                            value: 'TONALE',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 3234,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ALFA ROMEO',
                },
                {
                    label: 'Alpina',
                    legacy_id: 8092,
                    options: [
                        {
                            label: 'A110',
                            legacy_id: 2000577,
                            value: 'A110',
                        },
                        {
                            label: 'B10',
                            legacy_id: 2000355,
                            value: 'b10',
                        },
                        {
                            label: 'B12',
                            legacy_id: 2000356,
                            value: 'b12',
                        },
                        {
                            label: 'B3',
                            legacy_id: 2000357,
                            value: 'B3',
                        },
                        {
                            label: 'B4',
                            legacy_id: 2000358,
                            value: 'b4',
                        },
                        {
                            label: 'B5',
                            legacy_id: 2000359,
                            value: 'b5',
                        },
                        {
                            label: 'B6',
                            legacy_id: 2000360,
                            value: 'b6',
                        },
                        {
                            label: 'B7',
                            legacy_id: 2000361,
                            value: 'b7',
                        },
                        {
                            label: 'B8',
                            legacy_id: 2000362,
                            value: 'b8',
                        },
                        {
                            label: 'D10',
                            legacy_id: 2000363,
                            value: 'd10',
                        },
                        {
                            label: 'D3',
                            legacy_id: 2000364,
                            value: 'd3',
                        },
                        {
                            label: 'D4',
                            legacy_id: 2000365,
                            value: 'd4',
                        },
                        {
                            label: 'D5',
                            legacy_id: 2000366,
                            value: 'd5',
                        },
                        {
                            label: 'Roadster S',
                            legacy_id: 2000367,
                            value: 'roadster s',
                        },
                        {
                            label: 'XD3',
                            legacy_id: 2000368,
                            value: 'xd3',
                        },
                    ],
                    value: 'alpina',
                },
                {
                    label: 'Alpine',
                    legacy_id: 824,
                    options: [
                        {
                            label: 'A110',
                            legacy_id: 8404,
                            value: 'a110',
                        },
                    ],
                    value: 'alpine',
                },
                {
                    label: 'AMC',
                    legacy_id: 8103,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 8374,
                            value: 'andre',
                        },
                    ],
                    value: 'AMC',
                },
                {
                    label: 'Ariel',
                    legacy_id: 8110,
                    options: [
                        {
                            label: 'Atom',
                            legacy_id: 2000574,
                            value: 'ATOM',
                        },
                    ],
                    value: 'ARIEL',
                },
                {
                    label: 'Aston Martin',
                    legacy_id: 6733,
                    options: [
                        {
                            label: 'Cygnet',
                            legacy_id: 2000262,
                            value: 'cygnet',
                        },
                        {
                            label: 'DB7',
                            legacy_id: 8318,
                            value: 'db7',
                        },
                        {
                            label: 'DB9',
                            legacy_id: 8319,
                            value: 'db9',
                        },
                        {
                            label: 'DB11',
                            legacy_id: 8331,
                            value: 'DB11',
                        },
                        {
                            label: 'DB11 Volante',
                            legacy_id: 8332,
                            value: 'DB11 Volante',
                        },
                        {
                            label: 'DBS Superleggera',
                            legacy_id: 2000521,
                            value: 'DBS_SUPERLEGGERA',
                        },
                        {
                            label: 'DBX',
                            legacy_id: 8322,
                            value: 'DBX',
                        },
                        {
                            label: 'Rapide',
                            legacy_id: 2000257,
                            value: 'rapide',
                        },
                        {
                            label: 'V12 Vantage',
                            legacy_id: 2000345,
                            value: 'v12 vantage',
                        },
                        {
                            label: 'V8 Vantage',
                            legacy_id: 8320,
                            value: 'v8 vantage',
                        },
                        {
                            label: 'Vanquish',
                            legacy_id: 8321,
                            value: 'vanquish',
                        },
                        {
                            label: 'Virage',
                            legacy_id: 2000522,
                            value: 'VIRAGE',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7107,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ASTON MARTIN',
                },
                {
                    label: 'Audi',
                    legacy_id: 744,
                    options: [
                        {
                            label: 'A1',
                            legacy_id: 2000166,
                            value: 'a1',
                        },
                        {
                            label: 'A2',
                            legacy_id: 6720,
                            value: 'A2',
                        },
                        {
                            label: 'A3',
                            legacy_id: 2046,
                            value: 'A3',
                        },
                        {
                            label: 'A4',
                            legacy_id: 839,
                            value: 'A4',
                        },
                        {
                            label: 'A4 allroad',
                            legacy_id: 2000140,
                            value: 'a4 allroad',
                        },
                        {
                            label: 'A5',
                            legacy_id: 8314,
                            value: 'a5',
                        },
                        {
                            label: 'A6',
                            legacy_id: 840,
                            value: 'A6',
                        },
                        {
                            label: 'A6 allroad',
                            legacy_id: 6736,
                            value: 'ALLROAD',
                        },
                        {
                            label: 'A7',
                            legacy_id: 2000174,
                            value: 'a7',
                        },
                        {
                            label: 'A8',
                            legacy_id: 841,
                            value: 'A8',
                        },
                        {
                            label: 'Q2',
                            legacy_id: 2000400,
                            value: 'Q2',
                        },
                        {
                            label: 'Q3',
                            legacy_id: 2000190,
                            value: 'q3',
                        },
                        {
                            label: 'Q4 e-tron',
                            legacy_id: 2000541,
                            value: 'Q4 e-tron',
                        },
                        {
                            label: 'Q5',
                            legacy_id: 2000097,
                            value: 'q5',
                        },
                        {
                            label: 'Q7',
                            legacy_id: 8149,
                            value: 'q7',
                        },
                        {
                            label: 'Q8',
                            legacy_id: 2000455,
                            value: 'Q8',
                        },
                        {
                            label: 'R8',
                            legacy_id: 8340,
                            value: 'R8',
                        },
                        {
                            label: 'RS2',
                            legacy_id: 7947,
                            value: 'rs2',
                        },
                        {
                            label: 'RS3',
                            legacy_id: 2000184,
                            value: 'rs3',
                        },
                        {
                            label: 'RS4',
                            legacy_id: 6721,
                            value: 'RS4',
                        },
                        {
                            label: 'RS5',
                            legacy_id: 2000185,
                            value: 'rs5',
                        },
                        {
                            label: 'RS6',
                            legacy_id: 7709,
                            value: 'RS6',
                        },
                        {
                            label: 'RS7',
                            legacy_id: 2000291,
                            value: 'rs7',
                        },
                        {
                            label: 'RSQ3',
                            legacy_id: 2000454,
                            value: 'RSQ3',
                        },
                        {
                            label: 'RSQ8',
                            legacy_id: 8344,
                            value: 'RS_Q8',
                        },
                        {
                            label: 'S1',
                            legacy_id: 2000304,
                            value: 's1',
                        },
                        {
                            label: 'S2',
                            legacy_id: 7557,
                            value: 'S2',
                        },
                        {
                            label: 'S3',
                            legacy_id: 3731,
                            value: 'S3',
                        },
                        {
                            label: 'S4',
                            legacy_id: 2891,
                            value: 'S4',
                        },
                        {
                            label: 'S5',
                            legacy_id: 2000082,
                            value: 's5',
                        },
                        {
                            label: 'S6',
                            legacy_id: 843,
                            value: 'S6',
                        },
                        {
                            label: 'S7',
                            legacy_id: 2000246,
                            value: 's7',
                        },
                        {
                            label: 'S8',
                            legacy_id: 3786,
                            value: 'S8',
                        },
                        {
                            label: 'SQ5',
                            legacy_id: 2000296,
                            value: 'sq5',
                        },
                        {
                            label: 'SQ7',
                            legacy_id: 2000402,
                            value: 'SQ7',
                        },
                        {
                            label: 'SQ8',
                            legacy_id: 8343,
                            value: 'SQ8',
                        },
                        {
                            label: 'TT',
                            legacy_id: 3732,
                            value: 'TT',
                        },
                        {
                            label: 'V8',
                            legacy_id: 8313,
                            value: 'v8',
                        },
                        {
                            label: 'e-tron',
                            legacy_id: 2000503,
                            value: 'e-tron',
                        },
                        {
                            label: 'e-tron GT RS',
                            legacy_id: 2000539,
                            value: 'e-tron GT RS',
                        },
                        {
                            label: 'e-tron GT',
                            legacy_id: 2000540,
                            value: 'e-tron GT',
                        },
                        {
                            label: 'e-tron Sportback',
                            legacy_id: 8373,
                            value: 'e-tron Sportback',
                        },
                        {
                            label: '50',
                            legacy_id: 835,
                            value: '50',
                        },
                        {
                            label: '60',
                            legacy_id: 836,
                            value: '60',
                        },
                        {
                            label: '80',
                            legacy_id: 837,
                            value: '80',
                        },
                        {
                            label: '90',
                            legacy_id: 838,
                            value: '90',
                        },
                        {
                            label: '100',
                            legacy_id: 833,
                            value: '100',
                        },
                        {
                            label: '200',
                            legacy_id: 834,
                            value: '200',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2053,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'AUDI',
                },
                {
                    label: 'Austin',
                    legacy_id: 8076,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000180,
                            value: 'andre',
                        },
                        {
                            label: 'Healey 100 6',
                            legacy_id: 2000323,
                            value: 'healey 100 6',
                        },
                        {
                            label: 'Healey 3000',
                            legacy_id: 2000127,
                            value: 'healy 3000',
                        },
                        {
                            label: 'Princess',
                            legacy_id: 2000320,
                            value: 'princess',
                        },
                    ],
                    value: 'austin',
                },
                {
                    label: 'BMW',
                    legacy_id: 749,
                    options: [
                        {
                            label: '1-serie',
                            legacy_id: 7967,
                            value: '1-serie',
                        },
                        {
                            label: '1M',
                            legacy_id: 2000204,
                            value: 'm1',
                        },
                        {
                            label: '2-serie',
                            legacy_id: 2000283,
                            value: '2serie',
                        },
                        {
                            label: '3-serie',
                            legacy_id: 2132,
                            value: '3-SERIE',
                        },
                        {
                            label: '4-serie',
                            legacy_id: 2000265,
                            value: '4_serie',
                        },
                        {
                            label: '5-serie',
                            legacy_id: 2131,
                            value: '5-SERIE',
                        },
                        {
                            label: '6-serie',
                            legacy_id: 3004,
                            value: '6-SERIE',
                        },
                        {
                            label: '7-serie',
                            legacy_id: 2130,
                            value: '7-SERIE',
                        },
                        {
                            label: '8-serie',
                            legacy_id: 2129,
                            value: '8-SERIE',
                        },
                        {
                            label: '3-serie GT',
                            legacy_id: 2000529,
                            value: '3-SERIE-GT',
                        },
                        {
                            label: '5-serie GT',
                            legacy_id: 2000530,
                            value: '5-SERIE-GT',
                        },
                        {
                            label: 'M2',
                            legacy_id: 2000370,
                            value: 'M2',
                        },
                        {
                            label: 'M3',
                            legacy_id: 893,
                            value: 'M3',
                        },
                        {
                            label: 'M4',
                            legacy_id: 2000295,
                            value: 'm4',
                        },
                        {
                            label: 'M5',
                            legacy_id: 2133,
                            value: 'M5',
                        },
                        {
                            label: 'M6',
                            legacy_id: 8288,
                            value: 'M6',
                        },
                        {
                            label: 'X1',
                            legacy_id: 2000133,
                            value: 'x1',
                        },
                        {
                            label: 'X2',
                            legacy_id: 2000440,
                            value: 'X2',
                        },
                        {
                            label: 'X3',
                            legacy_id: 7798,
                            value: 'X3',
                        },
                        {
                            label: 'X3 M',
                            legacy_id: 8358,
                            value: 'X3M',
                        },
                        {
                            label: 'X4',
                            legacy_id: 2000294,
                            value: 'x4',
                        },
                        {
                            label: 'X4 M',
                            legacy_id: 8359,
                            value: 'X4M',
                        },
                        {
                            label: 'X5',
                            legacy_id: 6737,
                            value: 'X5',
                        },
                        {
                            label: 'X5 M',
                            legacy_id: 8360,
                            value: 'X5M',
                        },
                        {
                            label: 'X6',
                            legacy_id: 2000085,
                            value: 'x6',
                        },
                        {
                            label: 'X6 M',
                            legacy_id: 8361,
                            value: 'X6M',
                        },
                        {
                            label: 'X7',
                            legacy_id: 2000513,
                            value: 'X7',
                        },
                        {
                            label: 'XM',
                            legacy_id: 2000614,
                            value: 'BMW_XM',
                        },
                        {
                            label: 'M8',
                            legacy_id: 8362,
                            value: 'M8',
                        },
                        {
                            label: 'Z1',
                            legacy_id: 2000034,
                            value: 'Z1',
                        },
                        {
                            label: 'Z3',
                            legacy_id: 3003,
                            value: 'Z3',
                        },
                        {
                            label: 'Z4',
                            legacy_id: 7683,
                            value: 'Z4',
                        },
                        {
                            label: 'Z8',
                            legacy_id: 6716,
                            value: 'Z8',
                        },
                        {
                            label: 'i3',
                            legacy_id: 2000264,
                            value: 'i3',
                        },
                        {
                            label: 'i4',
                            legacy_id: 8308,
                            value: 'I4',
                        },
                        {
                            label: 'i4 M50',
                            legacy_id: 2000557,
                            value: 'I4-M50',
                        },
                        {
                            label: 'i7',
                            legacy_id: 2000595,
                            value: 'I7',
                        },
                        {
                            label: 'i8',
                            legacy_id: 2000309,
                            value: 'i8',
                        },
                        {
                            label: 'iX1',
                            legacy_id: 2000594,
                            value: 'IX1',
                        },
                        {
                            label: 'iX3',
                            legacy_id: 2000532,
                            value: 'IX3',
                        },
                        {
                            label: 'iX40',
                            legacy_id: 8309,
                            value: 'IX40',
                        },
                        {
                            label: 'iX M60',
                            legacy_id: 2000566,
                            value: 'IX-M60',
                        },
                        {
                            label: 'iX xDrive 40',
                            legacy_id: 8296,
                            value: 'IX XDRIVE 40',
                        },
                        {
                            label: 'iX xDrive 50',
                            legacy_id: 8297,
                            value: 'IX XDRIVE 50',
                        },
                        {
                            label: 'iX xDrive M60',
                            legacy_id: 8381,
                            value: 'IX_XDRIVE_M60',
                        },
                        {
                            label: '1500',
                            legacy_id: 862,
                            value: '1500',
                        },
                        {
                            label: '1502',
                            legacy_id: 863,
                            value: '1502',
                        },
                        {
                            label: '1600',
                            legacy_id: 864,
                            value: '1600',
                        },
                        {
                            label: '1602',
                            legacy_id: 865,
                            value: '1602',
                        },
                        {
                            label: '1800',
                            legacy_id: 866,
                            value: '1800',
                        },
                        {
                            label: '2000',
                            legacy_id: 867,
                            value: '2000',
                        },
                        {
                            label: '2002',
                            legacy_id: 868,
                            value: '2002',
                        },
                        {
                            label: '2500',
                            legacy_id: 869,
                            value: '2500',
                        },
                        {
                            label: '2800',
                            legacy_id: 870,
                            value: '2800',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2058,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'BMW',
                },
                {
                    label: 'Bentley',
                    legacy_id: 7166,
                    options: [
                        {
                            label: 'Arnage',
                            legacy_id: 7170,
                            value: 'ARNAGE',
                        },
                        {
                            label: 'Bentayga',
                            legacy_id: 2000397,
                            value: 'Bentayga',
                        },
                        {
                            label: 'Continental GT',
                            legacy_id: 7168,
                            value: 'CONTINENTAL',
                        },
                        {
                            label: 'Continental GTC',
                            legacy_id: 2000528,
                            value: 'CONTINENTAL_GTC',
                        },
                        {
                            label: 'Flying Spur',
                            legacy_id: 2000325,
                            value: 'flying spur',
                        },
                        {
                            label: 'Mulsanne',
                            legacy_id: 7167,
                            value: 'MULSANNE',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7169,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'BENTLEY',
                },
                {
                    label: 'Buddy',
                    legacy_id: 8066,
                    options: [
                        {
                            label: 'Basic',
                            legacy_id: 2000141,
                            value: 'buddy basic',
                        },
                        {
                            label: 'Citi-Jet 6',
                            legacy_id: 2000120,
                            value: 'citijet6',
                        },
                        {
                            label: 'Classic Basis',
                            legacy_id: 2000086,
                            value: 'buddybasis',
                        },
                        {
                            label: 'Classic Cab',
                            legacy_id: 2000088,
                            value: 'buddycab',
                        },
                        {
                            label: 'Classic Pluss',
                            legacy_id: 2000087,
                            value: 'buddypluss',
                        },
                        {
                            label: 'Pluss',
                            legacy_id: 2000143,
                            value: 'buddy pluss',
                        },
                        {
                            label: 'Premium',
                            legacy_id: 2000144,
                            value: 'buddy premium',
                        },
                    ],
                    value: 'buddy',
                },
                {
                    label: 'Buick',
                    legacy_id: 750,
                    options: [
                        {
                            label: 'Centurion',
                            legacy_id: 1488,
                            value: 'centurion',
                        },
                        {
                            label: 'Century',
                            legacy_id: 894,
                            value: 'CENTURY',
                        },
                        {
                            label: 'Le Sabre',
                            legacy_id: 895,
                            value: 'LE SABRE',
                        },
                        {
                            label: 'Park Avenue',
                            legacy_id: 896,
                            value: 'PARK AVENUE',
                        },
                        {
                            label: 'Regal',
                            legacy_id: 897,
                            value: 'REGAL',
                        },
                        {
                            label: 'Skylark',
                            legacy_id: 898,
                            value: 'SKYLARK',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2059,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'BUICK',
                },
                {
                    label: 'Bugatti',
                    legacy_id: 8111,
                    options: [
                        {
                            label: 'Chiron',
                            legacy_id: 2000586,
                            value: 'CHIRON',
                        },
                    ],
                    value: 'BUGATTI',
                },
                {
                    label: 'BYD',
                    legacy_id: 8101,
                    options: [
                        {
                            label: 'Atto3',
                            legacy_id: 2000592,
                            value: 'ATTO3',
                        },
                        {
                            label: 'Han',
                            legacy_id: 2000593,
                            value: 'HAN',
                        },
                        {
                            label: 'T3',
                            legacy_id: 8390,
                            value: 'T3',
                        },
                        {
                            label: 'Tang',
                            legacy_id: 8368,
                            value: 'Tang',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 8391,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'BYD',
                },
                {
                    label: 'Cadillac',
                    legacy_id: 752,
                    options: [
                        {
                            label: 'ATS',
                            legacy_id: 2000418,
                            value: 'ATS',
                        },
                        {
                            label: 'BLS',
                            legacy_id: 979,
                            value: 'BLS',
                        },
                        {
                            label: 'CTS',
                            legacy_id: 7987,
                            value: 'CTS',
                        },
                        {
                            label: 'Deville',
                            legacy_id: 900,
                            value: 'DEVILLE',
                        },
                        {
                            label: 'Eldorado',
                            legacy_id: 2000168,
                            value: 'eldorado',
                        },
                        {
                            label: 'Elr',
                            legacy_id: 8357,
                            value: 'ELR',
                        },
                        {
                            label: 'Escalade',
                            legacy_id: 7670,
                            value: 'ESCALADE',
                        },
                        {
                            label: 'Fleetwood',
                            legacy_id: 2000464,
                            value: 'FLEETWOOD',
                        },
                        {
                            label: 'SRX',
                            legacy_id: 7988,
                            value: 'SRX',
                        },
                        {
                            label: 'Seville',
                            legacy_id: 3787,
                            value: 'SEVILLE',
                        },
                        {
                            label: 'XLR',
                            legacy_id: 2000103,
                            value: 'xlr',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2061,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'CADILLAC',
                },
                {
                    label: 'Chevrolet',
                    legacy_id: 753,
                    options: [
                        {
                            label: 'Alero',
                            legacy_id: 3736,
                            value: 'ALERO',
                        },
                        {
                            label: 'Astro',
                            legacy_id: 901,
                            value: 'ASTRO',
                        },
                        {
                            label: 'Avalanche',
                            legacy_id: 7539,
                            value: 'AVALANCHE',
                        },
                        {
                            label: 'Aveo',
                            legacy_id: 2000098,
                            value: 'AVEO',
                        },
                        {
                            label: 'Beauville',
                            legacy_id: 902,
                            value: 'BEAUVILLE',
                        },
                        {
                            label: 'Bel Air',
                            legacy_id: 2000406,
                            value: 'BELAIR',
                        },
                        {
                            label: 'Beretta',
                            legacy_id: 903,
                            value: 'BERETTA',
                        },
                        {
                            label: 'Big Dooley',
                            legacy_id: 7624,
                            value: 'BIG DOOLEY',
                        },
                        {
                            label: 'Blazer',
                            legacy_id: 904,
                            value: 'BLAZER',
                        },
                        {
                            label: 'Bolt',
                            legacy_id: 2000410,
                            value: 'BOLT',
                        },
                        {
                            label: 'C-10',
                            legacy_id: 2000263,
                            value: 'c_10',
                        },
                        {
                            label: 'Camaro',
                            legacy_id: 905,
                            value: 'CAMARO',
                        },
                        {
                            label: 'Caprice',
                            legacy_id: 907,
                            value: 'CAPRICE',
                        },
                        {
                            label: 'Captiva',
                            legacy_id: 1486,
                            value: 'captiva',
                        },
                        {
                            label: 'Cargovan',
                            legacy_id: 2000053,
                            value: 'Cargovan',
                        },
                        {
                            label: 'Chevelle',
                            legacy_id: 3864,
                            value: 'chevelle',
                        },
                        {
                            label: 'Cheyenne',
                            legacy_id: 2000394,
                            value: 'Cheyenne',
                        },
                        {
                            label: 'Colorado',
                            legacy_id: 3828,
                            value: 'colorado',
                        },
                        {
                            label: 'Corsica',
                            legacy_id: 911,
                            value: 'CORSICA',
                        },
                        {
                            label: 'Corvair',
                            legacy_id: 8352,
                            value: 'CORVAIR',
                        },
                        {
                            label: 'Corvette',
                            legacy_id: 912,
                            value: 'CORVETTE',
                        },
                        {
                            label: 'Crew Cab',
                            legacy_id: 7541,
                            value: 'CREW CAB',
                        },
                        {
                            label: 'Cruze',
                            legacy_id: 2000233,
                            value: 'cruze',
                        },
                        {
                            label: 'El Camino',
                            legacy_id: 3862,
                            value: 'elcamino',
                        },
                        {
                            label: 'Epica',
                            legacy_id: 2000044,
                            value: 'epica',
                        },
                        {
                            label: 'Express',
                            legacy_id: 2000287,
                            value: 'express',
                        },
                        {
                            label: 'Extended Cab',
                            legacy_id: 7329,
                            value: 'EXTENDED CAB',
                        },
                        {
                            label: 'Impala',
                            legacy_id: 3860,
                            value: 'impala',
                        },
                        {
                            label: 'Kalos',
                            legacy_id: 8094,
                            value: 'kalos',
                        },
                        {
                            label: 'Korea',
                            legacy_id: 8113,
                            value: 'KOREA',
                        },
                        {
                            label: 'Lacetti',
                            legacy_id: 8095,
                            value: 'lacetti',
                        },
                        {
                            label: 'Lumina',
                            legacy_id: 3005,
                            value: 'LUMINA',
                        },
                        {
                            label: 'Malibu',
                            legacy_id: 913,
                            value: 'MALIBU',
                        },
                        {
                            label: 'Master',
                            legacy_id: 2000417,
                            value: 'MASTER',
                        },
                        {
                            label: 'Matiz',
                            legacy_id: 8093,
                            value: 'matiz',
                        },
                        {
                            label: 'Monte Carlo',
                            legacy_id: 914,
                            value: 'MONTE CARLO',
                        },
                        {
                            label: 'Nubira',
                            legacy_id: 8096,
                            value: 'nubira',
                        },
                        {
                            label: 'Orlando',
                            legacy_id: 2000225,
                            value: 'orlando',
                        },
                        {
                            label: 'Regular Cab',
                            legacy_id: 7542,
                            value: 'REGULAR CAB',
                        },
                        {
                            label: 'S-10',
                            legacy_id: 3733,
                            value: 'S-10',
                        },
                        {
                            label: 'Silverado',
                            legacy_id: 7540,
                            value: 'SILVERADO',
                        },
                        {
                            label: 'Spark',
                            legacy_id: 2000179,
                            value: 'spark',
                        },
                        {
                            label: 'Sport Van',
                            legacy_id: 915,
                            value: 'SPORT VAN',
                        },
                        {
                            label: 'Starcraft',
                            legacy_id: 916,
                            value: 'STARCRAFT',
                        },
                        {
                            label: 'Suburban',
                            legacy_id: 917,
                            value: 'SUBURBAN',
                        },
                        {
                            label: 'Tahoe',
                            legacy_id: 4338,
                            value: 'TAHOE',
                        },
                        {
                            label: 'Trailblazer',
                            legacy_id: 7543,
                            value: 'TRAILBLAZER',
                        },
                        {
                            label: 'Trans Sport',
                            legacy_id: 3734,
                            value: 'TRANS SPORT',
                        },
                        {
                            label: 'Trax',
                            legacy_id: 2000254,
                            value: 'trax',
                        },
                        {
                            label: 'Van',
                            legacy_id: 7330,
                            value: 'VAN',
                        },
                        {
                            label: 'Volt',
                            legacy_id: 2000182,
                            value: 'volt',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2062,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'CHEVROLET',
                },
                {
                    label: 'Chrysler',
                    legacy_id: 754,
                    options: [
                        {
                            label: '300 M',
                            legacy_id: 7174,
                            value: '300 M',
                        },
                        {
                            label: '300C',
                            legacy_id: 7945,
                            value: '300c',
                        },
                        {
                            label: 'Crossfire',
                            legacy_id: 7900,
                            value: 'crossfire',
                        },
                        {
                            label: 'Grand Voyager',
                            legacy_id: 918,
                            value: 'GRAND VOYAGER',
                        },
                        {
                            label: 'Le Baron',
                            legacy_id: 920,
                            value: 'LE BARON',
                        },
                        {
                            label: 'Neon',
                            legacy_id: 921,
                            value: 'NEON',
                        },
                        {
                            label: 'New Yorker',
                            legacy_id: 922,
                            value: 'NEW YORKER',
                        },
                        {
                            label: 'PT Cruiser',
                            legacy_id: 6738,
                            value: 'PT CRUISER',
                        },
                        {
                            label: 'Pacifica',
                            legacy_id: 7946,
                            value: 'pacifica',
                        },
                        {
                            label: 'Plymouth Premier',
                            legacy_id: 923,
                            value: 'PLYMOUTH PREMIER',
                        },
                        {
                            label: 'Plymouth Volare',
                            legacy_id: 924,
                            value: 'PLYMOUTH VOLARE',
                        },
                        {
                            label: 'Prowler',
                            legacy_id: 7622,
                            value: 'PROWLER',
                        },
                        {
                            label: 'Sebring',
                            legacy_id: 7173,
                            value: 'SEBRING',
                        },
                        {
                            label: 'Stratus',
                            legacy_id: 926,
                            value: 'STRATUS',
                        },
                        {
                            label: 'Vision',
                            legacy_id: 927,
                            value: 'VISION',
                        },
                        {
                            label: 'Voyager',
                            legacy_id: 928,
                            value: 'VOYAGER',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2063,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'CHRYSLER',
                },
                {
                    label: 'Citroen',
                    legacy_id: 757,
                    options: [
                        {
                            label: '2CV',
                            legacy_id: 937,
                            value: '2CV',
                        },
                        {
                            label: 'AX',
                            legacy_id: 939,
                            value: 'AX',
                        },
                        {
                            label: 'BX',
                            legacy_id: 940,
                            value: 'BX',
                        },
                        {
                            label: 'Berlingo',
                            legacy_id: 3255,
                            value: 'BERLINGO',
                        },
                        {
                            label: 'Berlingo Electrique',
                            legacy_id: 7768,
                            value: 'BERLINGO ELECTRIQUE',
                        },
                        {
                            label: 'C-Crosser',
                            legacy_id: 8339,
                            value: 'c-crosser',
                        },
                        {
                            label: 'C-Zero',
                            legacy_id: 2000187,
                            value: 'c-zero',
                        },
                        {
                            label: 'C1',
                            legacy_id: 8237,
                            value: 'C1',
                        },
                        {
                            label: 'C2',
                            legacy_id: 7793,
                            value: 'C2',
                        },
                        {
                            label: 'C3',
                            legacy_id: 7521,
                            value: 'C3',
                        },
                        {
                            label: 'C3 Aircross',
                            legacy_id: 2000446,
                            value: 'C3 Aircross',
                        },
                        {
                            label: 'C3 Picasso',
                            legacy_id: 2000116,
                            value: 'c3 picasso',
                        },
                        {
                            label: 'C4',
                            legacy_id: 7986,
                            value: 'C4',
                        },
                        {
                            label: 'C4 Aircross',
                            legacy_id: 2000211,
                            value: 'c4aircross',
                        },
                        {
                            label: 'C4 Cactus',
                            legacy_id: 2000292,
                            value: 'c4cactus',
                        },
                        {
                            label: 'C4 Picasso',
                            legacy_id: 1492,
                            value: 'c4picasso',
                        },
                        {
                            label: 'C5',
                            legacy_id: 7115,
                            value: 'C5',
                        },
                        {
                            label: 'C5 Aircross',
                            legacy_id: 2000514,
                            value: 'C5 Aircross',
                        },
                        {
                            label: 'C5 X',
                            legacy_id: 2000604,
                            value: 'C5X',
                        },
                        {
                            label: 'C6',
                            legacy_id: 8203,
                            value: 'C6',
                        },
                        {
                            label: 'C8',
                            legacy_id: 7660,
                            value: 'C8',
                        },
                        {
                            label: 'CX',
                            legacy_id: 943,
                            value: 'CX',
                        },
                        {
                            label: 'DS3',
                            legacy_id: 2000154,
                            value: 'ds3',
                        },
                        {
                            label: 'DS4',
                            legacy_id: 2000195,
                            value: 'ds4',
                        },
                        {
                            label: 'DS5',
                            legacy_id: 2000207,
                            value: 'ds5',
                        },
                        {
                            label: 'E-C4',
                            legacy_id: 2000544,
                            value: 'E-C4',
                        },
                        {
                            label: 'E-C4 X',
                            legacy_id: 2000615,
                            value: 'E_C4_X',
                        },
                        {
                            label: 'E-Mehari',
                            legacy_id: 2000505,
                            value: 'E-Mehari',
                        },
                        {
                            label: 'Evasion',
                            legacy_id: 3010,
                            value: 'EVASION',
                        },
                        {
                            label: 'GSA',
                            legacy_id: 947,
                            value: 'GSA',
                        },
                        {
                            label: 'Grand C4 Picasso',
                            legacy_id: 2000080,
                            value: 'grandc4picasso',
                        },
                        {
                            label: 'Grand C4 Spacetourer',
                            legacy_id: 2000449,
                            value: 'Grand C4 Spacetourer',
                        },
                        {
                            label: 'Jumper',
                            legacy_id: 950,
                            value: 'JUMPER',
                        },
                        {
                            label: 'Jumpy',
                            legacy_id: 7517,
                            value: 'JUMPY',
                        },
                        {
                            label: 'Nemo',
                            legacy_id: 2000156,
                            value: 'nemo',
                        },
                        {
                            label: 'SAXO Electrique',
                            legacy_id: 7538,
                            value: 'SAXO ELECTRIQUE',
                        },
                        {
                            label: 'Saxo',
                            legacy_id: 3012,
                            value: 'SAXO',
                        },
                        {
                            label: 'SpaceTourer',
                            legacy_id: 2000434,
                            value: 'SpaceTourer',
                        },
                        {
                            label: 'Visa',
                            legacy_id: 952,
                            value: 'VISA',
                        },
                        {
                            label: 'XM',
                            legacy_id: 954,
                            value: 'XM',
                        },
                        {
                            label: 'Xantia',
                            legacy_id: 953,
                            value: 'XANTIA',
                        },
                        {
                            label: 'Xsara',
                            legacy_id: 3011,
                            value: 'XSARA',
                        },
                        {
                            label: 'Xsara Picasso',
                            legacy_id: 7861,
                            value: 'xsara picasso',
                        },
                        {
                            label: 'ZX',
                            legacy_id: 955,
                            value: 'ZX',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2066,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'CITROEN',
                },
                {
                    label: 'City-Spirit',
                    legacy_id: 8095,
                    value: 'City-Spirit',
                },
                {
                    label: 'Cupra',
                    legacy_id: 8106,
                    options: [
                        {
                            label: 'Formentor',
                            legacy_id: 8305,
                            value: 'FORMENTOR',
                        },
                        {
                            label: 'Formentor e-HYBRID',
                            legacy_id: 8298,
                            value: 'FORMENTOR-e-HYBRID',
                        },
                        {
                            label: 'Leon e-Racer',
                            legacy_id: 8299,
                            value: 'LEON-E-RACER',
                        },
                        {
                            label: 'Born',
                            legacy_id: 2000567,
                            value: 'BORN',
                        },
                    ],
                    value: 'CUPRA',
                },
                {
                    label: 'DAF',
                    legacy_id: 8090,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000441,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'daf',
                },
                {
                    label: 'DS',
                    legacy_id: 8091,
                    options: [
                        {
                            label: 'DS 3',
                            legacy_id: 2000340,
                            value: 'ds3',
                        },
                        {
                            label: '3 Crossback',
                            legacy_id: 8350,
                            value: '3_CROSSBACK',
                        },
                        {
                            label: 'DS 4',
                            legacy_id: 2000341,
                            value: 'ds4',
                        },
                        {
                            label: 'DS 5',
                            legacy_id: 2000342,
                            value: 'ds5',
                        },
                        {
                            label: 'DS 7 Crossback',
                            legacy_id: 2000443,
                            value: 'DS 7 Crossback',
                        },
                        {
                            label: 'DS 9',
                            legacy_id: 2000585,
                            value: 'DS 9',
                        },
                    ],
                    value: 'ds',
                },
                {
                    label: 'Dacia',
                    legacy_id: 8079,
                    options: [
                        {
                            label: 'Duster',
                            legacy_id: 2000191,
                            value: 'duster',
                        },
                        {
                            label: 'Logan',
                            legacy_id: 8342,
                            value: 'Logan',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2000245,
                            value: 'ANDRE',
                        },
                        {
                            label: 'Dokker',
                            legacy_id: 2000563,
                            value: 'DOKKER',
                        },
                    ],
                    value: 'dacia',
                },
                {
                    label: 'Daewoo',
                    legacy_id: 760,
                    options: [
                        {
                            label: 'Espero',
                            legacy_id: 964,
                            value: 'ESPERO',
                        },
                        {
                            label: 'Kalos',
                            legacy_id: 7991,
                            value: 'KALOS',
                        },
                        {
                            label: 'Koranda',
                            legacy_id: 7760,
                            value: 'KORANDA',
                        },
                        {
                            label: 'Lacetti',
                            legacy_id: 7990,
                            value: 'LACETTI',
                        },
                        {
                            label: 'Lanos',
                            legacy_id: 3207,
                            value: 'LANOS',
                        },
                        {
                            label: 'Leganza',
                            legacy_id: 3791,
                            value: 'LEGANZA',
                        },
                        {
                            label: 'Matiz',
                            legacy_id: 3790,
                            value: 'MATIZ',
                        },
                        {
                            label: 'Musso',
                            legacy_id: 3789,
                            value: 'MUSSO',
                        },
                        {
                            label: 'Nexia',
                            legacy_id: 965,
                            value: 'NEXIA',
                        },
                        {
                            label: 'Nubira',
                            legacy_id: 3206,
                            value: 'NUBIRA',
                        },
                        {
                            label: 'Tacuma',
                            legacy_id: 6751,
                            value: 'TACUMA',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2069,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'DAEWOO',
                },
                {
                    label: 'Daihatsu',
                    legacy_id: 762,
                    options: [
                        {
                            label: 'Applause',
                            legacy_id: 6715,
                            value: 'APPLAUSE',
                        },
                        {
                            label: 'Charade',
                            legacy_id: 973,
                            value: 'CHARADE',
                        },
                        {
                            label: 'Copen',
                            legacy_id: 2000158,
                            value: 'copen',
                        },
                        {
                            label: 'Cuore',
                            legacy_id: 3793,
                            value: 'CUORE',
                        },
                        {
                            label: 'Gran Move',
                            legacy_id: 3792,
                            value: 'GRAND MOVE',
                        },
                        {
                            label: 'Rocky',
                            legacy_id: 975,
                            value: 'ROCKY',
                        },
                        {
                            label: 'Sirion',
                            legacy_id: 3794,
                            value: 'SIRION',
                        },
                        {
                            label: 'Terios',
                            legacy_id: 3795,
                            value: 'TERIOS',
                        },
                        {
                            label: 'YRV',
                            legacy_id: 7183,
                            value: 'YRV',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2071,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'DAIHATSU',
                },
                {
                    label: 'Datsun',
                    legacy_id: 8089,
                    options: [
                        {
                            label: 'Datsun',
                            legacy_id: 2000318,
                            value: 'datsun',
                        },
                    ],
                    value: 'datsun',
                },
                {
                    label: 'De Tomaso',
                    legacy_id: 8069,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000107,
                            value: 'andre',
                        },
                        {
                            label: 'Pantera',
                            legacy_id: 2000106,
                            value: 'pantera',
                        },
                    ],
                    value: 'de tomaso',
                },
                {
                    label: 'DeLorean',
                    legacy_id: 8085,
                    options: [
                        {
                            label: 'DMC-12',
                            legacy_id: 2000273,
                            value: 'dmc',
                        },
                    ],
                    value: 'delorean',
                },
                {
                    label: 'Dodge',
                    legacy_id: 764,
                    options: [
                        {
                            label: 'Avenger',
                            legacy_id: 2000095,
                            value: 'avenger',
                        },
                        {
                            label: 'Caliber',
                            legacy_id: 1494,
                            value: 'caliber',
                        },
                        {
                            label: 'Challenger',
                            legacy_id: 2074,
                            value: 'challenger',
                        },
                        {
                            label: 'Charger',
                            legacy_id: 2077,
                            value: 'charger',
                        },
                        {
                            label: 'Dakota',
                            legacy_id: 1001,
                            value: 'DAKOTA',
                        },
                        {
                            label: 'Durango',
                            legacy_id: 3796,
                            value: 'DURANGO',
                        },
                        {
                            label: 'Grand Caravan',
                            legacy_id: 1004,
                            value: 'GRAND CARAVAN',
                        },
                        {
                            label: 'Journey',
                            legacy_id: 2000089,
                            value: 'journey',
                        },
                        {
                            label: 'Le Baron',
                            legacy_id: 1006,
                            value: 'LE BARON',
                        },
                        {
                            label: 'Magnum',
                            legacy_id: 7982,
                            value: 'MAGNUM',
                        },
                        {
                            label: 'Nitro',
                            legacy_id: 2000163,
                            value: 'nitro',
                        },
                        {
                            label: 'RAM',
                            legacy_id: 3797,
                            value: 'RAM',
                        },
                        {
                            label: 'RAM SRT-10',
                            legacy_id: 8283,
                            value: 'RAM SRT-10',
                        },
                        {
                            label: 'Viper',
                            legacy_id: 7860,
                            value: 'viper',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2073,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'DODGE',
                },
                {
                    label: 'Ferrari',
                    legacy_id: 2999,
                    options: [
                        {
                            label: '296',
                            legacy_id: 2000599,
                            value: '296',
                        },
                        {
                            label: '308',
                            legacy_id: 2000122,
                            value: '308',
                        },
                        {
                            label: '328',
                            legacy_id: 7165,
                            value: '328',
                        },
                        {
                            label: '348',
                            legacy_id: 7162,
                            value: '348',
                        },
                        {
                            label: '355',
                            legacy_id: 3801,
                            value: '355',
                        },
                        {
                            label: '360',
                            legacy_id: 3800,
                            value: '360',
                        },
                        {
                            label: '365',
                            legacy_id: 7164,
                            value: '365',
                        },
                        {
                            label: '412',
                            legacy_id: 2000123,
                            value: '412',
                        },
                        {
                            label: '430',
                            legacy_id: 8330,
                            value: '430',
                        },
                        {
                            label: '456',
                            legacy_id: 3799,
                            value: '456',
                        },
                        {
                            label: '458 Italia',
                            legacy_id: 2000148,
                            value: '458',
                        },
                        {
                            label: '458 Spider',
                            legacy_id: 2000306,
                            value: '458spider',
                        },
                        {
                            label: '488 GTB',
                            legacy_id: 2000321,
                            value: '488 gtb',
                        },
                        {
                            label: '488 Spider',
                            legacy_id: 2000351,
                            value: '488spider',
                        },
                        {
                            label: '488 Pista',
                            legacy_id: 8333,
                            value: '488 PISTA',
                        },
                        {
                            label: '512',
                            legacy_id: 7345,
                            value: '512',
                        },
                        {
                            label: '550 Maranello',
                            legacy_id: 3798,
                            value: '550 Maranello',
                        },
                        {
                            label: '575 Maranelloo',
                            legacy_id: 2000543,
                            value: '575 Maranello',
                        },
                        {
                            label: '599',
                            legacy_id: 2000153,
                            value: '599',
                        },
                        {
                            label: '612',
                            legacy_id: 8307,
                            value: '612',
                        },
                        {
                            label: '812 GTS',
                            legacy_id: 8306,
                            value: '812-GTS',
                        },
                        {
                            label: '812 Superfast',
                            legacy_id: 2000458,
                            value: '812 Superfast',
                        },
                        {
                            label: 'California',
                            legacy_id: 2000151,
                            value: 'california',
                        },
                        {
                            label: 'Dino',
                            legacy_id: 7944,
                            value: 'dino',
                        },
                        {
                            label: 'F8 Spider',
                            legacy_id: 2000462,
                            value: 'F8_SPIDER',
                        },
                        {
                            label: 'F8 Tributo',
                            legacy_id: 2000461,
                            value: 'F8_TRIBUTO',
                        },
                        {
                            label: 'F 40',
                            legacy_id: 8100,
                            value: 'f40',
                        },
                        {
                            label: 'F12 Berlinetta',
                            legacy_id: 2000307,
                            value: 'f12berlinetta',
                        },
                        {
                            label: 'FF',
                            legacy_id: 2000199,
                            value: 'ff',
                        },
                        {
                            label: 'GTC4Lusso',
                            legacy_id: 2000450,
                            value: 'GTC4Lusso',
                        },
                        {
                            label: 'Mondial',
                            legacy_id: 2000542,
                            value: 'Mondial',
                        },
                        {
                            label: 'Portofino',
                            legacy_id: 2000451,
                            value: 'Portofino',
                        },
                        {
                            label: 'Roma',
                            legacy_id: 2000463,
                            value: 'ROMA',
                        },
                        {
                            label: 'SF90 Spider',
                            legacy_id: 2000571,
                            value: 'SF90-SPIDER',
                        },
                        {
                            label: 'SF90 Stradale',
                            legacy_id: 2000570,
                            value: 'SF90-STRADALE',
                        },
                        {
                            label: 'Testarossa',
                            legacy_id: 7163,
                            value: 'testarossa',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 3017,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'FERRARI',
                },
                {
                    label: 'Fiat',
                    legacy_id: 766,
                    options: [
                        {
                            label: '124 Spider',
                            legacy_id: 2000411,
                            value: '124SPIDER',
                        },
                        {
                            label: '500',
                            legacy_id: 2000071,
                            value: '500',
                        },
                        {
                            label: '500L',
                            legacy_id: 2000241,
                            value: '500l',
                        },
                        {
                            label: '500X',
                            legacy_id: 2000322,
                            value: '500x',
                        },
                        {
                            label: 'Barchetta',
                            legacy_id: 2881,
                            value: 'BARCHETTA',
                        },
                        {
                            label: 'Bertone',
                            legacy_id: 1029,
                            value: 'BERTONE',
                        },
                        {
                            label: 'Brava',
                            legacy_id: 2882,
                            value: 'BRAVA',
                        },
                        {
                            label: 'Bravo',
                            legacy_id: 3225,
                            value: 'BRAVO',
                        },
                        {
                            label: 'Coupe',
                            legacy_id: 3802,
                            value: 'COUPE',
                        },
                        {
                            label: 'Croma',
                            legacy_id: 1030,
                            value: 'CROMA',
                        },
                        {
                            label: 'Doblo',
                            legacy_id: 200036,
                            value: 'DOBLO',
                        },
                        {
                            label: 'Ducato',
                            legacy_id: 1031,
                            value: 'DUCATO',
                        },
                        {
                            label: 'Freemont',
                            legacy_id: 2000201,
                            value: 'freemont',
                        },
                        {
                            label: 'Fullback',
                            legacy_id: 2000374,
                            value: 'Fullback',
                        },
                        {
                            label: 'Grande Punto',
                            legacy_id: 8325,
                            value: 'grande punto',
                        },
                        {
                            label: 'Marea',
                            legacy_id: 3256,
                            value: 'MAREA',
                        },
                        {
                            label: 'Multipla',
                            legacy_id: 2000037,
                            value: 'MULTIPLA',
                        },
                        {
                            label: 'Panda',
                            legacy_id: 1033,
                            value: 'PANDA',
                        },
                        {
                            label: 'Punto',
                            legacy_id: 1034,
                            value: 'PUNTO',
                        },
                        {
                            label: 'Punto Evo',
                            legacy_id: 2000221,
                            value: 'punto_evo',
                        },
                        {
                            label: 'Regata',
                            legacy_id: 1035,
                            value: 'REGATA',
                        },
                        {
                            label: 'Ritmo',
                            legacy_id: 1036,
                            value: 'RITMO',
                        },
                        {
                            label: 'Scudo',
                            legacy_id: 8323,
                            value: 'scudo',
                        },
                        {
                            label: 'Sedici',
                            legacy_id: 8324,
                            value: 'sedici',
                        },
                        {
                            label: 'Stilo',
                            legacy_id: 7465,
                            value: 'STILO',
                        },
                        {
                            label: 'Strada',
                            legacy_id: 2000063,
                            value: 'strada',
                        },
                        {
                            label: 'Talento',
                            legacy_id: 2000403,
                            value: 'Talento',
                        },
                        {
                            label: 'Tipo',
                            legacy_id: 1039,
                            value: 'TIPO',
                        },
                        {
                            label: 'Ulysse',
                            legacy_id: 8170,
                            value: 'ulysse',
                        },
                        {
                            label: 'Uno',
                            legacy_id: 1040,
                            value: 'UNO',
                        },
                        {
                            label: 'X 1/9',
                            legacy_id: 1041,
                            value: 'X 1/9',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2075,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'FIAT',
                },
                {
                    label: 'Fisker',
                    legacy_id: 8073,
                    options: [
                        {
                            label: 'Karma',
                            legacy_id: 2000115,
                            value: 'karma',
                        },
                        {
                            label: 'Karma S',
                            legacy_id: 2000135,
                            value: 'karma s',
                        },
                    ],
                    value: 'fisker',
                },
                {
                    label: 'Ford',
                    legacy_id: 767,
                    options: [
                        {
                            label: 'Aerostar',
                            legacy_id: 2851,
                            value: 'AEROSTAR',
                        },
                        {
                            label: 'B-MAX',
                            legacy_id: 2000232,
                            value: 'bmax',
                        },
                        {
                            label: 'Bronco',
                            legacy_id: 2850,
                            value: 'BRONCO',
                        },
                        {
                            label: 'C-Max',
                            legacy_id: 7995,
                            value: 'FOCUSCMAX',
                        },
                        {
                            label: 'Cougar',
                            legacy_id: 3738,
                            value: 'COUGAR',
                        },
                        {
                            label: 'Courier',
                            legacy_id: 1050,
                            value: 'COURIER',
                        },
                        {
                            label: 'Custom Line',
                            legacy_id: 7326,
                            value: 'CUSTOM LINE',
                        },
                        {
                            label: 'Econoline',
                            legacy_id: 2849,
                            value: 'ECONOLINE',
                        },
                        {
                            label: 'Ecosport',
                            legacy_id: 2000348,
                            value: 'ecosport',
                        },
                        {
                            label: 'Edge',
                            legacy_id: 2000385,
                            value: 'Edge',
                        },
                        {
                            label: 'Escort',
                            legacy_id: 1051,
                            value: 'ESCORT',
                        },
                        {
                            label: 'Excursion',
                            legacy_id: 7109,
                            value: 'EXCURSION',
                        },
                        {
                            label: 'Expedition',
                            legacy_id: 7512,
                            value: 'EXPEDITION',
                        },
                        {
                            label: 'Explorer',
                            legacy_id: 2848,
                            value: 'EXPLORER',
                        },
                        {
                            label: 'Extended Cab',
                            legacy_id: 7328,
                            value: 'EXTENDED CAB',
                        },
                        {
                            label: 'F-serie',
                            legacy_id: 7160,
                            value: 'F-SERIE',
                        },
                        {
                            label: 'Fiesta',
                            legacy_id: 1052,
                            value: 'FIESTA',
                        },
                        {
                            label: 'Focus',
                            legacy_id: 3739,
                            value: 'FOCUS',
                        },
                        {
                            label: 'Focus CC',
                            legacy_id: 2000076,
                            value: 'focus_cc',
                        },
                        {
                            label: 'Fusion',
                            legacy_id: 7600,
                            value: 'FUSION',
                        },
                        {
                            label: 'Galaxy',
                            legacy_id: 1053,
                            value: 'GALAXY',
                        },
                        {
                            label: 'Granada',
                            legacy_id: 1054,
                            value: 'GRANADA',
                        },
                        {
                            label: 'Grand C-MAX',
                            legacy_id: 2000218,
                            value: 'grandcmax',
                        },
                        {
                            label: 'Grand Tourneo Connect',
                            legacy_id: 2000350,
                            value: 'grand tourneo connect',
                        },
                        {
                            label: 'Ka',
                            legacy_id: 3020,
                            value: 'KA',
                        },
                        {
                            label: 'Ka+',
                            legacy_id: 2000408,
                            value: 'KA_PLUS',
                        },
                        {
                            label: 'Kuga',
                            legacy_id: 2000084,
                            value: 'kuga',
                        },
                        {
                            label: 'Maverick',
                            legacy_id: 8101,
                            value: 'maverick',
                        },
                        {
                            label: 'Mondeo',
                            legacy_id: 1055,
                            value: 'MONDEO',
                        },
                        {
                            label: 'Mustang',
                            legacy_id: 1056,
                            value: 'MUSTANG',
                        },
                        {
                            label: 'Mustang Mach-E',
                            legacy_id: 2000460,
                            value: 'MUSTANG MACH-E',
                        },
                        {
                            label: 'Orion',
                            legacy_id: 1057,
                            value: 'ORION',
                        },
                        {
                            label: 'Probe',
                            legacy_id: 2855,
                            value: 'PROBE',
                        },
                        {
                            label: 'Puma',
                            legacy_id: 3257,
                            value: 'PUMA',
                        },
                        {
                            label: 'Ranger',
                            legacy_id: 2854,
                            value: 'RANGER',
                        },
                        {
                            label: 'S-MAX',
                            legacy_id: 1065,
                            value: 's-max',
                        },
                        {
                            label: 'SVT Lightning',
                            legacy_id: 8268,
                            value: 'SVT LIGHTNING',
                        },
                        {
                            label: 'Scorpio',
                            legacy_id: 1059,
                            value: 'SCORPIO',
                        },
                        {
                            label: 'Sierra',
                            legacy_id: 1060,
                            value: 'SIERRA',
                        },
                        {
                            label: 'Sportka',
                            legacy_id: 7794,
                            value: 'SPORTKA',
                        },
                        {
                            label: 'StreetKa',
                            legacy_id: 2000582,
                            value: 'STREETKA',
                        },
                        {
                            label: 'Taunus',
                            legacy_id: 1061,
                            value: 'TAUNUS',
                        },
                        {
                            label: 'Taurus',
                            legacy_id: 2853,
                            value: 'TAURUS',
                        },
                        {
                            label: 'Thunderbird',
                            legacy_id: 2852,
                            value: 'THUNDERBIRD',
                        },
                        {
                            label: 'Tourneo Connect',
                            legacy_id: 2000290,
                            value: 'tourneoconnect',
                        },
                        {
                            label: 'Tourneo Courier',
                            legacy_id: 2000339,
                            value: 'tourneo courier',
                        },
                        {
                            label: 'Tourneo Custom',
                            legacy_id: 2000272,
                            value: 'tourneocustom',
                        },
                        {
                            label: 'Transit',
                            legacy_id: 1063,
                            value: 'TRANSIT',
                        },
                        {
                            label: 'Transit Connect',
                            legacy_id: 8191,
                            value: 'CONNECT',
                        },
                        {
                            label: 'Transit Courier',
                            legacy_id: 2000310,
                            value: 'transitcourier',
                        },
                        {
                            label: 'Transit Custom',
                            legacy_id: 2000266,
                            value: 'transit_custom',
                        },
                        {
                            label: 'Van',
                            legacy_id: 7327,
                            value: 'VAN',
                        },
                        {
                            label: 'Windstar',
                            legacy_id: 7535,
                            value: 'WINDSTAR',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2076,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'FORD',
                },
                {
                    label: 'GMC',
                    legacy_id: 7547,
                    options: [
                        {
                            label: 'Crew Cab',
                            legacy_id: 7549,
                            value: 'CREW CAB',
                        },
                        {
                            label: 'Envoy',
                            legacy_id: 7872,
                            value: 'envoy',
                        },
                        {
                            label: 'Extended Cab',
                            legacy_id: 7550,
                            value: 'EXTENDED CAB',
                        },
                        {
                            label: 'Sierra',
                            legacy_id: 2000251,
                            value: 'sierra',
                        },
                        {
                            label: 'Silverado',
                            legacy_id: 7548,
                            value: 'SILVERADO',
                        },
                        {
                            label: 'Syclone',
                            legacy_id: 2000286,
                            value: 'syclone',
                        },
                        {
                            label: 'Yukon',
                            legacy_id: 7551,
                            value: 'YUKON',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7556,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'GMC',
                },
                {
                    label: 'Goupil',
                    legacy_id: 8099,
                    options: [
                        {
                            label: 'G2',
                            legacy_id: 1508,
                            value: 'G2',
                        },
                        {
                            label: 'G4',
                            legacy_id: 1509,
                            value: 'G4',
                        },
                        {
                            label: 'G5',
                            legacy_id: 1510,
                            value: 'G5',
                        },
                        {
                            label: 'G6',
                            legacy_id: 2000525,
                            value: 'G6',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 1511,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'goupil',
                },
                {
                    label: 'Honda',
                    legacy_id: 771,
                    options: [
                        {
                            label: 'Accord',
                            legacy_id: 1087,
                            value: 'ACCORD',
                        },
                        {
                            label: 'CR-V',
                            legacy_id: 3027,
                            value: 'CR-V',
                        },
                        {
                            label: 'CR-Z',
                            legacy_id: 2000167,
                            value: 'cr-z',
                        },
                        {
                            label: 'CRX',
                            legacy_id: 2000039,
                            value: 'CRX',
                        },
                        {
                            label: 'Civic',
                            legacy_id: 1088,
                            value: 'CIVIC',
                        },
                        {
                            label: 'e',
                            legacy_id: 8363,
                            value: 'E',
                        },
                        {
                            label: 'Element',
                            legacy_id: 8089,
                            value: 'element',
                        },
                        {
                            label: 'FR-V',
                            legacy_id: 8088,
                            value: 'fr-v',
                        },
                        {
                            label: 'HR-V',
                            legacy_id: 3744,
                            value: 'HR-V',
                        },
                        {
                            label: 'Insight',
                            legacy_id: 2000117,
                            value: 'insight',
                        },
                        {
                            label: 'Integra',
                            legacy_id: 2000014,
                            value: 'integra',
                        },
                        {
                            label: 'Jazz',
                            legacy_id: 7514,
                            value: 'JAZZ',
                        },
                        {
                            label: 'Legend',
                            legacy_id: 1090,
                            value: 'LEGEND',
                        },
                        {
                            label: 'Nsx',
                            legacy_id: 1091,
                            value: 'NSX',
                        },
                        {
                            label: 'Odyssey',
                            legacy_id: 2000075,
                            value: 'odyssey',
                        },
                        {
                            label: 'Prelude',
                            legacy_id: 1092,
                            value: 'PRELUDE',
                        },
                        {
                            label: 'Quintet',
                            legacy_id: 1093,
                            value: 'QUINTET',
                        },
                        {
                            label: 'Ridgeline',
                            legacy_id: 8097,
                            value: 'ridgeline',
                        },
                        {
                            label: 'S2000',
                            legacy_id: 3803,
                            value: 'S2000',
                        },
                        {
                            label: 'Shuttle',
                            legacy_id: 1094,
                            value: 'SHUTTLE',
                        },
                        {
                            label: 'Stream',
                            legacy_id: 2000040,
                            value: 'stream',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2080,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'HONDA',
                },
                {
                    label: 'Hongqi',
                    legacy_id: 8107,
                    options: [
                        {
                            label: 'E-HS9',
                            legacy_id: 8304,
                            value: 'E-HS9',
                        },
                    ],
                    value: 'HONGQI',
                },
                {
                    label: 'Hummer',
                    legacy_id: 7672,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 7673,
                            value: 'ANDRE',
                        },
                        {
                            label: 'H1',
                            legacy_id: 7674,
                            value: 'H1',
                        },
                        {
                            label: 'H2',
                            legacy_id: 7675,
                            value: 'H2',
                        },
                        {
                            label: 'H3',
                            legacy_id: 8180,
                            value: 'H3',
                        },
                        {
                            label: 'HX',
                            legacy_id: 2000244,
                            value: 'hx',
                        },
                    ],
                    value: 'HUMMER',
                },
                {
                    label: 'Hyundai',
                    legacy_id: 772,
                    options: [
                        {
                            label: 'Accent',
                            legacy_id: 1095,
                            value: 'ACCENT',
                        },
                        {
                            label: 'Atos',
                            legacy_id: 3746,
                            value: 'ATOS',
                        },
                        {
                            label: 'Coupe',
                            legacy_id: 3804,
                            value: 'COUPE',
                        },
                        {
                            label: 'Elantra',
                            legacy_id: 1096,
                            value: 'ELANTRA',
                        },
                        {
                            label: 'Galloper',
                            legacy_id: 7184,
                            value: 'GALLOPER',
                        },
                        {
                            label: 'Getz',
                            legacy_id: 7588,
                            value: 'GETZ',
                        },
                        {
                            label: 'Grand Santa Fe',
                            legacy_id: 2000349,
                            value: 'grand santa fe',
                        },
                        {
                            label: 'H-1',
                            legacy_id: 2899,
                            value: 'H-1',
                        },
                        {
                            label: 'H-100',
                            legacy_id: 7108,
                            value: 'H-100',
                        },
                        {
                            label: 'IONIQ',
                            legacy_id: 2000393,
                            value: 'Ioniq',
                        },
                        {
                            label: 'Ioniq 5',
                            legacy_id: 2000546,
                            value: 'Ioniq 5',
                        },
                        {
                            label: 'Ioniq 6',
                            legacy_id: 2000609,
                            value: 'Ioniq6',
                        },
                        {
                            label: 'Kona',
                            legacy_id: 2000438,
                            value: 'Kona',
                        },
                        {
                            label: 'Matrix',
                            legacy_id: 7178,
                            value: 'MATRIX',
                        },
                        {
                            label: 'Nexo',
                            legacy_id: 8349,
                            value: 'NEXO',
                        },
                        {
                            label: 'Pony',
                            legacy_id: 2900,
                            value: 'PONY',
                        },
                        {
                            label: 'Santa Fe',
                            legacy_id: 6739,
                            value: 'SANTA FE',
                        },
                        {
                            label: 'Sonata',
                            legacy_id: 1097,
                            value: 'SONATA',
                        },
                        {
                            label: 'Terracan',
                            legacy_id: 7246,
                            value: 'TERRACAN',
                        },
                        {
                            label: 'Trajet',
                            legacy_id: 6740,
                            value: 'TRAJET',
                        },
                        {
                            label: 'Tucson',
                            legacy_id: 7951,
                            value: 'tucson',
                        },
                        {
                            label: 'Veloster',
                            legacy_id: 2000193,
                            value: 'veloster',
                        },
                        {
                            label: 'X35',
                            legacy_id: 2000155,
                            value: 'x35',
                        },
                        {
                            label: 'XG',
                            legacy_id: 3805,
                            value: 'XG',
                        },
                        {
                            label: 'i10',
                            legacy_id: 2000091,
                            value: 'i10',
                        },
                        {
                            label: 'i20',
                            legacy_id: 2000102,
                            value: 'i20',
                        },
                        {
                            label: 'i30',
                            legacy_id: 2000069,
                            value: 'i30',
                        },
                        {
                            label: 'i40',
                            legacy_id: 2000194,
                            value: 'i40',
                        },
                        {
                            label: 'ix20',
                            legacy_id: 2000171,
                            value: 'ix20',
                        },
                        {
                            label: 'ix35',
                            legacy_id: 2000157,
                            value: 'ix35',
                        },
                        {
                            label: 'ix55',
                            legacy_id: 2000132,
                            value: 'ix55',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2081,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'HYUNDAI',
                },
                {
                    label: 'Infiniti',
                    legacy_id: 8065,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000060,
                            value: 'Andre',
                        },
                        {
                            label: 'FX30D',
                            legacy_id: 2000380,
                            value: 'FX30D',
                        },
                        {
                            label: 'FX35',
                            legacy_id: 2000054,
                            value: 'FX35',
                        },
                        {
                            label: 'FX37',
                            legacy_id: 2000375,
                            value: 'FX37',
                        },
                        {
                            label: 'FX45',
                            legacy_id: 2000055,
                            value: 'FX45',
                        },
                        {
                            label: 'FX50',
                            legacy_id: 2000554,
                            value: 'FX50',
                        },
                        {
                            label: 'G35',
                            legacy_id: 2000056,
                            value: 'G35',
                        },
                        {
                            label: 'G37',
                            legacy_id: 2000569,
                            value: 'G37',
                        },
                        {
                            label: 'M35',
                            legacy_id: 2000057,
                            value: 'M35',
                        },
                        {
                            label: 'M45',
                            legacy_id: 2000058,
                            value: 'M45',
                        },
                        {
                            label: 'Q30',
                            legacy_id: 2000376,
                            value: 'Q30',
                        },
                        {
                            label: 'Q50 Hybrid',
                            legacy_id: 2000377,
                            value: 'Q50 Hybrid',
                        },
                        {
                            label: 'QX56',
                            legacy_id: 2000059,
                            value: 'QX56',
                        },
                    ],
                    value: 'infiniti',
                },
                {
                    label: 'Isuzu',
                    legacy_id: 7179,
                    options: [
                        {
                            label: 'D-max',
                            legacy_id: 2000077,
                            value: 'dmax',
                        },
                        {
                            label: 'Trooper',
                            legacy_id: 7180,
                            value: 'TROOPER',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7181,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ISUZU',
                },
                {
                    label: 'Iveco',
                    legacy_id: 7280,
                    options: [
                        {
                            label: '3510',
                            legacy_id: 7580,
                            value: '3510',
                        },
                        {
                            label: 'Daily',
                            legacy_id: 7294,
                            value: 'DAILY',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7296,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'IVECO',
                },
                {
                    label: 'JAC',
                    legacy_id: 8114,
                    options: [
                        {
                            label: 'e-JS4',
                            legacy_id: 2000602,
                            value: 'E-JS4',
                        },
                    ],
                    value: 'JAC',
                },
                {
                    label: 'Jaguar',
                    legacy_id: 775,
                    options: [
                        {
                            label: 'E-PACE',
                            legacy_id: 2000432,
                            value: 'E-PACE',
                        },
                        {
                            label: 'E-TYPE',
                            legacy_id: 2000124,
                            value: 'e-type',
                        },
                        {
                            label: 'F-PACE',
                            legacy_id: 2000371,
                            value: 'F-PACE',
                        },
                        {
                            label: 'F-TYPE',
                            legacy_id: 2000252,
                            value: 'f_type',
                        },
                        {
                            label: 'I-PACE',
                            legacy_id: 2000447,
                            value: 'I-PACE',
                        },
                        {
                            label: 'S-TYPE',
                            legacy_id: 3747,
                            value: 'S-TYPE',
                        },
                        {
                            label: 'X-TYPE',
                            legacy_id: 2000042,
                            value: 'X-TYPE',
                        },
                        {
                            label: 'XE',
                            legacy_id: 2000324,
                            value: 'xe',
                        },
                        {
                            label: 'XF',
                            legacy_id: 8317,
                            value: 'xf',
                        },
                        {
                            label: 'XJ',
                            legacy_id: 3806,
                            value: 'XJ',
                        },
                        {
                            label: 'XJR',
                            legacy_id: 2000389,
                            value: 'XJR',
                        },
                        {
                            label: 'XJS',
                            legacy_id: 1112,
                            value: 'XJS',
                        },
                        {
                            label: 'XK',
                            legacy_id: 3035,
                            value: 'XK',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2084,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'JAGUAR',
                },
                {
                    label: 'Jeep',
                    legacy_id: 776,
                    options: [
                        {
                            label: 'Avenger',
                            legacy_id: 2000607,
                            value: 'Avenger',
                        },
                        {
                            label: 'Cherokee',
                            legacy_id: 1113,
                            value: 'CHEROKEE',
                        },
                        {
                            label: 'Comanche',
                            legacy_id: 1114,
                            value: 'COMANCHE',
                        },
                        {
                            label: 'Commander',
                            legacy_id: 1496,
                            value: 'commander',
                        },
                        {
                            label: 'Compass',
                            legacy_id: 8315,
                            value: 'compass',
                        },
                        {
                            label: 'Grand Cherokee',
                            legacy_id: 2143,
                            value: 'GRAND CHEROKEE',
                        },
                        {
                            label: 'J 20',
                            legacy_id: 1115,
                            value: 'J 20',
                        },
                        {
                            label: 'Patriot',
                            legacy_id: 8316,
                            value: 'patriot',
                        },
                        {
                            label: 'Renegade',
                            legacy_id: 2000317,
                            value: 'renegade',
                        },
                        {
                            label: 'Wrangler',
                            legacy_id: 1116,
                            value: 'WRANGLER',
                        },
                        {
                            label: 'Gladiator',
                            legacy_id: 2000533,
                            value: 'GLADIATOR',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2085,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'JEEP',
                },
                {
                    label: 'Jensen',
                    legacy_id: 774,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 8287,
                            value: 'andre',
                        },
                        {
                            label: 'Interceptor',
                            legacy_id: 8286,
                            value: 'interceptor',
                        },
                    ],
                    value: 'JENSEN',
                },
                {
                    label: 'Kewet',
                    legacy_id: 7764,
                    options: [
                        {
                            label: 'Buddy',
                            legacy_id: 8188,
                            value: 'BUDDY',
                        },
                        {
                            label: 'City-Jet 5',
                            legacy_id: 7766,
                            value: 'CITY-JET 5',
                        },
                        {
                            label: 'El-Jet',
                            legacy_id: 7765,
                            value: 'EL-JET',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7778,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'KEWET',
                },
                {
                    label: 'Kia',
                    legacy_id: 777,
                    options: [
                        {
                            label: 'Besta',
                            legacy_id: 1117,
                            value: 'BESTA',
                        },
                        {
                            label: 'Carens',
                            legacy_id: 7566,
                            value: 'carens',
                        },
                        {
                            label: 'Carnival',
                            legacy_id: 6741,
                            value: 'CARNIVAL',
                        },
                        {
                            label: "Cee'd",
                            legacy_id: 2000064,
                            value: 'ceed',
                        },
                        {
                            label: 'Cerato',
                            legacy_id: 8179,
                            value: 'CERATO',
                        },
                        {
                            label: 'Clarus',
                            legacy_id: 3810,
                            value: 'CLARUS',
                        },
                        {
                            label: 'Credos',
                            legacy_id: 3037,
                            value: 'CREDOS',
                        },
                        {
                            label: 'E-Niro',
                            legacy_id: 2000459,
                            value: 'E-NIRO',
                        },
                        {
                            label: 'EV6',
                            legacy_id: 8300,
                            value: 'EV6',
                        },
                        {
                            label: 'Magentis',
                            legacy_id: 7324,
                            value: 'MAGENTIS',
                        },
                        {
                            label: 'Niro',
                            legacy_id: 2000392,
                            value: 'Niro',
                        },
                        {
                            label: 'Optima',
                            legacy_id: 2000223,
                            value: 'optima',
                        },
                        {
                            label: 'Picanto',
                            legacy_id: 8178,
                            value: 'PICANTO',
                        },
                        {
                            label: 'Pregio',
                            legacy_id: 3809,
                            value: 'PREGIO',
                        },
                        {
                            label: 'Pride',
                            legacy_id: 3038,
                            value: 'PRIDE',
                        },
                        {
                            label: 'ProCeed',
                            legacy_id: 2000506,
                            value: 'ProCeed',
                        },
                        {
                            label: 'Rio',
                            legacy_id: 7117,
                            value: 'RIO',
                        },
                        {
                            label: 'Sephia',
                            legacy_id: 1118,
                            value: 'SEPHIA',
                        },
                        {
                            label: 'Shuma',
                            legacy_id: 3808,
                            value: 'SHUMA',
                        },
                        {
                            label: 'Sorento',
                            legacy_id: 7711,
                            value: 'SORENTO',
                        },
                        {
                            label: 'Soul',
                            legacy_id: 2000300,
                            value: 'soul',
                        },
                        {
                            label: 'Sportage',
                            legacy_id: 1119,
                            value: 'SPORTAGE',
                        },
                        {
                            label: 'Stinger',
                            legacy_id: 2000435,
                            value: 'Stinger',
                        },
                        {
                            label: 'Stonic',
                            legacy_id: 2000433,
                            value: 'Stonic',
                        },
                        {
                            label: 'Venga',
                            legacy_id: 2000164,
                            value: 'venga',
                        },
                        {
                            label: 'XCeed',
                            legacy_id: 2000526,
                            value: 'XCEED',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2086,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'KIA',
                },
                {
                    label: 'Koenigsegg',
                    legacy_id: 8081,
                    options: [
                        {
                            label: 'CCR',
                            legacy_id: 2000202,
                            value: 'ccr',
                        },
                    ],
                    value: 'koenigsegg',
                },
                {
                    label: 'Lada',
                    legacy_id: 779,
                    options: [
                        {
                            label: '1200',
                            legacy_id: 1121,
                            value: '1200',
                        },
                        {
                            label: '1300',
                            legacy_id: 1122,
                            value: '1300',
                        },
                        {
                            label: '1500',
                            legacy_id: 1123,
                            value: '1500',
                        },
                        {
                            label: '1600',
                            legacy_id: 1124,
                            value: '1600',
                        },
                        {
                            label: 'Niva',
                            legacy_id: 1125,
                            value: 'NIVA',
                        },
                        {
                            label: 'Samara',
                            legacy_id: 1126,
                            value: 'SAMARA',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2088,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LADA',
                },
                {
                    label: 'Lamborghini',
                    legacy_id: 6731,
                    options: [
                        {
                            label: 'Aventador',
                            legacy_id: 2000222,
                            value: 'aventador',
                        },
                        {
                            label: 'Countach',
                            legacy_id: 6732,
                            value: 'COUNTACH',
                        },
                        {
                            label: 'Diablo',
                            legacy_id: 7879,
                            value: 'diablo',
                        },
                        {
                            label: 'Gallardo',
                            legacy_id: 2000112,
                            value: 'gallardo',
                        },
                        {
                            label: 'Huracan',
                            legacy_id: 2000298,
                            value: 'huracan',
                        },
                        {
                            label: 'Huracan Spyder',
                            legacy_id: 2000396,
                            value: 'Huracan Spyder',
                        },
                        {
                            label: 'LM',
                            legacy_id: 2000125,
                            value: 'lm',
                        },
                        {
                            label: 'Murcielago',
                            legacy_id: 2000354,
                            value: 'murcielago',
                        },
                        {
                            label: 'Urus',
                            legacy_id: 2000452,
                            value: 'Urus',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7218,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LAMBORGHINI',
                },
                {
                    label: 'Lancia',
                    legacy_id: 780,
                    options: [
                        {
                            label: 'A112',
                            legacy_id: 1129,
                            value: 'A112',
                        },
                        {
                            label: 'Delta',
                            legacy_id: 1130,
                            value: 'DELTA',
                        },
                        {
                            label: 'Kappa',
                            legacy_id: 3811,
                            value: 'KAPPA',
                        },
                        {
                            label: 'Lybra',
                            legacy_id: 8326,
                            value: 'lybra',
                        },
                        {
                            label: 'Musa',
                            legacy_id: 2000050,
                            value: 'musa',
                        },
                        {
                            label: 'Phedra',
                            legacy_id: 2000051,
                            value: 'phedra',
                        },
                        {
                            label: 'Prisma',
                            legacy_id: 1131,
                            value: 'PRISMA',
                        },
                        {
                            label: 'Thema',
                            legacy_id: 1132,
                            value: 'THEMA',
                        },
                        {
                            label: 'Thesis',
                            legacy_id: 2000052,
                            value: 'thesis',
                        },
                        {
                            label: 'Y10',
                            legacy_id: 1133,
                            value: 'Y10',
                        },
                        {
                            label: 'Ypsilon',
                            legacy_id: 2000049,
                            value: 'ypsilon',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2089,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LANCIA',
                },
                {
                    label: 'Lucid',
                    legacy_id: 8115,
                    options: [
                        {
                            label: 'Air',
                            legacy_id: 2000610,
                            value: 'AIR',
                        },
                    ],
                    value: 'LUCID',
                },
                {
                    label: 'Land Rover',
                    legacy_id: 781,
                    options: [
                        {
                            label: 'Defender',
                            legacy_id: 6742,
                            value: 'DEFENDER',
                        },
                        {
                            label: 'Discovery',
                            legacy_id: 1135,
                            value: 'DISCOVERY',
                        },
                        {
                            label: 'Discovery Sport',
                            legacy_id: 2000319,
                            value: 'discovery sport',
                        },
                        {
                            label: 'Freelander',
                            legacy_id: 3750,
                            value: 'FREELANDER',
                        },
                        {
                            label: 'Range Rover',
                            legacy_id: 1136,
                            value: 'RANGE ROVER',
                        },
                        {
                            label: 'Range Rover Evoque',
                            legacy_id: 2000197,
                            value: 'range rover evoque',
                        },
                        {
                            label: 'Range Rover Sport',
                            legacy_id: 8265,
                            value: 'RANGE ROVER SPORT',
                        },
                        {
                            label: 'Range Rover Velar',
                            legacy_id: 2000416,
                            value: 'RANGE_ROVER_VELAR',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2090,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LAND ROVER',
                },
                {
                    label: 'Lexus',
                    legacy_id: 782,
                    options: [
                        {
                            label: 'CT200h',
                            legacy_id: 2000186,
                            value: 'ct',
                        },
                        {
                            label: 'ES',
                            legacy_id: 2000509,
                            value: 'ES',
                        },
                        {
                            label: 'GS',
                            legacy_id: 3040,
                            value: 'GS',
                        },
                        {
                            label: 'IS',
                            legacy_id: 3812,
                            value: 'IS',
                        },
                        {
                            label: 'LC',
                            legacy_id: 2000419,
                            value: 'LC',
                        },
                        {
                            label: 'LS',
                            legacy_id: 1137,
                            value: 'LS',
                        },
                        {
                            label: 'NX 300h',
                            legacy_id: 2000305,
                            value: 'nx300h',
                        },
                        {
                            label: 'NX 450h',
                            legacy_id: 8380,
                            value: 'NX_450H',
                        },
                        {
                            label: 'RC',
                            legacy_id: 2000387,
                            value: 'RC',
                        },
                        {
                            label: 'RC300h',
                            legacy_id: 2000382,
                            value: 'RC300h',
                        },
                        {
                            label: 'RX300',
                            legacy_id: 7949,
                            value: 'rx300',
                        },
                        {
                            label: 'RX400h',
                            legacy_id: 2000070,
                            value: 'RX400h',
                        },
                        {
                            label: 'RX450h',
                            legacy_id: 2000149,
                            value: 'rx450h',
                        },
                        {
                            label: 'SC',
                            legacy_id: 7189,
                            value: 'SC',
                        },
                        {
                            label: 'UX',
                            legacy_id: 2000510,
                            value: 'UX',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2091,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LEXUS',
                },
                {
                    label: 'Lincoln',
                    legacy_id: 7153,
                    options: [
                        {
                            label: 'Navigator',
                            legacy_id: 7156,
                            value: 'NAVIGATOR',
                        },
                        {
                            label: 'Town Car',
                            legacy_id: 7152,
                            value: 'TOWN CAR',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7154,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LINCOLN',
                },
                {
                    label: 'Lotus',
                    legacy_id: 7191,
                    options: [
                        {
                            label: 'Elise',
                            legacy_id: 7195,
                            value: 'ELISE',
                        },
                        {
                            label: 'Europa S',
                            legacy_id: 2000114,
                            value: 'europa s',
                        },
                        {
                            label: 'Evora',
                            legacy_id: 2000136,
                            value: 'evora',
                        },
                        {
                            label: 'Exige',
                            legacy_id: 2000113,
                            value: 'exige',
                        },
                        {
                            label: 'Seven',
                            legacy_id: 7194,
                            value: 'SEVEN',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7196,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'LOTUS',
                },
                {
                    label: 'MAN',
                    legacy_id: 8097,
                    options: [
                        {
                            label: 'TGE',
                            legacy_id: 1501,
                            value: 'TGE',
                        },
                        {
                            label: 'eTGE',
                            legacy_id: 1502,
                            value: 'eTGE',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 1503,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MAN',
                },
                {
                    label: 'Mia Electric',
                    legacy_id: 8083,
                    options: [
                        {
                            label: 'mia',
                            legacy_id: 2000215,
                            value: 'mia',
                        },
                        {
                            label: 'mia L',
                            legacy_id: 2000216,
                            value: 'mial',
                        },
                        {
                            label: 'mia U',
                            legacy_id: 2000217,
                            value: 'miau',
                        },
                    ],
                    value: 'miaelectric',
                },
                {
                    label: 'MG',
                    legacy_id: 786,
                    options: [
                        {
                            label: 'MG4',
                            legacy_id: 8399,
                            value: '4',
                        },
                        {
                            label: 'MG5',
                            legacy_id: 8398,
                            value: '5',
                        },
                        {
                            label: 'MG-F',
                            legacy_id: 3817,
                            value: 'MG-F',
                        },
                        {
                            label: 'EHS',
                            legacy_id: 8396,
                            value: 'EHS',
                        },
                        {
                            label: 'MGB',
                            legacy_id: 2000538,
                            value: 'MGB',
                        },
                        {
                            label: 'Marvel R',
                            legacy_id: 2000552,
                            value: 'MARVEL_R',
                        },
                        {
                            label: 'TF',
                            legacy_id: 7876,
                            value: 'tf',
                        },
                        {
                            label: 'ZS',
                            legacy_id: 8351,
                            value: 'ZS',
                        },
                        {
                            label: 'ZS EV',
                            legacy_id: 8345,
                            value: 'ZS_EV',
                        },
                        {
                            label: 'ZT',
                            legacy_id: 7877,
                            value: 'zt',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2095,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MG',
                },
                {
                    label: 'MINI',
                    legacy_id: 7147,
                    options: [
                        {
                            label: '3-dørs',
                            legacy_id: 2000270,
                            value: '3doers',
                        },
                        {
                            label: 'Cabrio',
                            legacy_id: 2000271,
                            value: 'cabrio',
                        },
                        {
                            label: 'Clubman',
                            legacy_id: 2000105,
                            value: 'cooper s clubman',
                        },
                        {
                            label: 'Cooper',
                            legacy_id: 7150,
                            value: 'COOPER',
                        },
                        {
                            label: 'Cooper S',
                            legacy_id: 2000043,
                            value: 'cooper_s',
                        },
                        {
                            label: 'Countryman',
                            legacy_id: 2000173,
                            value: 'countrymann',
                        },
                        {
                            label: 'Coupe',
                            legacy_id: 2000236,
                            value: 'coupe',
                        },
                        {
                            label: 'One',
                            legacy_id: 7149,
                            value: 'ONE',
                        },
                        {
                            label: 'Paceman',
                            legacy_id: 2000247,
                            value: 'paceman',
                        },
                        {
                            label: 'Roadster',
                            legacy_id: 2000237,
                            value: 'roadster',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7151,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MINI',
                },
                {
                    label: 'Maserati',
                    legacy_id: 3001,
                    options: [
                        {
                            label: '222',
                            legacy_id: 7118,
                            value: '222',
                        },
                        {
                            label: '3200',
                            legacy_id: 3814,
                            value: '3200',
                        },
                        {
                            label: '3500',
                            legacy_id: 7124,
                            value: '3500',
                        },
                        {
                            label: 'Biturbo',
                            legacy_id: 7123,
                            value: 'BITURBO',
                        },
                        {
                            label: 'Coupe',
                            legacy_id: 7712,
                            value: 'COUPE',
                        },
                        {
                            label: 'Ghibli',
                            legacy_id: 7120,
                            value: 'GHIBLI',
                        },
                        {
                            label: 'GranTurismo',
                            legacy_id: 2000256,
                            value: 'granturismo',
                        },
                        {
                            label: 'Indy',
                            legacy_id: 7121,
                            value: 'INDY',
                        },
                        {
                            label: 'Levante',
                            legacy_id: 2000353,
                            value: 'levante',
                        },
                        {
                            label: 'MC20',
                            legacy_id: 2000531,
                            value: 'MC20',
                        },
                        {
                            label: 'Mistral',
                            legacy_id: 7122,
                            value: 'MISTRAL',
                        },
                        {
                            label: 'Quattroporte',
                            legacy_id: 7581,
                            value: 'QUATTROPORTE',
                        },
                        {
                            label: 'Quattroporte Evoluzzione',
                            legacy_id: 7119,
                            value: 'QUATTROPORTE EVOLUZZIONE',
                        },
                        {
                            label: 'Spyder',
                            legacy_id: 7325,
                            value: 'SPYDER',
                        },
                        {
                            label: 'GranCabrio',
                            legacy_id: 2000588,
                            value: 'GRANCABRIO',
                        },
                        {
                            label: 'Grecale',
                            legacy_id: 2000589,
                            value: 'GRECALE',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 3041,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MASERATI',
                },
                {
                    label: 'Matra',
                    legacy_id: 8070,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000110,
                            value: 'andre',
                        },
                        {
                            label: 'Bagheera',
                            legacy_id: 2000109,
                            value: 'bagheera',
                        },
                        {
                            label: 'Murena',
                            legacy_id: 2000108,
                            value: 'murena',
                        },
                    ],
                    value: 'matra',
                },
                {
                    label: 'Maxus',
                    legacy_id: 8096,
                    options: [
                        {
                            label: 'ev80',
                            legacy_id: 2000500,
                            value: 'EV80',
                        },
                        {
                            label: 'e-Deliver 3',
                            legacy_id: 2000523,
                            value: 'E_DELIVER_3',
                        },
                        {
                            label: 'e-Deliver 9',
                            legacy_id: 2000524,
                            value: 'E_DELIVER_9',
                        },
                        {
                            label: 'Euniq MPV',
                            legacy_id: 8364,
                            value: 'EUNIQ_MPV',
                        },
                        {
                            label: 'Euniq 5',
                            legacy_id: 2000564,
                            value: 'EUNIQ-5',
                        },
                        {
                            label: 'Euniq 6',
                            legacy_id: 2000565,
                            value: 'EUNIQ-6',
                        },
                        {
                            label: 'T90 EV',
                            legacy_id: 2000603,
                            value: 'ET90',
                        },
                    ],
                    value: 'MAXUS',
                },
                {
                    label: 'Maybach',
                    legacy_id: 8082,
                    options: [
                        {
                            label: '57',
                            legacy_id: 2000203,
                            value: '57',
                        },
                    ],
                    value: 'maybach',
                },
                {
                    label: 'Mazda',
                    legacy_id: 784,
                    options: [
                        {
                            label: '121',
                            legacy_id: 1141,
                            value: '121',
                        },
                        {
                            label: '2',
                            legacy_id: 8063,
                            value: 'mazda2',
                        },
                        {
                            label: '3',
                            legacy_id: 7795,
                            value: '3',
                        },
                        {
                            label: '323',
                            legacy_id: 1145,
                            value: '323',
                        },
                        {
                            label: '5',
                            legacy_id: 8147,
                            value: '5',
                        },
                        {
                            label: '6',
                            legacy_id: 7513,
                            value: '6',
                        },
                        {
                            label: '626',
                            legacy_id: 1147,
                            value: '626',
                        },
                        {
                            label: '929',
                            legacy_id: 1149,
                            value: '929',
                        },
                        {
                            label: 'B 2500 Freestyle Cab',
                            legacy_id: 8238,
                            value: 'B 2500 Freestyle Cab',
                        },
                        {
                            label: 'B2000',
                            legacy_id: 8122,
                            value: 'B2000',
                        },
                        {
                            label: 'B2200',
                            legacy_id: 1150,
                            value: 'B2200',
                        },
                        {
                            label: 'B2600',
                            legacy_id: 1151,
                            value: 'B2600',
                        },
                        {
                            label: 'BT-50',
                            legacy_id: 2000068,
                            value: 'BT-50',
                        },
                        {
                            label: 'CX-3',
                            legacy_id: 2000328,
                            value: 'cx-3',
                        },
                        {
                            label: 'CX-5',
                            legacy_id: 2000214,
                            value: 'cx5',
                        },
                        {
                            label: 'CX-7',
                            legacy_id: 2000152,
                            value: 'cx-7',
                        },
                        {
                            label: 'CX-30',
                            legacy_id: 2000519,
                            value: 'CX-30',
                        },
                        {
                            label: 'CX-60',
                            legacy_id: 8403,
                            value: 'CX-60',
                        },
                        {
                            label: 'Demio',
                            legacy_id: 3751,
                            value: 'DEMIO',
                        },
                        {
                            label: 'E2000',
                            legacy_id: 1152,
                            value: 'E2000',
                        },
                        {
                            label: 'MPV',
                            legacy_id: 3815,
                            value: 'MPV',
                        },
                        {
                            label: 'MX-3',
                            legacy_id: 1154,
                            value: 'MX-3',
                        },
                        {
                            label: 'MX-30',
                            legacy_id: 8377,
                            value: 'MX_30',
                        },
                        {
                            label: 'MX-5',
                            legacy_id: 1155,
                            value: 'MX-5',
                        },
                        {
                            label: 'Premacy',
                            legacy_id: 3816,
                            value: 'PREMACY',
                        },
                        {
                            label: 'RX-2',
                            legacy_id: 1157,
                            value: 'RX-2',
                        },
                        {
                            label: 'RX-7',
                            legacy_id: 1158,
                            value: 'RX-7',
                        },
                        {
                            label: 'RX-8',
                            legacy_id: 7797,
                            value: 'RX-8',
                        },
                        {
                            label: 'Tribute',
                            legacy_id: 7114,
                            value: 'TRIBUTE',
                        },
                        {
                            label: 'Xedos',
                            legacy_id: 1159,
                            value: 'XEDOS',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2093,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MAZDA',
                },
                {
                    label: 'McLaren',
                    legacy_id: 8087,
                    options: [
                        {
                            label: '12C',
                            legacy_id: 2000302,
                            value: '12c',
                        },
                        {
                            label: '570GT',
                            legacy_id: 2000453,
                            value: '570GT',
                        },
                        {
                            label: '570S',
                            legacy_id: 2000395,
                            value: '570S',
                        },
                        {
                            label: '675LT',
                            legacy_id: 2000550,
                            value: '675LT',
                        },
                        {
                            label: '720S',
                            legacy_id: 8397,
                            value: '720S',
                        },
                        {
                            label: '765LT',
                            legacy_id: 2000551,
                            value: '765LT',
                        },
                        {
                            label: 'Artura',
                            legacy_id: 2000608,
                            value: 'Artura',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2000303,
                            value: 'andre',
                        },
                    ],
                    value: 'mclaren',
                },
                {
                    label: 'Mercedes-Benz',
                    legacy_id: 785,
                    options: [
                        {
                            label: '190',
                            legacy_id: 8112,
                            value: 'mb190e',
                        },
                        {
                            label: 'A-Klasse',
                            legacy_id: 3777,
                            value: 'A-KLASSE',
                        },
                        {
                            label: 'AMG GT',
                            legacy_id: 2000369,
                            value: 'amg gt',
                        },
                        {
                            label: 'AMG GT C',
                            legacy_id: 8346,
                            value: 'AMG_GT_C',
                        },
                        {
                            label: 'AMG GT R',
                            legacy_id: 8347,
                            value: 'AMG_GT_R',
                        },
                        {
                            label: 'AMG GT S',
                            legacy_id: 8348,
                            value: 'AMG_GT_S',
                        },
                        {
                            label: 'B-Klasse',
                            legacy_id: 8111,
                            value: 'B-KLASSE',
                        },
                        {
                            label: 'C-Klasse',
                            legacy_id: 3776,
                            value: 'C-KLASSE',
                        },
                        {
                            label: 'C-Klasse All-Terrain',
                            legacy_id: 8311,
                            value: 'C-KLASSE-ALL-TERRAIN',
                        },
                        {
                            label: 'CL',
                            legacy_id: 7112,
                            value: 'CL',
                        },
                        {
                            label: 'CLA',
                            legacy_id: 2000250,
                            value: 'cla',
                        },
                        {
                            label: 'CLC',
                            legacy_id: 2000100,
                            value: 'clc',
                        },
                        {
                            label: 'CLK',
                            legacy_id: 6713,
                            value: 'CLK',
                        },
                        {
                            label: 'CLS',
                            legacy_id: 7960,
                            value: 'cls',
                        },
                        {
                            label: 'Citan',
                            legacy_id: 2000230,
                            value: 'citan',
                        },
                        {
                            label: 'E-Klasse',
                            legacy_id: 3775,
                            value: 'E-KLASSE',
                        },
                        {
                            label: 'E-klasse All-Terrain',
                            legacy_id: 2000421,
                            value: 'e-klasse all-terrain',
                        },
                        {
                            label: 'EQC',
                            legacy_id: 2000515,
                            value: 'EQC',
                        },
                        {
                            label: 'EQS',
                            legacy_id: 8303,
                            value: 'EQS',
                        },
                        {
                            label: 'EQB',
                            legacy_id: 2000559,
                            value: 'EQB',
                        },
                        {
                            label: 'EQE',
                            legacy_id: 8312,
                            value: 'EQE',
                        },
                        {
                            label: 'EQV',
                            legacy_id: 8394,
                            value: 'EQV',
                        },
                        {
                            label: 'EQA',
                            legacy_id: 2000536,
                            value: 'EQA',
                        },
                        {
                            label: 'EQS SUV',
                            legacy_id: 2000605,
                            value: 'EQSSUV',
                        },
                        {
                            label: 'G-Klasse',
                            legacy_id: 1507,
                            value: 'G-Klasse',
                        },
                        {
                            label: 'GL',
                            legacy_id: 8289,
                            value: 'gl',
                        },
                        {
                            label: 'GLA',
                            legacy_id: 2000285,
                            value: 'gla',
                        },
                        {
                            label: 'GLB',
                            legacy_id: 8393,
                            value: 'GLB',
                        },
                        {
                            label: 'GLC',
                            legacy_id: 2000332,
                            value: 'glc',
                        },
                        {
                            label: 'GLE',
                            legacy_id: 2000327,
                            value: 'gle',
                        },
                        {
                            label: 'GLK',
                            legacy_id: 2000096,
                            value: 'glk',
                        },
                        {
                            label: 'GLS',
                            legacy_id: 2000384,
                            value: 'GLS',
                        },
                        {
                            label: 'Geländewagen',
                            legacy_id: 2259,
                            value: 'GELANDEWAGEN',
                        },
                        {
                            label: 'M-Klasse',
                            legacy_id: 3774,
                            value: 'M-KLASSE',
                        },
                        {
                            label: 'R-Klasse',
                            legacy_id: 8121,
                            value: 'r-klasse',
                        },
                        {
                            label: 'S-Klasse',
                            legacy_id: 3773,
                            value: 'S-KLASSE',
                        },
                        {
                            label: 'SL',
                            legacy_id: 7113,
                            value: 'SL',
                        },
                        {
                            label: 'SLC',
                            legacy_id: 2000333,
                            value: 'slc',
                        },
                        {
                            label: 'SLK',
                            legacy_id: 6712,
                            value: 'SLK',
                        },
                        {
                            label: 'SLR',
                            legacy_id: 987,
                            value: 'SLR',
                        },
                        {
                            label: 'SLS',
                            legacy_id: 2000177,
                            value: 'sls',
                        },
                        {
                            label: 'Sprinter',
                            legacy_id: 1187,
                            value: 'SPRINTER',
                        },
                        {
                            label: 'V-Klasse',
                            legacy_id: 3772,
                            value: 'V-KLASSE',
                        },
                        {
                            label: 'Vaneo',
                            legacy_id: 7601,
                            value: 'VANEO',
                        },
                        {
                            label: 'Viano',
                            legacy_id: 7871,
                            value: 'viano',
                        },
                        {
                            label: 'Vito',
                            legacy_id: 2897,
                            value: 'VITO',
                        },
                        {
                            label: 'X-Klasse',
                            legacy_id: 2000422,
                            value: 'X-Klasse',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2094,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MERCEDES-BENZ',
                },
                {
                    label: 'Mercury',
                    legacy_id: 7554,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 7555,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MERCURY',
                },
                {
                    label: 'Mitsubishi',
                    legacy_id: 787,
                    options: [
                        {
                            label: '3000 gt',
                            legacy_id: 7155,
                            value: '3000 GT',
                        },
                        {
                            label: 'ASX',
                            legacy_id: 2000165,
                            value: 'asx',
                        },
                        {
                            label: 'Carisma',
                            legacy_id: 1190,
                            value: 'CARISMA',
                        },
                        {
                            label: 'Colt',
                            legacy_id: 1191,
                            value: 'COLT',
                        },
                        {
                            label: 'Eclipse',
                            legacy_id: 1193,
                            value: 'ECLIPSE',
                        },
                        {
                            label: 'Eclipse Cross',
                            legacy_id: 2000439,
                            value: 'Eclipse Cross',
                        },
                        {
                            label: 'Galant',
                            legacy_id: 1194,
                            value: 'GALANT',
                        },
                        {
                            label: 'Grandis',
                            legacy_id: 7994,
                            value: 'GRANDIS',
                        },
                        {
                            label: 'L200',
                            legacy_id: 1195,
                            value: 'L200',
                        },
                        {
                            label: 'L300',
                            legacy_id: 1196,
                            value: 'L300',
                        },
                        {
                            label: 'L400',
                            legacy_id: 3046,
                            value: 'L400',
                        },
                        {
                            label: 'Lancer',
                            legacy_id: 1197,
                            value: 'LANCER',
                        },
                        {
                            label: 'Outlander',
                            legacy_id: 7713,
                            value: 'OUTLANDER',
                        },
                        {
                            label: 'Pajero',
                            legacy_id: 1198,
                            value: 'PAJERO',
                        },
                        {
                            label: 'Pajero Pinin',
                            legacy_id: 7992,
                            value: 'PAJERO PININ',
                        },
                        {
                            label: 'Pajero Sport',
                            legacy_id: 7953,
                            value: 'pajero sport',
                        },
                        {
                            label: 'Sapporo',
                            legacy_id: 1199,
                            value: 'SAPPORO',
                        },
                        {
                            label: 'Sigma',
                            legacy_id: 1200,
                            value: 'SIGMA',
                        },
                        {
                            label: 'Space Gear',
                            legacy_id: 8144,
                            value: 'SPACE GEAR',
                        },
                        {
                            label: 'Space Runner',
                            legacy_id: 7185,
                            value: 'SPACE RUNNER',
                        },
                        {
                            label: 'Space Star',
                            legacy_id: 3752,
                            value: 'SPACE STAR',
                        },
                        {
                            label: 'Space Star I',
                            legacy_id: 2000239,
                            value: 'space_star_i',
                        },
                        {
                            label: 'Space Wagon',
                            legacy_id: 1201,
                            value: 'SPACE WAGON',
                        },
                        {
                            label: 'i-Miev',
                            legacy_id: 2000181,
                            value: 'miev',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2096,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MITSUBISHI',
                },
                {
                    label: 'Morgan',
                    legacy_id: 788,
                    options: [
                        {
                            label: '3-wheeler',
                            legacy_id: 2000258,
                            value: '3_wheeler',
                        },
                        {
                            label: '4/4',
                            legacy_id: 7762,
                            value: '4/4',
                        },
                        {
                            label: 'Aero 8',
                            legacy_id: 7761,
                            value: 'AERO 8',
                        },
                        {
                            label: 'Plus 4',
                            legacy_id: 3049,
                            value: 'PLUS 4',
                        },
                        {
                            label: 'Plus 8',
                            legacy_id: 1204,
                            value: 'PLUS 8',
                        },
                        {
                            label: 'Roadster',
                            legacy_id: 3866,
                            value: 'roadster',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2097,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MORGAN',
                },
                {
                    label: 'Morris',
                    legacy_id: 789,
                    options: [
                        {
                            label: 'Mini',
                            legacy_id: 1209,
                            value: 'MINI',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2098,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'MORRIS',
                },
                {
                    label: 'NIO',
                    legacy_id: 8109,
                    options: [
                        {
                            label: 'ES8',
                            legacy_id: 2000568,
                            value: 'ES8',
                        },
                        {
                            label: 'ET7',
                            legacy_id: 8405,
                            value: 'ET7',
                        },
                        {
                            label: 'ET5',
                            legacy_id: 2000616,
                            value: 'ET5',
                        },
                    ],
                    value: 'NIO',
                },
                {
                    label: 'Nissan',
                    legacy_id: 792,
                    options: [
                        {
                            label: '100 NX',
                            legacy_id: 1214,
                            value: '100 NX',
                        },
                        {
                            label: '200 SX',
                            legacy_id: 1215,
                            value: '200 SX',
                        },
                        {
                            label: '300 ZX',
                            legacy_id: 1216,
                            value: '300 ZX',
                        },
                        {
                            label: '350 Z',
                            legacy_id: 7950,
                            value: '350 z',
                        },
                        {
                            label: '370z',
                            legacy_id: 2000537,
                            value: '370z',
                        },
                        {
                            label: 'Almera',
                            legacy_id: 1217,
                            value: 'ALMERA',
                        },
                        {
                            label: 'Ariya',
                            legacy_id: 2000584,
                            value: 'ARIYA',
                        },
                        {
                            label: 'Bluebird',
                            legacy_id: 1218,
                            value: 'BLUEBIRD',
                        },
                        {
                            label: 'Cherry',
                            legacy_id: 1219,
                            value: 'CHERRY',
                        },
                        {
                            label: 'Cube',
                            legacy_id: 2000404,
                            value: 'CUBE',
                        },
                        {
                            label: 'GT-R',
                            legacy_id: 2000200,
                            value: 'gt-r',
                        },
                        {
                            label: 'Interstar',
                            legacy_id: 7984,
                            value: 'INTERSTAR',
                        },
                        {
                            label: 'Juke',
                            legacy_id: 2000175,
                            value: 'juke',
                        },
                        {
                            label: 'King Cab',
                            legacy_id: 1220,
                            value: 'KING CAB',
                        },
                        {
                            label: 'King Van',
                            legacy_id: 8163,
                            value: 'king van',
                        },
                        {
                            label: 'Kubistar',
                            legacy_id: 7983,
                            value: 'KUBISTAR',
                        },
                        {
                            label: 'Laurel',
                            legacy_id: 1221,
                            value: 'LAUREL',
                        },
                        {
                            label: 'Leaf',
                            legacy_id: 2000183,
                            value: 'leaf',
                        },
                        {
                            label: 'Maxima',
                            legacy_id: 1222,
                            value: 'MAXIMA',
                        },
                        {
                            label: 'Micra',
                            legacy_id: 1223,
                            value: 'MICRA',
                        },
                        {
                            label: 'Murano',
                            legacy_id: 2000046,
                            value: 'murano',
                        },
                        {
                            label: 'NP300',
                            legacy_id: 2000260,
                            value: 'np300',
                        },
                        {
                            label: 'NV200',
                            legacy_id: 2000162,
                            value: 'nv200',
                        },
                        {
                            label: 'NV250',
                            legacy_id: 8367,
                            value: 'nv250',
                        },
                        {
                            label: 'NV300',
                            legacy_id: 2000405,
                            value: 'NV300',
                        },
                        {
                            label: 'NV400',
                            legacy_id: 2000234,
                            value: 'nv400',
                        },
                        {
                            label: 'Navara',
                            legacy_id: 8234,
                            value: 'navara',
                        },
                        {
                            label: 'Note',
                            legacy_id: 6753,
                            value: 'note',
                        },
                        {
                            label: 'Pathfinder',
                            legacy_id: 7985,
                            value: 'PATHFINDER',
                        },
                        {
                            label: 'Patrol',
                            legacy_id: 1224,
                            value: 'PATROL',
                        },
                        {
                            label: 'Pixo',
                            legacy_id: 2000161,
                            value: 'pixo',
                        },
                        {
                            label: 'Prairie',
                            legacy_id: 1225,
                            value: 'PRAIRIE',
                        },
                        {
                            label: 'Primastar',
                            legacy_id: 7685,
                            value: 'PRIMASTAR',
                        },
                        {
                            label: 'Primera',
                            legacy_id: 1226,
                            value: 'PRIMERA',
                        },
                        {
                            label: 'Pulsar',
                            legacy_id: 2000308,
                            value: 'pulsar',
                        },
                        {
                            label: 'Qashqai',
                            legacy_id: 2000061,
                            value: 'qashqai',
                        },
                        {
                            label: 'Qashqai +2',
                            legacy_id: 2000160,
                            value: 'qashqai+2',
                        },
                        {
                            label: 'Serena',
                            legacy_id: 1227,
                            value: 'SERENA',
                        },
                        {
                            label: 'Skyline',
                            legacy_id: 8142,
                            value: 'SKYLINE',
                        },
                        {
                            label: 'Stanza',
                            legacy_id: 1229,
                            value: 'STANZA',
                        },
                        {
                            label: 'Sunny',
                            legacy_id: 1230,
                            value: 'SUNNY',
                        },
                        {
                            label: 'Terrano',
                            legacy_id: 1231,
                            value: 'TERRANO',
                        },
                        {
                            label: 'Tino',
                            legacy_id: 7595,
                            value: 'TINO',
                        },
                        {
                            label: 'Townstar',
                            legacy_id: 2000576,
                            value: 'TOWNSTAR',
                        },
                        {
                            label: 'X-Trail',
                            legacy_id: 7188,
                            value: 'X-TRAIL',
                        },
                        {
                            label: 'e-NV200',
                            legacy_id: 2000311,
                            value: 'e-nv200',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2101,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'NISSAN',
                },
                {
                    label: 'NOSMOKE',
                    legacy_id: 823,
                    options: [
                        {
                            label: 'Original',
                            legacy_id: 8401,
                            value: 'original',
                        },
                        {
                            label: 'Truckï',
                            legacy_id: 8402,
                            value: 'trucki',
                        },
                    ],
                    value: 'NOSMOKE',
                },
                {
                    label: 'Oldsmobile',
                    legacy_id: 794,
                    options: [
                        {
                            label: 'Custom',
                            legacy_id: 1237,
                            value: 'CUSTOM',
                        },
                        {
                            label: 'Cutlass',
                            legacy_id: 1238,
                            value: 'CUTLASS',
                        },
                        {
                            label: 'Delta',
                            legacy_id: 1239,
                            value: 'DELTA',
                        },
                        {
                            label: 'Omega',
                            legacy_id: 1240,
                            value: 'OMEGA',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2103,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'OLDSMOBILE',
                },
                {
                    label: 'Opel',
                    legacy_id: 795,
                    options: [
                        {
                            label: 'ADAM',
                            legacy_id: 2000248,
                            value: 'adam',
                        },
                        {
                            label: 'Agila',
                            legacy_id: 6750,
                            value: 'AGILA',
                        },
                        {
                            label: 'Ampera',
                            legacy_id: 2000205,
                            value: 'ampera',
                        },
                        {
                            label: 'Antara',
                            legacy_id: 2000065,
                            value: 'antara',
                        },
                        {
                            label: 'Ascona',
                            legacy_id: 1246,
                            value: 'ASCONA',
                        },
                        {
                            label: 'Astra',
                            legacy_id: 1247,
                            value: 'ASTRA',
                        },
                        {
                            label: 'Calibra',
                            legacy_id: 1248,
                            value: 'CALIBRA',
                        },
                        {
                            label: 'Campo',
                            legacy_id: 1249,
                            value: 'CAMPO',
                        },
                        {
                            label: 'Combo',
                            legacy_id: 1252,
                            value: 'COMBO',
                        },
                        {
                            label: 'Commodore',
                            legacy_id: 1253,
                            value: 'COMMODORE',
                        },
                        {
                            label: 'Corsa',
                            legacy_id: 1254,
                            value: 'CORSA',
                        },
                        {
                            label: 'Crossland X',
                            legacy_id: 2000429,
                            value: 'Crossland X',
                        },
                        {
                            label: 'Frontera',
                            legacy_id: 1255,
                            value: 'FRONTERA',
                        },
                        {
                            label: 'GT',
                            legacy_id: 2000081,
                            value: 'gt',
                        },
                        {
                            label: 'Grandland',
                            legacy_id: 8406,
                            value: 'grandland',
                        },
                        {
                            label: 'Grandland X',
                            legacy_id: 2000428,
                            value: 'Grandland X',
                        },
                        {
                            label: 'Insignia',
                            legacy_id: 2000099,
                            value: 'insignia',
                        },
                        {
                            label: 'Insignia Country Tourer',
                            legacy_id: 2000288,
                            value: 'onsigniacountry tourer',
                        },
                        {
                            label: 'Kadett',
                            legacy_id: 1259,
                            value: 'KADETT',
                        },
                        {
                            label: 'Kapitan',
                            legacy_id: 2000378,
                            value: 'Kapitan',
                        },
                        {
                            label: 'Manta',
                            legacy_id: 1261,
                            value: 'MANTA',
                        },
                        {
                            label: 'Meriva',
                            legacy_id: 7715,
                            value: 'MERIVA',
                        },
                        {
                            label: 'Mokka',
                            legacy_id: 2000226,
                            value: 'mokka',
                        },
                        {
                            label: 'Monterey',
                            legacy_id: 2144,
                            value: 'MONTEREY',
                        },
                        {
                            label: 'Monza',
                            legacy_id: 1262,
                            value: 'MONZA',
                        },
                        {
                            label: 'Movano',
                            legacy_id: 7186,
                            value: 'MOVANO',
                        },
                        {
                            label: 'Omega',
                            legacy_id: 1264,
                            value: 'OMEGA',
                        },
                        {
                            label: 'Rekord',
                            legacy_id: 1265,
                            value: 'REKORD',
                        },
                        {
                            label: 'Senator',
                            legacy_id: 1266,
                            value: 'SENATOR',
                        },
                        {
                            label: 'Signum',
                            legacy_id: 7796,
                            value: 'SIGNUM',
                        },
                        {
                            label: 'Sintra',
                            legacy_id: 3250,
                            value: 'SINTRA',
                        },
                        {
                            label: 'Speedster',
                            legacy_id: 7187,
                            value: 'SPEEDSTER',
                        },
                        {
                            label: 'Tigra',
                            legacy_id: 1267,
                            value: 'TIGRA',
                        },
                        {
                            label: 'Vectra',
                            legacy_id: 1268,
                            value: 'VECTRA',
                        },
                        {
                            label: 'Vivaro',
                            legacy_id: 7623,
                            value: 'VIVARO',
                        },
                        {
                            label: 'Zafira',
                            legacy_id: 3753,
                            value: 'ZAFIRA',
                        },
                        {
                            label: 'Zafira Tourer',
                            legacy_id: 2000274,
                            value: 'zafiratourer',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2104,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'OPEL',
                },
                {
                    label: 'Packard',
                    legacy_id: 8077,
                    options: [
                        {
                            label: 'Clipper',
                            legacy_id: 2000126,
                            value: 'clipper',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2000249,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'packard',
                },
                {
                    label: 'Panther',
                    legacy_id: 8098,
                    options: [
                        {
                            label: 'J72',
                            legacy_id: 1504,
                            value: 'J72',
                        },
                        {
                            label: 'Kallista',
                            legacy_id: 1506,
                            value: 'Kallista',
                        },
                        {
                            label: 'Lima',
                            legacy_id: 1505,
                            value: 'Lima',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 1500,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'Panther',
                },
                {
                    label: 'Peugeot',
                    legacy_id: 796,
                    options: [
                        {
                            label: '1007',
                            legacy_id: 8120,
                            value: '1007',
                        },
                        {
                            label: '106',
                            legacy_id: 1270,
                            value: '106',
                        },
                        {
                            label: '106 Electric',
                            legacy_id: 8246,
                            value: '106 electric',
                        },
                        {
                            label: '107',
                            legacy_id: 8293,
                            value: '107',
                        },
                        {
                            label: '108',
                            legacy_id: 2000299,
                            value: 'peugeot108',
                        },
                        {
                            label: '2008',
                            legacy_id: 2000259,
                            value: '2008',
                        },
                        {
                            label: '205',
                            legacy_id: 1272,
                            value: '205',
                        },
                        {
                            label: '206',
                            legacy_id: 3755,
                            value: '206',
                        },
                        {
                            label: '206 CC',
                            legacy_id: 7586,
                            value: '206 CC',
                        },
                        {
                            label: '206 SW',
                            legacy_id: 7585,
                            value: '206 SW',
                        },
                        {
                            label: '207',
                            legacy_id: 8294,
                            value: '207',
                        },
                        {
                            label: '207 CC',
                            legacy_id: 2000391,
                            value: '207 CC',
                        },
                        {
                            label: '207 SW',
                            legacy_id: 2000073,
                            value: '207SW',
                        },
                        {
                            label: '208',
                            legacy_id: 2000212,
                            value: '208',
                        },
                        {
                            label: '3008',
                            legacy_id: 2000131,
                            value: '3008',
                        },
                        {
                            label: '305',
                            legacy_id: 1274,
                            value: '305',
                        },
                        {
                            label: '306',
                            legacy_id: 1275,
                            value: '306',
                        },
                        {
                            label: '307',
                            legacy_id: 7138,
                            value: '307',
                        },
                        {
                            label: '307 CC',
                            legacy_id: 7993,
                            value: '307 CC',
                        },
                        {
                            label: '307 SW',
                            legacy_id: 7716,
                            value: '307 SW',
                        },
                        {
                            label: '308',
                            legacy_id: 2000079,
                            value: '308',
                        },
                        {
                            label: '308 SW',
                            legacy_id: 2000101,
                            value: '308sw',
                        },
                        {
                            label: '309',
                            legacy_id: 1276,
                            value: '309',
                        },
                        {
                            label: '4007',
                            legacy_id: 2000074,
                            value: '4007',
                        },
                        {
                            label: '4008',
                            legacy_id: 2000213,
                            value: '4008',
                        },
                        {
                            label: '405',
                            legacy_id: 1279,
                            value: '405',
                        },
                        {
                            label: '406',
                            legacy_id: 1280,
                            value: '406',
                        },
                        {
                            label: '407',
                            legacy_id: 7958,
                            value: '407',
                        },
                        {
                            label: '5008',
                            legacy_id: 2000139,
                            value: '5008',
                        },
                        {
                            label: '505',
                            legacy_id: 1282,
                            value: '505',
                        },
                        {
                            label: '508',
                            legacy_id: 2000188,
                            value: '508',
                        },
                        {
                            label: '605',
                            legacy_id: 1284,
                            value: '605',
                        },
                        {
                            label: '607',
                            legacy_id: 6714,
                            value: '607',
                        },
                        {
                            label: '806',
                            legacy_id: 3051,
                            value: '806',
                        },
                        {
                            label: '807',
                            legacy_id: 7634,
                            value: '807',
                        },
                        {
                            label: 'Bipper',
                            legacy_id: 2000329,
                            value: 'bipper',
                        },
                        {
                            label: 'Boxer',
                            legacy_id: 1285,
                            value: 'BOXER',
                        },
                        {
                            label: 'Expert',
                            legacy_id: 7621,
                            value: 'EXPERT',
                        },
                        {
                            label: 'e-Expert',
                            legacy_id: 2000545,
                            value: 'e-Expert',
                        },
                        {
                            label: 'Partner',
                            legacy_id: 3754,
                            value: 'PARTNER',
                        },
                        {
                            label: 'Partner Electric',
                            legacy_id: 7767,
                            value: 'PARTNER ELECTRIC',
                        },
                        {
                            label: 'RCZ',
                            legacy_id: 2000169,
                            value: 'rcz',
                        },
                        {
                            label: 'Rifter',
                            legacy_id: 2000508,
                            value: 'Rifter',
                        },
                        {
                            label: 'Traveller',
                            legacy_id: 2000420,
                            value: 'Traveller',
                        },
                        {
                            label: 'iOn',
                            legacy_id: 2000189,
                            value: 'ion',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2105,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'PEUGEOT',
                },
                {
                    label: 'Piaggio',
                    legacy_id: 8084,
                    options: [
                        {
                            label: 'Porter',
                            legacy_id: 2000219,
                            value: 'porter',
                        },
                        {
                            label: 'Porter Maxxi',
                            legacy_id: 2000220,
                            value: 'portermaxxi',
                        },
                    ],
                    value: 'piaggio',
                },
                {
                    label: 'Plymouth',
                    legacy_id: 797,
                    options: [
                        {
                            label: 'Grand Voyager',
                            legacy_id: 1290,
                            value: 'GRAND VOYAGER',
                        },
                        {
                            label: 'Roadrunner',
                            legacy_id: 2000511,
                            value: 'Roadrunner',
                        },
                        {
                            label: 'Voyager',
                            legacy_id: 1291,
                            value: 'VOYAGER',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2106,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'PLYMOUTH',
                },
                {
                    label: 'Polestar',
                    legacy_id: 8102,
                    options: [
                        {
                            label: '1',
                            legacy_id: 8369,
                            value: '1',
                        },
                        {
                            label: '2',
                            legacy_id: 8370,
                            value: '2',
                        },
                    ],
                    value: 'POLESTAR',
                },
                {
                    label: 'Pontiac',
                    legacy_id: 800,
                    options: [
                        {
                            label: 'Bonneville',
                            legacy_id: 1294,
                            value: 'BONNEVILLE',
                        },
                        {
                            label: 'Fiero',
                            legacy_id: 1295,
                            value: 'FIERO',
                        },
                        {
                            label: 'Firebird',
                            legacy_id: 1296,
                            value: 'FIREBIRD',
                        },
                        {
                            label: 'Solstice',
                            legacy_id: 2000293,
                            value: 'solstice',
                        },
                        {
                            label: 'Trans Am',
                            legacy_id: 1299,
                            value: 'TRANS AM',
                        },
                        {
                            label: 'Trans Sport',
                            legacy_id: 1300,
                            value: 'TRANS SPORT',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2109,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'PONTIAC',
                },
                {
                    label: 'Porsche',
                    legacy_id: 801,
                    options: [
                        {
                            label: '356',
                            legacy_id: 7237,
                            value: '356',
                        },
                        {
                            label: '911',
                            legacy_id: 1301,
                            value: '911',
                        },
                        {
                            label: '914',
                            legacy_id: 7347,
                            value: '914',
                        },
                        {
                            label: '924',
                            legacy_id: 1302,
                            value: '924',
                        },
                        {
                            label: '928',
                            legacy_id: 7342,
                            value: '928',
                        },
                        {
                            label: '944',
                            legacy_id: 1303,
                            value: '944',
                        },
                        {
                            label: '968',
                            legacy_id: 7239,
                            value: '968',
                        },
                        {
                            label: 'Boxster',
                            legacy_id: 3756,
                            value: 'BOXSTER',
                        },
                        {
                            label: 'Carrera GT',
                            legacy_id: 2000275,
                            value: 'carreragt',
                        },
                        {
                            label: 'Cayenne',
                            legacy_id: 7714,
                            value: 'CAYENNE',
                        },
                        {
                            label: 'Cayenne Coupe',
                            legacy_id: 2000612,
                            value: 'CAYENNE_COUPE',
                        },
                        {
                            label: 'Cayman',
                            legacy_id: 2000067,
                            value: 'cayman',
                        },
                        {
                            label: 'Macan',
                            legacy_id: 2000276,
                            value: 'macan',
                        },
                        {
                            label: 'Panamera',
                            legacy_id: 2000121,
                            value: 'panamera',
                        },
                        {
                            label: 'Taycan',
                            legacy_id: 2000527,
                            value: 'TAYCAN',
                        },
                        {
                            label: 'Taycan Cross Turismo',
                            legacy_id: 2000556,
                            value: 'TAYCAN_CROSS_TURISMO',
                        },
                        {
                            label: 'Taycan Sport Turismo',
                            legacy_id: 2000611,
                            value: 'TAYCAN_SPORT_TURISMO',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2110,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'PORSCHE',
                },
                {
                    label: 'Radical',
                    legacy_id: 8088,
                    options: [
                        {
                            label: 'RXC',
                            legacy_id: 2000315,
                            value: 'rxc',
                        },
                        {
                            label: 'SR1',
                            legacy_id: 2000312,
                            value: 'sr1',
                        },
                        {
                            label: 'SR3',
                            legacy_id: 2000313,
                            value: 'sr3',
                        },
                        {
                            label: 'SR8',
                            legacy_id: 2000314,
                            value: 'sr8',
                        },
                    ],
                    value: 'radical',
                },
                {
                    label: 'RAM',
                    legacy_id: 8100,
                    options: [
                        {
                            label: '500',
                            legacy_id: 8353,
                            value: '500',
                        },
                        {
                            label: '1500',
                            legacy_id: 2000606,
                            value: '1500',
                        },
                        {
                            label: '2500',
                            legacy_id: 8354,
                            value: '2500',
                        },
                        {
                            label: '3500',
                            legacy_id: 8355,
                            value: '3500',
                        },
                        {
                            label: '5500',
                            legacy_id: 8356,
                            value: '5500',
                        },
                    ],
                    value: 'RAM',
                },
                {
                    label: 'Renault',
                    legacy_id: 804,
                    options: [
                        {
                            label: '12',
                            legacy_id: 1309,
                            value: '12',
                        },
                        {
                            label: '14',
                            legacy_id: 1310,
                            value: '14',
                        },
                        {
                            label: '15',
                            legacy_id: 1311,
                            value: '15',
                        },
                        {
                            label: '16',
                            legacy_id: 1312,
                            value: '16',
                        },
                        {
                            label: '17',
                            legacy_id: 1313,
                            value: '17',
                        },
                        {
                            label: '18',
                            legacy_id: 1314,
                            value: '18',
                        },
                        {
                            label: '19',
                            legacy_id: 1315,
                            value: '19',
                        },
                        {
                            label: '20',
                            legacy_id: 1316,
                            value: '20',
                        },
                        {
                            label: '30',
                            legacy_id: 1317,
                            value: '30',
                        },
                        {
                            label: '5',
                            legacy_id: 1319,
                            value: '5',
                        },
                        {
                            label: 'Avantime',
                            legacy_id: 7850,
                            value: 'avantime',
                        },
                        {
                            label: 'C3',
                            legacy_id: 7544,
                            value: 'C3',
                        },
                        {
                            label: 'Captur',
                            legacy_id: 2000268,
                            value: 'captur',
                        },
                        {
                            label: 'Clio',
                            legacy_id: 1322,
                            value: 'CLIO',
                        },
                        {
                            label: 'Espace',
                            legacy_id: 1324,
                            value: 'ESPACE',
                        },
                        {
                            label: 'Express',
                            legacy_id: 2000547,
                            value: 'RenaultExpress',
                        },
                        {
                            label: 'Fuego',
                            legacy_id: 1326,
                            value: 'FUEGO',
                        },
                        {
                            label: 'Grand Espace',
                            legacy_id: 7648,
                            value: 'GRAND ESPACE',
                        },
                        {
                            label: 'Grand Scenic',
                            legacy_id: 8172,
                            value: 'grandscenic',
                        },
                        {
                            label: 'Kadjar',
                            legacy_id: 2000326,
                            value: 'kadjar',
                        },
                        {
                            label: 'Kangoo',
                            legacy_id: 3820,
                            value: 'KANGOO',
                        },
                        {
                            label: 'Kangoo Electric',
                            legacy_id: 7769,
                            value: 'KANGOO ELECTRIC',
                        },
                        {
                            label: 'Kangoo Express',
                            legacy_id: 7241,
                            value: 'EXPRESS',
                        },
                        {
                            label: 'Koleos',
                            legacy_id: 2000427,
                            value: 'Koleos',
                        },
                        {
                            label: 'Laguna',
                            legacy_id: 1328,
                            value: 'LAGUNA',
                        },
                        {
                            label: 'Master',
                            legacy_id: 7455,
                            value: 'MASTER',
                        },
                        {
                            label: 'Megane',
                            legacy_id: 1331,
                            value: 'MEGANE',
                        },
                        {
                            label: 'Megane CC',
                            legacy_id: 2000078,
                            value: 'meganecc',
                        },
                        {
                            label: 'Modus',
                            legacy_id: 8171,
                            value: 'modus',
                        },
                        {
                            label: 'Scenic',
                            legacy_id: 3956,
                            value: 'SCENIC',
                        },
                        {
                            label: 'Talisman',
                            legacy_id: 2000372,
                            value: 'Talisman',
                        },
                        {
                            label: 'Trafic',
                            legacy_id: 1332,
                            value: 'TRAFFIC',
                        },
                        {
                            label: 'Twingo',
                            legacy_id: 7782,
                            value: 'TWINGO',
                        },
                        {
                            label: 'Twizy',
                            legacy_id: 2000243,
                            value: 'twizy',
                        },
                        {
                            label: 'Zoe',
                            legacy_id: 2000301,
                            value: 'zoe',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2113,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'RENAULT',
                },
                {
                    label: 'Reva',
                    legacy_id: 8072,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2000119,
                            value: 'andre',
                        },
                    ],
                    value: 'reva',
                },
                {
                    label: 'Rolls Royce',
                    legacy_id: 7170,
                    options: [
                        {
                            label: 'Cullinan',
                            legacy_id: 2000580,
                            value: 'CULLINAN',
                        },
                        {
                            label: 'Ghost',
                            legacy_id: 2000399,
                            value: 'Ghost',
                        },
                        {
                            label: 'Phantom',
                            legacy_id: 2000507,
                            value: 'Phantom',
                        },
                        {
                            label: 'Silver Shadow',
                            legacy_id: 7171,
                            value: 'SILVER SHADOW',
                        },
                        {
                            label: 'Wraith',
                            legacy_id: 2000398,
                            value: 'Wraith',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7172,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ROLLS ROYCE',
                },
                {
                    label: 'Rover',
                    legacy_id: 805,
                    options: [
                        {
                            label: '200-serie',
                            legacy_id: 3054,
                            value: '200-SERIE',
                        },
                        {
                            label: '400-serie',
                            legacy_id: 1333,
                            value: '400-SERIE',
                        },
                        {
                            label: '600-serie',
                            legacy_id: 1335,
                            value: '600-SERIE',
                        },
                        {
                            label: '800-serie',
                            legacy_id: 2000028,
                            value: '800-SERIE',
                        },
                        {
                            label: 'Defender',
                            legacy_id: 7684,
                            value: 'DEFENDER',
                        },
                        {
                            label: 'Mini',
                            legacy_id: 2000255,
                            value: 'mini',
                        },
                        {
                            label: '25',
                            legacy_id: 6744,
                            value: '25',
                        },
                        {
                            label: '45',
                            legacy_id: 6745,
                            value: '45',
                        },
                        {
                            label: '75',
                            legacy_id: 3821,
                            value: '75',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2114,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ROVER',
                },
                {
                    label: 'Saab',
                    legacy_id: 806,
                    options: [
                        {
                            label: '9-3',
                            legacy_id: 3224,
                            value: '9-3',
                        },
                        {
                            label: '9-5',
                            legacy_id: 3057,
                            value: '9-5',
                        },
                        {
                            label: '900',
                            legacy_id: 1339,
                            value: '900',
                        },
                        {
                            label: '9000',
                            legacy_id: 1340,
                            value: '9000',
                        },
                        {
                            label: '99',
                            legacy_id: 1341,
                            value: '99',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2115,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SAAB',
                },
                {
                    label: 'Santana',
                    legacy_id: 8064,
                    options: [
                        {
                            label: 'PS10',
                            legacy_id: 8065,
                            value: 'PS10',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 8066,
                            value: 'OTHER',
                        },
                    ],
                    value: 'SANTANA',
                },
                {
                    label: 'Scion',
                    legacy_id: 822,
                    options: [
                        {
                            label: 'tC',
                            legacy_id: 985,
                            value: 'tC',
                        },
                        {
                            label: 'xA',
                            legacy_id: 981,
                            value: 'xA',
                        },
                        {
                            label: 'xB',
                            legacy_id: 983,
                            value: 'xB',
                        },
                    ],
                    value: 'SCION',
                },
                {
                    label: 'Seat',
                    legacy_id: 807,
                    options: [
                        {
                            label: 'Alhambra',
                            legacy_id: 3825,
                            value: 'ALHAMBRA',
                        },
                        {
                            label: 'Altea',
                            legacy_id: 7948,
                            value: 'altea',
                        },
                        {
                            label: 'Arona',
                            legacy_id: 2000456,
                            value: 'Arona',
                        },
                        {
                            label: 'Arosa',
                            legacy_id: 3824,
                            value: 'AROSA',
                        },
                        {
                            label: 'Ateca',
                            legacy_id: 2000448,
                            value: 'Ateca',
                        },
                        {
                            label: 'Cordoba',
                            legacy_id: 1345,
                            value: 'CORDOBA',
                        },
                        {
                            label: 'Cordoba Vario',
                            legacy_id: 7175,
                            value: 'CORDOBA VARIO',
                        },
                        {
                            label: 'Ibiza',
                            legacy_id: 1346,
                            value: 'IBIZA',
                        },
                        {
                            label: 'Inca',
                            legacy_id: 7110,
                            value: 'INCA',
                        },
                        {
                            label: 'Leon',
                            legacy_id: 6746,
                            value: 'LEON',
                        },
                        {
                            label: 'Leon XP',
                            legacy_id: 2000457,
                            value: 'Leon XP',
                        },
                        {
                            label: 'Mii',
                            legacy_id: 2000520,
                            value: 'MII',
                        },
                        {
                            label: 'Tarraco',
                            legacy_id: 2000516,
                            value: 'Tarraco',
                        },
                        {
                            label: 'Toledo',
                            legacy_id: 1347,
                            value: 'TOLEDO',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2116,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SEAT',
                },
                {
                    label: 'Seres',
                    legacy_id: 8108,
                    options: [
                        {
                            label: '3',
                            legacy_id: 8310,
                            value: '3',
                        },
                        {
                            label: 'SF5',
                            legacy_id: 2000561,
                            value: 'SF5',
                        },
                        {
                            label: 'SF7',
                            legacy_id: 2000562,
                            value: 'SF7',
                        },
                    ],
                    value: 'SERES',
                },
                {
                    label: 'Skoda',
                    legacy_id: 808,
                    options: [
                        {
                            label: 'Citigo',
                            legacy_id: 2000224,
                            value: 'citigo',
                        },
                        {
                            label: 'Enyaq',
                            legacy_id: 8395,
                            value: 'ENYAQ',
                        },
                        {
                            label: 'Enyaq Coupe RS',
                            legacy_id: 2000613,
                            value: 'ENYAQ_COUPE_RS',
                        },
                        {
                            label: 'Fabia',
                            legacy_id: 6747,
                            value: 'FABIA',
                        },
                        {
                            label: 'Favorit',
                            legacy_id: 1353,
                            value: 'FAVORIT',
                        },
                        {
                            label: 'Felicia',
                            legacy_id: 1354,
                            value: 'FELICIA',
                        },
                        {
                            label: 'Forman',
                            legacy_id: 2000334,
                            value: 'forman',
                        },
                        {
                            label: 'Kamiq',
                            legacy_id: 2000518,
                            value: 'KAMIQ',
                        },
                        {
                            label: 'Karoq',
                            legacy_id: 2000424,
                            value: 'Karoq',
                        },
                        {
                            label: 'Kodiaq',
                            legacy_id: 2000409,
                            value: 'KODIAQ',
                        },
                        {
                            label: 'Kodiaq Scout',
                            legacy_id: 8365,
                            value: 'KODIAQ_SCOUT',
                        },
                        {
                            label: 'Octavia',
                            legacy_id: 1355,
                            value: 'OCTAVIA',
                        },
                        {
                            label: 'Octavia RS',
                            legacy_id: 2000335,
                            value: 'octavia rs',
                        },
                        {
                            label: 'Octavia Scout',
                            legacy_id: 2000336,
                            value: 'octavia scout',
                        },
                        {
                            label: 'Pickup',
                            legacy_id: 2000337,
                            value: 'pickup',
                        },
                        {
                            label: 'Rapid',
                            legacy_id: 2000242,
                            value: 'rapid',
                        },
                        {
                            label: 'Rapid Spaceback',
                            legacy_id: 2000338,
                            value: 'rapid spaceback',
                        },
                        {
                            label: 'Roomster',
                            legacy_id: 1490,
                            value: 'roomster',
                        },
                        {
                            label: 'Scala',
                            legacy_id: 2000517,
                            value: 'Scala',
                        },
                        {
                            label: 'Superb',
                            legacy_id: 7532,
                            value: 'SUPERB',
                        },
                        {
                            label: 'Yeti',
                            legacy_id: 2000128,
                            value: 'yeti',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2117,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SKODA',
                },
                {
                    label: 'Smart',
                    legacy_id: 7137,
                    options: [
                        {
                            label: 'Crossblade',
                            legacy_id: 1482,
                            value: 'crossblade',
                        },
                        {
                            label: 'Forfour',
                            legacy_id: 8190,
                            value: 'FORFOUR',
                        },
                        {
                            label: 'Fortwo',
                            legacy_id: 1483,
                            value: 'fortwo',
                        },
                        {
                            label: 'Fortwo cabrio',
                            legacy_id: 8189,
                            value: 'fortwocabrio',
                        },
                        {
                            label: 'Fortwo coupe',
                            legacy_id: 1484,
                            value: 'fortwocoupe',
                        },
                        {
                            label: 'Roadster',
                            legacy_id: 7956,
                            value: 'roadster',
                        },
                        {
                            label: 'Roadster coupe',
                            legacy_id: 7957,
                            value: 'roadster coupe',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7144,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SMART',
                },
                {
                    label: 'Ssangyong',
                    legacy_id: 7190,
                    options: [
                        {
                            label: 'Actyon Sport',
                            legacy_id: 8302,
                            value: 'actyon sport',
                        },
                        {
                            label: 'Family',
                            legacy_id: 2000145,
                            value: 'family',
                        },
                        {
                            label: 'Korando',
                            legacy_id: 7510,
                            value: 'KORANDO',
                        },
                        {
                            label: 'Kyron',
                            legacy_id: 8301,
                            value: 'kyron',
                        },
                        {
                            label: 'Musso',
                            legacy_id: 7192,
                            value: 'MUSSO',
                        },
                        {
                            label: 'Rexton',
                            legacy_id: 7511,
                            value: 'REXTON',
                        },
                        {
                            label: 'Rexton Sports',
                            legacy_id: 2000558,
                            value: 'REXTON-SPORTS',
                        },
                        {
                            label: 'Rexton W',
                            legacy_id: 2000316,
                            value: 'rextonw',
                        },
                        {
                            label: 'Rodius',
                            legacy_id: 8173,
                            value: 'RODIUS',
                        },
                        {
                            label: 'Tivoli',
                            legacy_id: 2000343,
                            value: 'tivoli',
                        },
                        {
                            label: 'XLV',
                            legacy_id: 2000381,
                            value: 'XLV',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7193,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SSANGYONG',
                },
                {
                    label: 'Subaru',
                    legacy_id: 810,
                    options: [
                        {
                            label: 'B9 Tribeca',
                            legacy_id: 2000047,
                            value: 'tribeca',
                        },
                        {
                            label: 'BRZ',
                            legacy_id: 2000228,
                            value: 'brz',
                        },
                        {
                            label: 'Domingo',
                            legacy_id: 3067,
                            value: 'DOMINGO',
                        },
                        {
                            label: 'Forester',
                            legacy_id: 3655,
                            value: 'FORESTER',
                        },
                        {
                            label: 'Impreza',
                            legacy_id: 1368,
                            value: 'IMPREZA',
                        },
                        {
                            label: 'Justy',
                            legacy_id: 1369,
                            value: 'JUSTY',
                        },
                        {
                            label: 'L-serie',
                            legacy_id: 7676,
                            value: 'L-SERIE',
                        },
                        {
                            label: 'Legacy',
                            legacy_id: 1370,
                            value: 'LEGACY',
                        },
                        {
                            label: 'Levorg',
                            legacy_id: 2000344,
                            value: 'levorg',
                        },
                        {
                            label: 'Outback',
                            legacy_id: 7141,
                            value: 'OUTBACK',
                        },
                        {
                            label: 'Solterra',
                            legacy_id: 2000590,
                            value: 'SOLTERRA',
                        },
                        {
                            label: 'Trezia',
                            legacy_id: 2000196,
                            value: 'trezia',
                        },
                        {
                            label: 'XV',
                            legacy_id: 2000206,
                            value: 'xv',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2119,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SUBARU',
                },
                {
                    label: 'Suzuki',
                    legacy_id: 811,
                    options: [
                        {
                            label: 'Alto',
                            legacy_id: 1372,
                            value: 'ALTO',
                        },
                        {
                            label: 'Across',
                            legacy_id: 8375,
                            value: 'across',
                        },
                        {
                            label: 'Baleno',
                            legacy_id: 1373,
                            value: 'BALENO',
                        },
                        {
                            label: 'Grand Vitara',
                            legacy_id: 3757,
                            value: 'GRAND VITARA',
                        },
                        {
                            label: 'Ignis',
                            legacy_id: 6748,
                            value: 'IGNIS',
                        },
                        {
                            label: 'Jimny',
                            legacy_id: 7142,
                            value: 'jimny',
                        },
                        {
                            label: 'Kizashi',
                            legacy_id: 2000146,
                            value: 'kizashi',
                        },
                        {
                            label: 'Liana',
                            legacy_id: 7177,
                            value: 'LIANA',
                        },
                        {
                            label: 'SJ',
                            legacy_id: 1376,
                            value: 'SJ',
                        },
                        {
                            label: 'SX4',
                            legacy_id: 8269,
                            value: 'SX4',
                        },
                        {
                            label: 'SX4 S-Cross',
                            legacy_id: 2000284,
                            value: 'sxscross',
                        },
                        {
                            label: 'Splash',
                            legacy_id: 2000159,
                            value: 'splash',
                        },
                        {
                            label: 'Swift',
                            legacy_id: 1377,
                            value: 'SWIFT',
                        },
                        {
                            label: 'Vitara',
                            legacy_id: 1378,
                            value: 'VITARA',
                        },
                        {
                            label: 'Wagon R+',
                            legacy_id: 3826,
                            value: 'WAGON R+',
                        },
                        {
                            label: 'XL7',
                            legacy_id: 8081,
                            value: 'XL7',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2120,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'SUZUKI',
                },
                {
                    label: 'TVR',
                    legacy_id: 820,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 8295,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'TVR',
                },
                {
                    label: 'Tazzari',
                    legacy_id: 8080,
                    options: [
                        {
                            label: 'Zero',
                            legacy_id: 2000192,
                            value: 'zero',
                        },
                    ],
                    value: 'tazzari',
                },
                {
                    label: 'Tesla',
                    legacy_id: 8078,
                    options: [
                        {
                            label: 'Model 3',
                            legacy_id: 2000501,
                            value: 'MODEL3',
                        },
                        {
                            label: 'Model S',
                            legacy_id: 2000138,
                            value: 'model s',
                        },
                        {
                            label: 'Model X',
                            legacy_id: 2000379,
                            value: 'Model X',
                        },
                        {
                            label: 'Model Y',
                            legacy_id: 2000555,
                            value: 'MODEL_Y',
                        },
                        {
                            label: 'Roadster',
                            legacy_id: 2000137,
                            value: 'roadster',
                        },
                    ],
                    value: 'telsa',
                },
                {
                    label: 'Think',
                    legacy_id: 6734,
                    options: [
                        {
                            label: 'City',
                            legacy_id: 7139,
                            value: 'CITY',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 7140,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'THINK',
                },
                {
                    label: 'Toyota',
                    legacy_id: 813,
                    options: [
                        {
                            label: '4-Runner',
                            legacy_id: 1385,
                            value: '4-RUNNER',
                        },
                        {
                            label: 'Auris',
                            legacy_id: 2000062,
                            value: 'auris',
                        },
                        {
                            label: 'Avensis',
                            legacy_id: 3252,
                            value: 'AVENSIS',
                        },
                        {
                            label: 'Avensis Verso',
                            legacy_id: 7537,
                            value: 'AVENSIS VERSO',
                        },
                        {
                            label: 'Aygo',
                            legacy_id: 1480,
                            value: 'aygo',
                        },
                        {
                            label: 'Aygo X',
                            legacy_id: 2000581,
                            value: 'AYGO_X',
                        },
                        {
                            label: 'bZ4X',
                            legacy_id: 2000575,
                            value: 'BZ4X',
                        },
                        {
                            label: 'C-HR',
                            legacy_id: 2000401,
                            value: 'C-HR',
                        },
                        {
                            label: 'Camry',
                            legacy_id: 1386,
                            value: 'CAMRY',
                        },
                        {
                            label: 'Carina',
                            legacy_id: 1387,
                            value: 'CARINA',
                        },
                        {
                            label: 'Celica',
                            legacy_id: 1388,
                            value: 'CELICA',
                        },
                        {
                            label: 'Corolla',
                            legacy_id: 1389,
                            value: 'COROLLA',
                        },
                        {
                            label: 'Corolla Cross',
                            legacy_id: 2000600,
                            value: 'COROLLA_CROSS',
                        },
                        {
                            label: 'Corolla Verso',
                            legacy_id: 8110,
                            value: 'corolla verso',
                        },
                        {
                            label: 'Cressida',
                            legacy_id: 2000330,
                            value: 'cressida',
                        },
                        {
                            label: 'Crown',
                            legacy_id: 1392,
                            value: 'CROWN',
                        },
                        {
                            label: 'Dyna',
                            legacy_id: 1393,
                            value: 'DYNA',
                        },
                        {
                            label: 'GR Supra',
                            legacy_id: 8371,
                            value: 'GR_SUPRA',
                        },
                        {
                            label: 'GT86',
                            legacy_id: 2000229,
                            value: 'gt86',
                        },
                        {
                            label: 'HiAce',
                            legacy_id: 1394,
                            value: 'HIACE',
                        },
                        {
                            label: 'HiClass',
                            legacy_id: 1395,
                            value: 'HICLASS',
                        },
                        {
                            label: 'HiLux',
                            legacy_id: 1396,
                            value: 'HILUX',
                        },
                        {
                            label: 'Highlander',
                            legacy_id: 2000072,
                            value: 'highlander',
                        },
                        {
                            label: 'IQ',
                            legacy_id: 2000104,
                            value: 'iq',
                        },
                        {
                            label: 'Land Cruiser',
                            legacy_id: 1397,
                            value: 'LANDCRUISER',
                        },
                        {
                            label: 'Land Cruiser V8',
                            legacy_id: 2000240,
                            value: 'landcruiserv8',
                        },
                        {
                            label: 'MR2',
                            legacy_id: 1398,
                            value: 'MR2',
                        },
                        {
                            label: 'Mirai',
                            legacy_id: 2000442,
                            value: 'Mirai',
                        },
                        {
                            label: 'Picnic',
                            legacy_id: 3073,
                            value: 'PICNIC',
                        },
                        {
                            label: 'Previa',
                            legacy_id: 1399,
                            value: 'PREVIA',
                        },
                        {
                            label: 'Prius',
                            legacy_id: 6749,
                            value: 'PRIUS',
                        },
                        {
                            label: 'Prius Plug-in Hybrid',
                            legacy_id: 2000426,
                            value: 'Prius Plug-in Hybrid',
                        },
                        {
                            label: 'Prius+ Seven',
                            legacy_id: 2000227,
                            value: 'prius+seven',
                        },
                        {
                            label: 'Proace',
                            legacy_id: 2000267,
                            value: 'proace',
                        },
                        {
                            label: 'Proace City',
                            legacy_id: 8372,
                            value: 'Proace City',
                        },
                        {
                            label: 'Proace Verso',
                            legacy_id: 2000423,
                            value: 'Proace Verso',
                        },
                        {
                            label: 'RAV4',
                            legacy_id: 3074,
                            value: 'RAV4',
                        },
                        {
                            label: 'Sienna',
                            legacy_id: 2000425,
                            value: 'Sienna',
                        },
                        {
                            label: 'Starlet',
                            legacy_id: 1401,
                            value: 'STARLET',
                        },
                        {
                            label: 'Supra',
                            legacy_id: 1402,
                            value: 'SUPRA',
                        },
                        {
                            label: 'Tacoma',
                            legacy_id: 2000383,
                            value: 'Tacoma',
                        },
                        {
                            label: 'Tercel',
                            legacy_id: 1403,
                            value: 'TERCEL',
                        },
                        {
                            label: 'Tundra',
                            legacy_id: 8256,
                            value: 'tundra',
                        },
                        {
                            label: 'Urban Cruiser',
                            legacy_id: 2000118,
                            value: 'urban cruiser',
                        },
                        {
                            label: 'Verso',
                            legacy_id: 2000134,
                            value: 'verso',
                        },
                        {
                            label: 'Verso-S',
                            legacy_id: 2000198,
                            value: 'verso-s',
                        },
                        {
                            label: 'Yaris',
                            legacy_id: 3759,
                            value: 'YARIS',
                        },
                        {
                            label: 'Yaris Cross',
                            legacy_id: 2000601,
                            value: 'YARIS_CROSS',
                        },
                        {
                            label: 'Yaris Verso',
                            legacy_id: 8109,
                            value: 'yaris verso',
                        },
                        {
                            label: 'GR Yaris',
                            legacy_id: 2000534,
                            value: 'GR-YARIS',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2122,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'TOYOTA',
                },
                {
                    label: 'Triumph',
                    legacy_id: 814,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 8285,
                            value: 'andre',
                        },
                        {
                            label: 'Spitfire',
                            legacy_id: 8284,
                            value: 'SPITFIRE',
                        },
                        {
                            label: 'TR7',
                            legacy_id: 2000253,
                            value: 'tr7',
                        },
                    ],
                    value: 'TRIUMPH',
                },
                {
                    label: 'Volkswagen',
                    legacy_id: 817,
                    options: [
                        {
                            label: 'Amarok',
                            legacy_id: 2000170,
                            value: 'amarok',
                        },
                        {
                            label: 'Arteon',
                            legacy_id: 2000415,
                            value: 'ARTEON',
                        },
                        {
                            label: 'Beetle',
                            legacy_id: 3761,
                            value: 'BEETLE',
                        },
                        {
                            label: 'Boble (gml. type)',
                            legacy_id: 2781,
                            value: 'BOBLE (GML. TYPE)',
                        },
                        {
                            label: 'Bora',
                            legacy_id: 3762,
                            value: 'BORA',
                        },
                        {
                            label: 'CC',
                            legacy_id: 2000210,
                            value: 'cc',
                        },
                        {
                            label: 'Caddy',
                            legacy_id: 6709,
                            value: 'CADDY',
                        },
                        {
                            label: 'Caddy Alltrack',
                            legacy_id: 2000430,
                            value: 'Caddy Alltrack',
                        },
                        {
                            label: 'Caddy Maxi',
                            legacy_id: 2000231,
                            value: 'caddy_maxi',
                        },
                        {
                            label: 'Caravelle',
                            legacy_id: 1430,
                            value: 'CARAVELLE',
                        },
                        {
                            label: 'Corrado',
                            legacy_id: 1431,
                            value: 'CORRADO',
                        },
                        {
                            label: 'Crafter',
                            legacy_id: 2000048,
                            value: 'crafter',
                        },
                        {
                            label: 'Derby',
                            legacy_id: 1432,
                            value: 'DERBY',
                        },
                        {
                            label: 'Eos',
                            legacy_id: 8290,
                            value: 'eos',
                        },
                        {
                            label: 'Golf',
                            legacy_id: 1433,
                            value: 'GOLF',
                        },
                        {
                            label: 'Golf Alltrack',
                            legacy_id: 2000331,
                            value: 'golf alltrack',
                        },
                        {
                            label: 'Golf Cross',
                            legacy_id: 2000261,
                            value: 'golf_cross',
                        },
                        {
                            label: 'Golf Plus',
                            legacy_id: 2000235,
                            value: 'golf_plus',
                        },
                        {
                            label: 'Golf Sportsvan',
                            legacy_id: 2000297,
                            value: 'golfsportsvan',
                        },
                        {
                            label: 'ID. Buzz',
                            legacy_id: 8400,
                            value: 'id buzz',
                        },
                        {
                            label: 'ID.3',
                            legacy_id: 8376,
                            value: 'id3',
                        },
                        {
                            label: 'ID.4',
                            legacy_id: 8392,
                            value: 'id4',
                        },
                        {
                            label: 'ID.4 GTX',
                            legacy_id: 2000553,
                            value: 'ID.4_GTX',
                        },
                        {
                            label: 'ID.5',
                            legacy_id: 2000578,
                            value: 'ID5',
                        },
                        {
                            label: 'ID.5 GTX',
                            legacy_id: 2000579,
                            value: 'ID5_GTX',
                        },
                        {
                            label: 'Jetta',
                            legacy_id: 1434,
                            value: 'JETTA',
                        },
                        {
                            label: 'K 70',
                            legacy_id: 1435,
                            value: 'K 70',
                        },
                        {
                            label: 'LT',
                            legacy_id: 7650,
                            value: 'lt',
                        },
                        {
                            label: 'Lupo',
                            legacy_id: 3760,
                            value: 'LUPO',
                        },
                        {
                            label: 'Multivan',
                            legacy_id: 7952,
                            value: 'multivan',
                        },
                        {
                            label: 'Passat',
                            legacy_id: 1437,
                            value: 'PASSAT',
                        },
                        {
                            label: 'Passat Alltrack',
                            legacy_id: 2000209,
                            value: 'passatalltrack',
                        },
                        {
                            label: 'Passat CC',
                            legacy_id: 2000111,
                            value: 'passat cc',
                        },
                        {
                            label: 'Phaeton',
                            legacy_id: 7591,
                            value: 'PHAETON',
                        },
                        {
                            label: 'Polo',
                            legacy_id: 1438,
                            value: 'POLO',
                        },
                        {
                            label: 'Polo Cross',
                            legacy_id: 2000388,
                            value: 'Polo Cross',
                        },
                        {
                            label: 'Santana',
                            legacy_id: 1440,
                            value: 'SANTANA',
                        },
                        {
                            label: 'Scirocco',
                            legacy_id: 1441,
                            value: 'SCIROCCO',
                        },
                        {
                            label: 'Sharan',
                            legacy_id: 1442,
                            value: 'SHARAN',
                        },
                        {
                            label: 'T-Cross',
                            legacy_id: 2000504,
                            value: 'T-Cross',
                        },
                        {
                            label: 'T-Roc',
                            legacy_id: 2000431,
                            value: 'T-Roc',
                        },
                        {
                            label: 'Taro',
                            legacy_id: 2000269,
                            value: 'taro',
                        },
                        {
                            label: 'Tiguan',
                            legacy_id: 8329,
                            value: 'tiguan',
                        },
                        {
                            label: 'Tiguan Allspace',
                            legacy_id: 2000444,
                            value: 'Tiguan Allspace',
                        },
                        {
                            label: 'Touareg',
                            legacy_id: 7592,
                            value: 'TOUAREG',
                        },
                        {
                            label: 'Touran',
                            legacy_id: 7593,
                            value: 'TOURAN',
                        },
                        {
                            label: 'Transporter',
                            legacy_id: 1444,
                            value: 'TRANSPORTER',
                        },
                        {
                            label: 'UP!',
                            legacy_id: 2000208,
                            value: 'up',
                        },
                        {
                            label: 'Variant',
                            legacy_id: 1445,
                            value: 'VARIANT',
                        },
                        {
                            label: 'Vento',
                            legacy_id: 1446,
                            value: 'VENTO',
                        },
                        {
                            label: 'Kombi',
                            legacy_id: 8341,
                            value: 'Kombi',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2126,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'VOLKSWAGEN',
                },
                {
                    label: 'Volvo',
                    legacy_id: 818,
                    options: [
                        {
                            label: '744',
                            legacy_id: 3767,
                            value: '744',
                        },
                        {
                            label: 'C30',
                            legacy_id: 2000045,
                            value: 'c30',
                        },
                        {
                            label: 'C40',
                            legacy_id: 2000560,
                            value: 'C40',
                        },
                        {
                            label: 'C70',
                            legacy_id: 3763,
                            value: 'C70',
                        },
                        {
                            label: 'P1800',
                            legacy_id: 2000512,
                            value: 'P1800',
                        },
                        {
                            label: 'S40',
                            legacy_id: 2784,
                            value: 'S40',
                        },
                        {
                            label: 'S60',
                            legacy_id: 6718,
                            value: 'S60',
                        },
                        {
                            label: 'S60 Cross Country',
                            legacy_id: 2000347,
                            value: 's60 cross country',
                        },
                        {
                            label: 'S70',
                            legacy_id: 3083,
                            value: 'S70',
                        },
                        {
                            label: 'S80',
                            legacy_id: 3769,
                            value: 'S80',
                        },
                        {
                            label: 'S90',
                            legacy_id: 3768,
                            value: 'S90',
                        },
                        {
                            label: 'V40',
                            legacy_id: 2783,
                            value: 'V40',
                        },
                        {
                            label: 'V40 Cross Country',
                            legacy_id: 2000238,
                            value: 'v40_crosscountry',
                        },
                        {
                            label: 'V50',
                            legacy_id: 7849,
                            value: 'v50',
                        },
                        {
                            label: 'V60',
                            legacy_id: 2000172,
                            value: 'v60',
                        },
                        {
                            label: 'V60 Cross Country',
                            legacy_id: 2000346,
                            value: 'v60 cross country',
                        },
                        {
                            label: 'V70',
                            legacy_id: 3077,
                            value: 'V70',
                        },
                        {
                            label: 'V90',
                            legacy_id: 2000386,
                            value: 'V90',
                        },
                        {
                            label: 'V90 Cross Country',
                            legacy_id: 2000390,
                            value: 'V90 Cross Country',
                        },
                        {
                            label: 'X60',
                            legacy_id: 2000092,
                            value: 'x60',
                        },
                        {
                            label: 'XC40',
                            legacy_id: 2000437,
                            value: 'XC 40',
                        },
                        {
                            label: 'XC60',
                            legacy_id: 2000093,
                            value: 'XC60',
                        },
                        {
                            label: 'XC70',
                            legacy_id: 7781,
                            value: 'XC 70',
                        },
                        {
                            label: 'XC90',
                            legacy_id: 7651,
                            value: 'XC 90',
                        },
                        {
                            label: '142',
                            legacy_id: 1450,
                            value: '142',
                        },
                        {
                            label: '144',
                            legacy_id: 1451,
                            value: '144',
                        },
                        {
                            label: '145',
                            legacy_id: 1452,
                            value: '145',
                        },
                        {
                            label: '164',
                            legacy_id: 1453,
                            value: '164',
                        },
                        {
                            label: '240',
                            legacy_id: 1456,
                            value: '240',
                        },
                        {
                            label: '242',
                            legacy_id: 1457,
                            value: '242',
                        },
                        {
                            label: '244',
                            legacy_id: 1458,
                            value: '244',
                        },
                        {
                            label: '245',
                            legacy_id: 1459,
                            value: '245',
                        },
                        {
                            label: '264',
                            legacy_id: 1461,
                            value: '264',
                        },
                        {
                            label: '265',
                            legacy_id: 1462,
                            value: '265',
                        },
                        {
                            label: '340',
                            legacy_id: 1463,
                            value: '340',
                        },
                        {
                            label: '343',
                            legacy_id: 1464,
                            value: '343',
                        },
                        {
                            label: '345',
                            legacy_id: 1465,
                            value: '345',
                        },
                        {
                            label: '360',
                            legacy_id: 1466,
                            value: '360',
                        },
                        {
                            label: '440',
                            legacy_id: 1467,
                            value: '440',
                        },
                        {
                            label: '460',
                            legacy_id: 1468,
                            value: '460',
                        },
                        {
                            label: '480',
                            legacy_id: 1469,
                            value: '480',
                        },
                        {
                            label: '740',
                            legacy_id: 1472,
                            value: '740',
                        },
                        {
                            label: '745',
                            legacy_id: 2826,
                            value: '745',
                        },
                        {
                            label: '760',
                            legacy_id: 1473,
                            value: '760',
                        },
                        {
                            label: '780',
                            legacy_id: 1474,
                            value: '780',
                        },
                        {
                            label: '850',
                            legacy_id: 1475,
                            value: '850',
                        },
                        {
                            label: '940',
                            legacy_id: 1476,
                            value: '940',
                        },
                        {
                            label: '960',
                            legacy_id: 1477,
                            value: '960',
                        },
                        {
                            label: 'Amazon',
                            legacy_id: 2145,
                            value: 'AMAZON',
                        },
                        {
                            label: 'Duett',
                            legacy_id: 1478,
                            value: 'DUETT',
                        },
                        {
                            label: 'PV',
                            legacy_id: 1479,
                            value: 'PV',
                        },
                        {
                            label: 'Andre',
                            legacy_id: 2127,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'VOLVO',
                },
                {
                    label: 'VOYAH',
                    legacy_id: 8112,
                    options: [
                        {
                            label: 'FREE',
                            legacy_id: 2000587,
                            value: 'FREE',
                        },
                    ],
                    value: 'VOYAH',
                },
                {
                    label: 'Wiesmann',
                    legacy_id: 8086,
                    options: [
                        {
                            label: 'GT MF4',
                            legacy_id: 2000277,
                            value: 'gtmffire',
                        },
                        {
                            label: 'GT MF5',
                            legacy_id: 2000278,
                            value: 'gtmffem',
                        },
                        {
                            label: 'MF3',
                            legacy_id: 2000282,
                            value: 'mftre',
                        },
                        {
                            label: 'MF30',
                            legacy_id: 2000281,
                            value: 'mftrenull',
                        },
                        {
                            label: 'Roadster MF4',
                            legacy_id: 2000279,
                            value: 'roadstermffire',
                        },
                        {
                            label: 'Roadster MF5',
                            legacy_id: 2000280,
                            value: 'roadstermffem',
                        },
                    ],
                    value: 'wiesmann',
                },
                {
                    label: 'XPeng',
                    legacy_id: 8104,
                    options: [
                        {
                            label: 'G3',
                            legacy_id: 8378,
                            value: 'G3',
                        },
                        {
                            label: 'G3i',
                            legacy_id: 2000583,
                            value: 'G3I',
                        },
                        {
                            label: 'G9',
                            legacy_id: 2000573,
                            value: 'G9',
                        },
                        {
                            label: 'P5',
                            legacy_id: 2000572,
                            value: 'P5',
                        },
                        {
                            label: 'P7',
                            legacy_id: 8379,
                            value: 'P7',
                        },
                    ],
                    value: 'XPENG',
                },
                {
                    label: 'ZD',
                    legacy_id: 8094,
                    options: [
                        {
                            label: 'D1',
                            legacy_id: 2000445,
                            value: 'D1',
                        },
                    ],
                    value: 'ZD',
                },
                {
                    label: 'Andre',
                    legacy_id: 2252,
                    options: [
                        {
                            label: 'Andre',
                            legacy_id: 2260,
                            value: 'ANDRE',
                        },
                    ],
                    value: 'ANDRE',
                },
            ],
            type: 'taxonomy',
        },
        {
            label: 'Modellspesifikasjon',
            'legacy-name': 'CAR_MODEL/MODEL_SPECIFICATION',
            name: 'model_spec',
            type: 'string',
        },
        {
            label: 'Bilen står i',
            'legacy-name': 'CAR_LOCATION',
            name: 'car_location',
            options: [
                {
                    label: 'Norge',
                    legacy_id: 1,
                    value: 'NORWAY',
                },
                {
                    label: 'Utlandet',
                    legacy_id: 2,
                    value: 'ABROAD',
                },
            ],
            type: 'enum',
        },
        {
            fields: [
                {
                    label: 'Effekt i hk',
                    'legacy-name': 'ENGINE/EFFECT',
                    name: 'effect',
                    type: 'number',
                },
                {
                    label: 'Sylindervolum i liter',
                    'legacy-name': 'ENGINE/VOLUME',
                    name: 'volume',
                    type: 'decimal',
                    unit: 'LITRE',
                },
                {
                    label: 'Drivstoff',
                    'legacy-name': 'ENGINE/FUEL',
                    name: 'fuel',
                    options: [
                        {
                            label: 'Bensin',
                            legacy_id: 1,
                            value: 'GASOLINE',
                        },
                        {
                            label: 'Diesel',
                            legacy_id: 2,
                            value: 'DIESEL',
                        },
                        {
                            label: 'Gass',
                            legacy_id: 3,
                            value: 'LPG',
                        },
                        {
                            label: 'Elektrisitet',
                            legacy_id: 4,
                            value: 'ELECTRICAL',
                        },
                        {
                            label: 'Elektrisitet+bensin',
                            legacy_id: 6,
                            value: 'ELECTRICALANDGASOLINE',
                        },
                        {
                            label: 'Elektrisitet+diesel',
                            legacy_id: 8,
                            value: 'ELECTRICALANDDIESEL',
                        },
                        {
                            label: 'Gass+bensin',
                            legacy_id: 5,
                            value: 'LPGANDGASOLINE',
                        },
                        {
                            label: 'Gass+diesel',
                            legacy_id: 7,
                            value: 'LPGANDDIESEL',
                        },
                        {
                            label: 'Hydrogen',
                            legacy_id: 9,
                            value: 'HYDROGEN',
                        },
                    ],
                    type: 'enum',
                },
                {
                    default: false,
                    label: 'Er eller har bilens motor vært trimmet?',
                    'legacy-name': 'CAR_SELF_DECLARATION/TRIMMED',
                    name: 'tuned',
                    type: 'boolean',
                },
                {
                    label: 'Hvordan er motoren justert?',
                    'legacy-name': 'ENGINE_ADJUSTMENTS',
                    name: 'adjustments',
                    type: 'string',
                },
            ],
            label: 'Motor',
            name: 'engine',
            type: 'complex',
        },
        {
            label: 'CO2-utslipp i g/km',
            'legacy-name': 'CO2',
            name: 'co2_emissions',
            type: 'number',
            unit: 'GRAMS_PER_KILOMETER',
        },
        {
            label: 'Batterikapasitet i kWh',
            'legacy-name': 'BATTERY_CAPACITY',
            name: 'battery_capacity',
            type: 'number',
            unit: 'KILOWATT_HOUR',
        },
        {
            label: 'Rekkevidde i km',
            'legacy-name': 'DRIVING_RANGE',
            name: 'driving_range',
            type: 'number',
            unit: 'KILOMETER',
        },
        {
            label: 'Girkasse',
            'legacy-name': 'TRANSMISSION',
            name: 'transmission',
            options: [
                {
                    label: 'Automat',
                    legacy_id: 2,
                    value: 'AUTOMATIC',
                },
                {
                    label: 'Manuell',
                    legacy_id: 1,
                    value: 'MANUAL',
                },
            ],
            type: 'enum',
        },
        {
            label: 'Girkassebetegnelse',
            'legacy-name': 'TRANSMISSION_SPECIFICATION',
            name: 'transmission_spec',
            type: 'string',
        },
        {
            label: 'Hjuldrift',
            'legacy-name': 'WHEEL_DRIVE',
            name: 'wheel_drive',
            options: [
                {
                    label: 'Forhjulsdrift',
                    legacy_id: 3,
                    value: 'FRONTWHEELEDRIVE',
                },
                {
                    label: 'Bakhjulsdrift',
                    legacy_id: 1,
                    value: 'REARWHEELEDRIVE',
                },
                {
                    label: 'Firehjulsdrift',
                    legacy_id: 2,
                    value: 'FOURWHEELEDRIVE',
                },
            ],
            type: 'enum',
        },
        {
            label: 'Hjuldriftbetegnelse',
            'legacy-name': 'WHEEL_DRIVE_SPECIFICATION',
            name: 'wheel_drive_spec',
            type: 'string',
        },
        {
            label: 'Karosseritype',
            'legacy-name': 'BODY_TYPE',
            name: 'body_type',
            options: [
                {
                    label: 'Cabriolet',
                    legacy_id: 7,
                    value: 'CABRIOLET',
                },
                {
                    label: 'Coupe',
                    legacy_id: 6,
                    value: 'COUPE',
                },
                {
                    label: 'Flerbruksbil',
                    legacy_id: 5,
                    value: 'MPV',
                },
                {
                    label: 'Kasse',
                    legacy_id: 10,
                    value: 'VAN',
                },
                {
                    label: 'Kombi 3-dørs',
                    legacy_id: 1,
                    value: 'COMBI-3DOOR',
                },
                {
                    label: 'Kombi 5-dørs',
                    legacy_id: 2,
                    value: 'COMBI-5DOOR',
                },
                {
                    label: 'Pickup',
                    legacy_id: 8,
                    value: 'PICKUP',
                },
                {
                    label: 'SUV/Offroad',
                    legacy_id: 9,
                    value: 'OFFROAD',
                },
                {
                    label: 'Sedan',
                    legacy_id: 3,
                    value: 'SEDAN',
                },
                {
                    label: 'Stasjonsvogn',
                    legacy_id: 4,
                    value: 'STATIONWAGON',
                },
                {
                    label: 'Annet',
                    legacy_id: 11,
                    value: 'OTHER',
                },
            ],
            type: 'enum',
        },
        {
            label: 'Avgiftsklasse',
            'legacy-name': 'REGISTRATION_CLASS',
            name: 'registration_class',
            options: [
                {
                    label: 'Kombinertbil',
                    legacy_id: 3,
                    value: 'COMBINED-CAR',
                },
                {
                    label: 'Lett lastebil',
                    legacy_id: 5,
                    value: 'LIGHT-LORRY',
                },
                {
                    label: 'Minibuss',
                    legacy_id: 4,
                    value: 'MINIBUS',
                },
                {
                    label: 'Personbil',
                    legacy_id: 1,
                    value: 'PASSENGER-CAR',
                },
                {
                    label: 'Varebil',
                    legacy_id: 2,
                    value: 'DELIVERY-TRUCK',
                },
                {
                    label: 'Annet',
                    legacy_id: 6,
                    value: 'OTHER',
                },
            ],
            type: 'enum',
        },
        {
            label: 'Antall seter',
            'legacy-name': 'NO_OF_SEATS',
            name: 'no_of_seats',
            type: 'number',
        },
        {
            label: 'Antall dører',
            'legacy-name': 'NO_OF_DOORS',
            name: 'no_of_doors',
            type: 'number',
        },
        {
            label: 'Bagasjeromsvolum i liter',
            'legacy-name': 'SIZE_OF_BOOT',
            name: 'boot_size',
            type: 'number',
        },
        {
            label: 'Egenvekt i kg',
            'legacy-name': 'WEIGHT',
            name: 'weight',
            type: 'number',
        },
        {
            label: 'Maks tilhengervekt i kg',
            'legacy-name': 'MAX_TRAILER_WEIGHT',
            name: 'max_trailer_weight',
            type: 'number',
            unit: 'KILOGRAM',
        },
        {
            label: 'Hovedfarge',
            'legacy-name': 'EXTERIOR_COLOUR_MAIN',
            name: 'exterior_color',
            options: [
                {
                    label: 'Beige',
                    legacy_id: 1,
                    value: 'BEIGE',
                },
                {
                    label: 'Blå',
                    legacy_id: 2,
                    value: 'BLUE',
                },
                {
                    label: 'Bronse',
                    legacy_id: 3,
                    value: 'BRONZE',
                },
                {
                    label: 'Brun',
                    legacy_id: 4,
                    value: 'BROWN',
                },
                {
                    label: 'Grønn',
                    legacy_id: 5,
                    value: 'GREEN',
                },
                {
                    label: 'Grå',
                    legacy_id: 6,
                    value: 'GREY',
                },
                {
                    label: 'Gul',
                    legacy_id: 7,
                    value: 'YELLOW',
                },
                {
                    label: 'Gull',
                    legacy_id: 8,
                    value: 'GOLD',
                },
                {
                    label: 'Hvit',
                    legacy_id: 9,
                    value: 'WHITE',
                },
                {
                    label: 'Lilla',
                    legacy_id: 10,
                    value: 'LILAC',
                },
                {
                    label: 'Oransje',
                    legacy_id: 11,
                    value: 'ORANGE',
                },
                {
                    label: 'Rosa',
                    legacy_id: 12,
                    value: 'PINK',
                },
                {
                    label: 'Rød',
                    legacy_id: 13,
                    value: 'RED',
                },
                {
                    label: 'Svart',
                    legacy_id: 14,
                    value: 'BLACK',
                },
                {
                    label: 'Sølv',
                    legacy_id: 15,
                    value: 'SILVER',
                },
                {
                    label: 'Turkis',
                    legacy_id: 16,
                    value: 'TURQUOISE',
                },
            ],
            type: 'enum',
        },
        {
            label: 'Fargebeskrivelse',
            'legacy-name': 'EXTERIOR_COLOUR',
            name: 'exterior_color_description',
            type: 'string',
        },
        {
            label: 'Interiørfarge',
            'legacy-name': 'INTERIOR_COLOUR',
            name: 'interior_color',
            type: 'string',
        },
        {
            label: 'Utstyr',
            'legacy-name': 'EQUIPMENT',
            list: true,
            name: 'equipment_tmp',
            type: 'string',
        },
        {
            label: 'Kilometerstand',
            'legacy-name': 'MILEAGE',
            name: 'mileage',
            type: 'number',
        },
        {
            label: 'Førstegangsregistrert',
            'legacy-name': 'REGISTRATION_FIRST',
            name: 'first_registration',
            type: 'date',
        },
        {
            fields: [
                {
                    label: 'Sist EU-godkjent',
                    'legacy-name': 'EU_CHECK_LAST',
                    name: 'last',
                    type: 'date',
                },
                {
                    label: 'Neste frist for EU-kontroll',
                    'legacy-name': 'EU_CHECK_NEXT',
                    name: 'next',
                    type: 'date',
                },
            ],
            label: 'EU-kontroll',
            name: 'eu_check',
            type: 'complex',
        },
        {
            label: 'Antall eiere',
            'legacy-name': 'NO_OF_OWNERS',
            name: 'owners',
            type: 'number',
        },
        {
            fields: [
                {
                    label: 'Garantitype',
                    'legacy-name': 'WARRANTY',
                    name: 'type',
                    type: 'string',
                },
                {
                    label: 'Garantiens varighet (måneder)',
                    'legacy-name': 'WARRANTY_DURATION',
                    name: 'duration',
                    type: 'number',
                },
                {
                    label: 'Garantiens varighet (kilometer)',
                    'legacy-name': 'WARRANTY_DISTANCE',
                    name: 'distance',
                    type: 'number',
                    unit: 'KILOMETER',
                },
            ],
            label: 'Garanti',
            name: 'warranty',
            type: 'complex',
        },
        {
            fields: [
                {
                    label: 'Leverandør av tilstandsrapport',
                    'legacy-name': 'CONDITION_REPORT/PROVIDER',
                    name: 'provider',
                    options: [
                        {
                            label: 'NAF',
                            legacy_id: 1,
                            value: 'NAF',
                        },
                        {
                            label: 'SHAKE',
                            legacy_id: 2,
                            value: 'SHAKE',
                        },
                        {
                            label: 'BMW',
                            legacy_id: 3,
                            value: 'BMW',
                        },
                        {
                            label: 'Viking',
                            legacy_id: 4,
                            value: 'VIKING',
                        },
                    ],
                    type: 'enum',
                },
                {
                    label: 'Rapportlenke',
                    name: 'link',
                    synthetic: true,
                    type: 'url',
                },
            ],
            label: 'Tilstandsrapport',
            name: 'condition_report',
            type: 'complex',
        },
        {
            default: false,
            label: 'Fulgt bilens vedlikeholdsprogram',
            'legacy-name': 'SERVICE_PLAN_FOLLOWED',
            name: 'service_plan_followed',
            type: 'boolean',
        },
        {
            default: false,
            label: 'Foreligger servicehistorikk?',
            'legacy-name': 'CAR_SERVICE_HISTORY',
            name: 'service_history',
            type: 'boolean',
        },
        {
            fields: [
                {
                    default: false,
                    label: 'Har bilen kjente feil, mangler eller synlige skader?',
                    'legacy-name': 'HAS_KNOWN_FAULTS',
                    name: 'has_known_damages',
                    type: 'boolean',
                },
                {
                    label: 'Hva er feil med bilen?',
                    'legacy-name': 'KNOWN_FAULTS_DETAILS',
                    name: 'damages_details',
                    type: 'string',
                },
            ],
            label: 'Feil',
            name: 'damages',
            type: 'complex',
        },
        {
            fields: [
                {
                    default: false,
                    label: 'Er det gjort større reparasjoner?',
                    'legacy-name': 'HAS_HAD_REPAIRS',
                    name: 'has_undergone_repairs',
                    type: 'boolean',
                },
                {
                    label: 'Hva er reparert?',
                    'legacy-name': 'REPAIRS_DETAILS',
                    name: 'repairs_details',
                    type: 'string',
                },
            ],
            label: 'Reparasjoner',
            name: 'repairs',
            type: 'complex',
        },
        {
            fields: [
                {
                    doc: 'A relative uri. The base address should be to the FINN cdn server location followed by a image size path.',
                    label: 'Referanse',
                    name: 'uri',
                    type: 'string',
                },
                {
                    label: 'Bildetekst',
                    name: 'description',
                    type: 'string',
                },
                {
                    label: 'Bredde',
                    name: 'width',
                    type: 'number',
                },
                {
                    label: 'Høyde',
                    name: 'height',
                    type: 'number',
                },
            ],
            label: 'Bilder',
            list: true,
            name: 'images',
            type: 'complex',
        },
        {
            label: 'Video',
            'legacy-name': 'VIDEO_URL',
            name: 'video_url',
            type: 'url',
        },
        {
            fields: [
                {
                    label: 'Salgspris i kr - eks. omregistrering',
                    name: 'main',
                    type: 'number',
                },
                {
                    default: false,
                    label: 'Har bilen heftelser/gjeld?',
                    'legacy-name': 'HAS_ENCUMBRANCES',
                    name: 'has_liabilities',
                    type: 'boolean',
                },
                {
                    label: 'Hvilke heftelser/gjeld følger bilen?',
                    'legacy-name': 'ENCUMBRANCES_DETAILS',
                    name: 'liabilities_details',
                    type: 'string',
                },
                {
                    label: 'Salgspris i kr - inkl. evt. omregistrering',
                    name: 'total',
                    synthetic: true,
                    type: 'number',
                },
                {
                    label: 'Omregistreringsavgift i kr',
                    'legacy-name': 'MOTOR_PRICE/REGISTRATION',
                    name: 'registration_price',
                    type: 'number',
                },
                {
                    default: false,
                    label: 'Fritak for omregistreringsavgift',
                    'legacy-name': 'MOTOR_PRICE/REREGISTRATION_EXEMPTION',
                    name: 'registration_tax_exemption',
                    type: 'boolean',
                },
            ],
            label: 'Pris',
            name: 'price',
            type: 'complex',
        },
        {
            fields: [
                {
                    label: 'Gateadresse',
                    name: 'street',
                    type: 'string',
                },
                {
                    label: 'Postnummer',
                    name: 'postalcode',
                    type: 'string',
                },
                {
                    label: 'Land',
                    name: 'country',
                    type: 'string',
                },
            ],
            label: 'Adresse',
            name: 'location',
            type: 'complex',
        },
        {
            label: 'Tittel',
            name: 'title',
            synthetic: true,
            type: 'string',
        },
        {
            doc: "The main text describing the item. May contain HTML. With a temporary name, since we don't know exactly how this should work.",
            label: 'Beskrivelse',
            name: 'description_tmp',
            type: 'html',
        },
        {
            default: false,
            doc: 'true: The profile should be hidden.\nfalse: The profile should be shown.\nEmpty: The profile should be shown.',
            label: 'Ikke vis profilbilde og lenke til profilsiden før kjøperen tar kontakt med meg',
            'legacy-name': 'ANONYMOUS',
            name: 'anonymous',
            type: 'boolean',
        },
        {
            doc: 'This is a transition-field that will be removed once the owner of the data has taken ownership and implemented mechanisms handling this data outside sybase. Consider the field deprecated.',
            fields: [
                {
                    label: 'Dato',
                    name: 'occurred',
                    type: 'string',
                },
                {
                    label: 'Pris',
                    name: 'price',
                    type: 'number',
                },
            ],
            label: 'Salgsinformasjon',
            name: 'sale_tmp',
            type: 'complex',
        },
        {
            doc: 'Matches the Ad-table column of the same name. It is the same as OrderNo, an identifier that together with a partnerId uniquely identifies an Ad to an external system. Used by partners to update (or stop etc) an ad.',
            label: 'Ekstern ID',
            name: 'external_adid',
            type: 'string',
        },
        {
            doc: 'Matches the Ad-table column of the same name. Has the label "Din referanse" in Admin. Used for billing.',
            label: 'Annonsørreferanse',
            name: 'advertiser_ref',
            type: 'string',
        },
        {
            doc: 'Deprecated, use advertiser_ref instead',
            label: 'Annonsørreferanse',
            name: 'external_reference',
            type: 'string',
        },
        {
            fields: [
                {
                    label: 'Programnavn',
                    'legacy-name': 'CAR_PREMIUMBRAND',
                    name: 'name',
                    type: 'string',
                },
                {
                    label: 'Lenke',
                    'legacy-name': 'CAR_PREMIUMBRAND_LINK',
                    name: 'url',
                    type: 'url',
                },
            ],
            label: 'Programbil',
            name: 'premium',
            type: 'complex',
        },
        {
            doc: 'The OFV variant Id initially selected by the creator of the ad.\nThis may diverge from the actual properties of the vehicle, and should not be trusted.\n',
            name: 'ofv_variant_id_tmp',
            type: 'string',
        },
        {
            default: false,
            doc: 'true: The item has been marked by owner as sold. false: The item was previously marked as sold. Empty: No information available about sold status.',
            label: 'Solgt',
            name: 'disposed',
            type: 'boolean',
        },
    ],
};
