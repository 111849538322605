import React, { useEffect } from 'react';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import trackEvent from '../../../lib/tracking';

const CustomTooltip = ({
    children,
    payload,
    graphId,
}: {
    children: React.ReactNode;
    payload: Payload<string, number>[];
    graphId: string;
}) => {
    useEffect(() => {
        // ISP_ev30
        trackEvent('Show', 'Click', 'Chart', `${graphId} graph interaction`);
    }, [graphId]);
    return (
        <div className="p-8 text-white" style={{ backgroundColor: '#3F3F46', borderRadius: 8 }}>
            <p className="text-12 font-bold">{`Q${payload[0].payload.quarter} ${payload[0].payload.year}`}</p>
            {children}
        </div>
    );
};

export default CustomTooltip;
