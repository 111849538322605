import React from 'react';
import { searchKeysMappingSchema } from './fetchers';
import { ActiveAds, ActiveAdsData, ActiveAdsGraphPoint, VtOptions, VtSchema } from './types';
import { defaultVtSchema } from '../schema/schema';

export function calculatePercentShare(num1: number, num2: number) {
    const total = num1 + num2;
    const percent1 = (num1 / total) * 100;
    const percent2 = (num2 / total) * 100;
    return {
        carsInPercent: percent1.toFixed(),
        carsOutPercent: percent2.toFixed(),
    };
}

export const getDotSize = (standingTime: number | null) => {
    if (standingTime !== null) {
        if (standingTime >= 0 && standingTime <= 29) {
            // need standingTime!=null because nulls resolve to 0
            return 'xlarge';
        }
        if (standingTime >= 30 && standingTime <= 59) {
            return 'large';
        }
        if (standingTime >= 60 && standingTime <= 89) {
            return 'medium';
        }
        if (standingTime >= 90) {
            return 'small';
        }
    }
    return 'xsmall';
};

export const getCountyNameFromCode = (code: string) =>
    new Map<string, string>([
        ['22042', 'Agder'],
        ['22034', 'Innlandet'],
        ['20015', 'Møre og Romsdal'],
        ['20018', 'Nordland'],
        ['20061', 'Oslo'],
        ['20012', 'Rogaland'],
        ['22054', 'Troms og Finnmark'],
        ['20016', 'Trøndelag'],
        ['22038', 'Vestfold og Telemark'],
        ['22046', 'Vestland'],
        ['22030', 'Viken'],
    ]).get(code);

export const getEquipmentNameFromCode = (code: string) =>
    new Map<string, string>([
        ['2', 'Hengerfeste'],
        ['35', 'Motorvarmer'],
        ['40', 'Navigasjonssystem'],
        ['77', 'Radio'],
        ['12', 'Skinninteriør'],
    ]).get(code);

export const getFuelTypeFromCode = (code: string) =>
    new Map<string, string>([
        ['1', 'gasoline'],
        ['2', 'diesel'],
        ['3', 'natural-gas'],
        ['4', 'electricity'],
        ['5', 'hybrid'],
        ['6', 'hybrid'],
        ['7', 'hybrid'],
        ['8', 'hybrid'],
        ['9', 'other'],
    ]).get(code);

export const getDealerType = (code: string) =>
    new Map<string, string>([
        ['1', 'certified'],
        ['2', 'pro'],
        ['3', 'private'],
    ]).get(code);

function findModelInSchema(makes: VtOptions[], code: number): [number, number] | [null, null] {
    let modelArrayIndices: [number, number] | [null, null] = [null, null];
    makes.forEach((make, makeIndex) => {
        if (make.options != null) {
            make.options.forEach((model, modelIndex) => {
                if (model.legacy_id === code) {
                    modelArrayIndices = [makeIndex, modelIndex];
                }
            });
        }
    });
    return modelArrayIndices;
}

export function getCarModelFromCode(code: number, vtSchema: VtSchema): [string, string] | undefined {
    const makes = vtSchema.fields.find((field) => field.name === 'make_and_model')?.options;

    let translatedFields: [string, string] | undefined;

    if (makes) {
        const makeModelIndices = findModelInSchema(makes, code);
        const firstIndex = makeModelIndices[0];
        const secondIndex = makeModelIndices[1];

        if (firstIndex !== null && secondIndex !== null) {
            const makeOptions = makes[firstIndex].options;
            if (makeOptions) {
                translatedFields = [
                    makes[firstIndex].label.toLowerCase(),
                    makeOptions[secondIndex].label.toLowerCase(),
                ];
            }
        }
    }

    return translatedFields;
}

export const getNameFromCode = (code: number, fieldName: string, vtSchema: VtSchema) => {
    const options = vtSchema.fields.find((field) => field.name === fieldName)?.options;
    let label = '';
    if (options) {
        const vtOption = options.find((option) => option.legacy_id === code);
        if (vtOption) {
            label = vtOption.label;
        }
    }
    return label;
};

function convertColorParam(vtSchema: VtSchema, code: string) {
    const codeValue = Number(code);
    const fieldName = 'exterior_color';
    const options = vtSchema.fields.find((field) => field.name === fieldName)?.options;
    if (options !== undefined) {
        const color = options.find((option) => option.legacy_id === codeValue)?.value;
        return color?.toLowerCase();
    }
    return '';
}

const wheelDrive = new Map<string, string>([
    ['Bakhjulsdrift', 'rwd'],
    ['Firehjulsdrift', '4wd'],
    ['Forhjulsdrift', 'fwd'],
]);

const transmission = new Map<string, string>([
    ['Automat', 'automatic'],
    ['Manuell', 'manual'],
]);

const chassis = new Map<string, string>([
    ['Cabriolet', 'convertible'],
    ['Kasse', 'van'],
    ['SUV/Offroad', 'suv'],
    ['Stasjonsvogn', 'stationwagon'],
    ['Coupe', 'coupe'],
    ['Flerbruksbil', 'mpv'],
    ['Pickup', 'pickup'],
    ['Annet', 'other'],
    ['Sedan', 'sedan'],
    ['Kombi 3-dørs', 'hatchback'],
    ['Kombi 5-dørs', 'hatchback'],
]);

export async function translateUrl(params: URLSearchParams | undefined) {
    if (params === undefined) {
        return undefined;
    }

    let vtSchema: VtSchema = defaultVtSchema;
    await searchKeysMappingSchema()
        .then((result) => {
            vtSchema = result;
        })
        .catch(() => {
            vtSchema = defaultVtSchema;
        });

    const newParams = new URLSearchParams();

    params.forEach((value, key) => {
        let newValue = value;
        let newKey = key;
        if (newKey === 'make') {
            newValue = getNameFromCode(Number(value.split('.')[1]), 'make_and_model', vtSchema);
            newKey = 'manufacturer';
        } else if (newKey === 'model') {
            const modelNum = Number(value.split('.')[2]);
            const carModel = getCarModelFromCode(modelNum, vtSchema);

            if (carModel) {
                const [manufacturer, model] = carModel;
                if (newParams.has('manufacturer')) {
                    const oldValue = newParams.get('manufacturer');
                    newParams.set('manufacturer', `${oldValue},${manufacturer.toLowerCase()}`);
                } else {
                    newParams.append('manufacturer', manufacturer.toLowerCase());
                }
                if (model === 'ID.4 GTX||ID.5 GTX||Boble (gml. type)') {
                    newValue = model;
                } else {
                    newValue = model.replace('.', '');
                }
            }
        } else if (newKey === 'dealer_segment') {
            newValue = getDealerType(value) ?? '';
        } else if (newKey === 'fuel') {
            newValue = getFuelTypeFromCode(value) ?? '';
        } else if (newKey === 'location') {
            newValue = getCountyNameFromCode(value) ?? '';
        } else if (newKey === 'car_equipment') {
            newValue = getEquipmentNameFromCode(value) ?? '';
        } else if (newKey === 'exterior_colour') {
            newValue = convertColorParam(vtSchema, value) ?? '';
        } else if (newKey === 'wheel_drive') {
            const name = getNameFromCode(Number(value), newKey, vtSchema);
            newValue = wheelDrive.get(name) ?? '';
        } else if (newKey === 'transmission') {
            const name = getNameFromCode(Number(value), newKey, vtSchema);
            newValue = transmission.get(name) ?? '';
        } else if (newKey === 'body_type') {
            const name = getNameFromCode(Number(value), newKey, vtSchema);
            newValue = chassis.get(name) ?? '';
        }

        if (newParams.has(newKey)) {
            const oldValue = newParams.get(newKey);
            newParams.set(newKey, `${oldValue},${newValue.toLowerCase()}`);
        } else {
            newParams.append(newKey, newValue.toLowerCase());
        }
    });

    newParams.delete('sort');
    return newParams;
}

export function isProMarketShown(): boolean {
    const dealerSegments = new URLSearchParams(window.location.search).getAll('dealer_segment');
    return dealerSegments.includes('1') || dealerSegments.includes('2') || dealerSegments.length === 0;
}

export function isPrivateMarketShown(): boolean {
    const dealerSegments = new URLSearchParams(window.location.search).getAll('dealer_segment');
    return dealerSegments.includes('3') || dealerSegments.length === 0;
}

function allEntriesAreZero(activeAdsData: ActiveAds | undefined): boolean {
    if (activeAdsData !== undefined) {
        const privateEntries = activeAdsData.activeAdsPrivate;
        const proEntries = activeAdsData.activeAdsPro;
        const privateEntriesAreZero = privateEntries.every((entry) => entry.activeAds === 0);
        const proEntriesAreZero = proEntries.every((entry) => entry.activeAds === 0);
        return privateEntriesAreZero && proEntriesAreZero;
    }
    return true;
}

export function activeAdsIsValid(activeAdsData: ActiveAds | undefined): boolean {
    return !(
        (
            !activeAdsData ||
            !activeAdsData.activeAdsPrivate ||
            !activeAdsData.activeAdsPro ||
            activeAdsData.activeAdsPrivate.length !== 5 || // Must have 5 elements for the graph to render.
            activeAdsData.activeAdsPro.length !== 5 ||
            allEntriesAreZero(activeAdsData)
        ) // Must have at least one non-zero entry for the graph to render.
    );
}

export function numberWithSpaces(x: number): string {
    if (!x) {
        return '0';
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !('nodeType' in e)) {
        throw new Error(`Node expected`);
    }
}

export function trackFilterChange(setParams: React.Dispatch<React.SetStateAction<URLSearchParams | undefined>>) {
    let previousUrl = '';
    const config = { subtree: true, childList: true };

    const observer = new MutationObserver(async () => {
        if (previousUrl !== window.location.search) {
            previousUrl = window.location.search;
            const params = new URLSearchParams(window.location.search);

            const newFilters = await translateUrl(params).catch(() => undefined);

            setParams(newFilters);
        }
    });

    observer.observe(document, config);
}

export const mapActiveAdsToGraphData = (
    privateAdsData: ActiveAdsData[],
    proAdsData: ActiveAdsData[],
    includePrivate: boolean,
    includePro: boolean,
) => {
    const graphData: ActiveAdsGraphPoint[] = [];
    const NR_OF_QUARTERS = 5;

    if (
        (includePrivate && privateAdsData.length !== NR_OF_QUARTERS) ||
        (includePrivate && proAdsData.length !== NR_OF_QUARTERS)
    ) {
        return graphData;
    }

    for (let i = 0; i < NR_OF_QUARTERS; i += 1) {
        const graphPoint: ActiveAdsGraphPoint = {
            year: includePro ? proAdsData[i].year : privateAdsData[i].year,
            quarter: includePro ? proAdsData[i].quarter : privateAdsData[i].quarter,
        };
        if (includePrivate) {
            graphPoint.private = privateAdsData[i].activeAds;
        }

        if (includePro) {
            graphPoint.forhandlere = proAdsData[i].activeAds;
        }

        graphData.push(graphPoint);
    }

    return graphData;
};
