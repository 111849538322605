import React from 'react';
import { TooltipProps } from 'recharts';
import CustomTooltip from './CustomTooltip';

const PublishTimeTooltip = ({ payload, active }: TooltipProps<string, number>) => (
    <>
        {active && payload && (
            <CustomTooltip payload={payload} graphId="publish-time">
                <p className="text-12 m-0">Publiseringstid</p>
                <p className="text-16 font-bold">{payload[0].payload.publishTime} dager</p>
                <p className="text-12">{payload[0].payload.carAmount} biler</p>
            </CustomTooltip>
        )}
    </>
);

export default PublishTimeTooltip;
