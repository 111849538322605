import React from 'react';

const PaintingGraphs = () => (
    <svg width="128" height="92" viewBox="0 0 128 92" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-0">
        <g opacity="0.5">
            <path d="M0.882324 90.8086H127.118" stroke="#C3CCD9" strokeDasharray="2 2" />
            <path d="M0.882324 90.8086H127.118" stroke="#C3CCD9" strokeDasharray="2 2" />
            <path d="M0.882324 90.8086H127.118" stroke="#4D586F" />
            <path d="M0.882324 66.874H127.118" stroke="#C3CCD9" strokeLinecap="round" strokeDasharray="2 2" />
            <path d="M0.882324 78.8418H127.118" stroke="#C3CCD9" strokeLinecap="round" strokeDasharray="2 2" />
            <path
                d="M109.668 57.2354H101.5C99.2913 57.2354 97.5005 59.0262 97.5005 61.2354V90.8095H113.668V61.2354C113.668 59.0262 111.877 57.2354 109.668 57.2354Z"
                fill="#4D586F"
            />
            <path
                d="M109.668 54.6699H101.5C99.2913 54.6699 97.5005 56.4608 97.5005 58.6699V90.5716H113.668V58.6699C113.668 56.4608 111.877 54.6699 109.668 54.6699Z"
                fill="#4D586F"
            />
            <path
                d="M90.5153 66.5088H82.3481C80.139 66.5088 78.3481 68.2997 78.3481 70.5088V90.8095H94.5153V70.5088C94.5153 68.2997 92.7244 66.5088 90.5153 66.5088Z"
                fill="#F8FAFC"
                stroke="#4D586F"
                strokeLinecap="round"
            />
            <path
                d="M71.3683 78.6582H63.2012C60.992 78.6582 59.2012 80.4491 59.2012 82.6582V90.8085H75.3683V82.6582C75.3683 80.4491 73.5775 78.6582 71.3683 78.6582Z"
                fill="#F8FAFC"
                stroke="#4D586F"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2522 78.9791C15.2522 81.912 14.9179 84.5948 14.9179 84.5948C14.9179 85.2736 16.9431 87.3646 22.142 87.3646C27.341 87.3646 29.3625 85.4677 29.3625 84.5948C29.3625 84.5948 29.1315 81.7236 29.1315 78.9791C29.1315 76.5477 29.7575 74.1863 29.8777 73.7552C29.8833 73.7376 29.8883 73.7199 29.8927 73.7021C29.8959 73.6911 29.8975 73.6854 29.8975 73.6854C29.9088 73.6307 29.915 73.5755 29.915 73.5198C29.915 72.2037 26.4353 71.1367 22.1431 71.1367C17.8509 71.1367 14.373 72.2037 14.373 73.5198C14.373 73.6251 14.3952 73.7288 14.4383 73.8305C14.6219 74.436 15.2522 76.6813 15.2522 78.9791Z"
                fill="#C3CCD9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1427 71.6748C18.6042 71.6748 15.7361 72.4874 15.7361 73.4893C15.7361 74.4912 18.6042 75.303 22.1427 75.303L22.1479 75.3026V79.8581C22.1479 80.1193 22.3798 80.33 22.6677 80.33C22.9565 80.33 23.1875 80.1193 23.1875 79.8581L23.1875 75.279C23.4505 75.2668 23.7081 75.2501 23.9593 75.2291L23.9593 82.5224C23.9593 82.7836 24.1942 82.9943 24.482 82.9943C24.7689 82.9943 25.0037 82.7836 25.0037 82.5224L25.0039 75.1125C27.1051 74.8151 28.5464 74.2 28.5464 73.4893C28.5464 72.4874 25.6821 71.6748 22.1427 71.6748Z"
                fill="#4D586F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.0269 73.2494C30.0269 73.2494 30.4606 63.5098 22.1602 63.5098C13.9044 63.5098 14.3428 73.2494 14.3428 73.2494C14.3428 73.2494 15.5131 64.4526 22.1602 64.4526C28.92 64.4526 30.0269 73.2494 30.0269 73.2494Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M91.4264 36.999C91.4264 36.999 90.7366 38.9486 89.7639 40.7649L89.6482 40.9779C89.5702 41.1192 89.4906 41.2593 89.4095 41.397C88.2704 43.3177 87.0499 44.7101 87.0499 44.7101L94.605 49.7837C94.605 49.7837 95.9534 48.0643 97.7847 46.6051C98.3851 46.1262 99.0041 45.7007 99.5704 45.3425C99.3308 46.5424 99.016 48.2767 99.0992 48.7251C99.2573 49.5754 100.422 49.5685 100.54 48.749C100.616 48.2123 100.25 46.2518 100.011 45.0701C101.03 44.4564 101.787 44.1013 101.787 44.1013L98.9787 43.8144L99.2798 41.8355L96.8738 42.178L96.7753 39.943L94.5104 40.5733L94.3446 38.3854L92.3297 39.2708L91.4264 36.999Z"
                fill="#4D586F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.4116 59.0618L80.8555 60.956L89.9626 50.0509L93.7482 51.9433L95.4658 50.1228L86.5642 43.9668L85.0672 46.0125L87.9201 48.7724L78.4116 59.0618Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.797 48.6655C71.8123 45.5143 67.9131 44.2898 66.2882 44.9149C62.8037 46.255 63.7241 50.01 65.1671 51.9161C66.6564 53.8804 68.3312 55.1101 70.0402 55.5104C70.6833 56.3484 73.0539 57.8008 77.5026 58.0221C78.9309 58.0936 80.0854 57.9871 80.9288 57.7744C81.7829 58.4513 82.9612 58.9884 84.3238 59.1273C87.1531 59.4168 89.6443 57.2178 89.8072 54.9194C89.9852 52.4113 88.331 50.917 86.2734 50.5411C84.153 50.1564 81.3732 51.4484 80.1261 53.3713C78.9349 52.3617 77.4278 51.4993 75.891 51.3951C75.5769 51.3735 75.2714 51.3746 74.9755 51.3954C74.7645 50.4785 74.3382 49.5228 73.797 48.6655Z"
                fill="#E1E6EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.4075 59.0612L80.8514 60.9553L83.4771 57.8118C83.4771 57.8118 83.2886 57.1499 82.5917 56.643C81.8948 56.1377 81.125 56.1172 81.125 56.1172L78.4075 59.0612Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.5786 46.1868C51.8629 38.8045 58.6085 35.4855 65.6675 38.5707C71.7843 41.245 73.1279 45.1232 73.1279 45.1232L59.2618 54.1804C59.2618 54.1804 56.5424 51.5147 54.5786 46.1868Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.1078 46.7853C62.9822 36.7442 55.6524 34.499 48.1532 34.922C38.906 35.4444 34.1006 43.432 35.4612 54.5187C35.9135 58.2014 36.6943 61.1711 37.7836 63.5636C36.759 64.6314 35.9587 66.0414 35.5297 67.8249C33.8367 74.8611 34.2987 81.459 35.4766 82.3033C36.1071 82.7572 45.209 81.0121 49.4583 76.7016C52.5799 80.0333 55.8569 82.2948 56.4365 82.1947C58.3169 81.8718 63.8827 74.4862 66.5898 65.5591C66.6898 65.229 66.7784 64.905 66.8558 64.5871C68.8984 58.618 68.8506 51.0245 67.1078 46.7853Z"
                fill="#4D586F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.732 84.0766C31.0646 86.0367 30.7304 89.5159 32.2918 90.2429C33.8276 90.9605 37.0148 90.9451 38.4806 90.4818C40.666 89.7899 40.1338 86.6627 39.2797 85.2138C38.3594 83.6433 35.8918 82.4907 33.732 84.0766ZM57.6536 83.2387C55.1576 82.2052 52.4458 83.386 51.5264 84.906C50.6732 86.3112 50.9829 88.7345 52.9884 89.7612C54.9654 90.7785 61.4118 90.9506 62.4855 89.077C63.297 87.6556 62.0509 85.0532 57.6536 83.2387Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.4049 46.6078C45.9826 40.7224 42.0427 37.2124 39.4757 37.9711C34.6495 39.3994 33.8209 53.1971 37.0072 55.6376C40.8742 58.5987 46.9257 53.8804 46.4049 46.6078ZM49.6363 34.7544C43.9616 34.7116 40.9382 37.3859 40.9382 37.3859C40.9382 37.3859 40.9453 37.3866 40.959 37.3885L40.9845 37.392C41.4361 37.4568 45.106 38.0928 48.1061 41.9996C50.6845 45.3598 50.8132 46.8481 50.8132 46.8481C50.8132 46.8481 55.8345 47.2916 59.0833 47.1546C62.2932 47.0202 63.7845 46.5467 63.7845 46.5467C63.7845 46.5467 63.7908 46.4837 63.7924 46.3645L63.7925 46.2545C63.786 45.7114 63.6772 44.4496 62.9352 42.796C61.3454 39.2595 57.7226 36.4516 57.7226 36.4516C57.7226 36.4516 54.6093 34.7921 49.6363 34.7544Z"
                fill="#6F7D90"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.8874 47.1201C24.6595 48.2625 23.6511 49.7215 23.4275 51.0333C22.3727 51.9052 21.0309 53.6658 20.2142 56.5933C19.9924 57.3943 19.8702 58.11 19.8294 58.7281C17.9818 59.5359 16.3657 63.1919 17.3104 65.6202C18.2119 67.9305 20.2126 69.8041 23.3534 68.6507C25.7765 67.7576 27.1305 65.532 25.7945 62.5033C25.4761 61.782 24.8434 60.9466 24.07 60.2303C25.6389 59.3443 27.2441 57.9995 27.722 56.4204C27.9129 55.7871 27.9643 55.1715 27.9138 54.5907C29.6043 54.5084 31.4632 53.7013 33.3432 52.2194C35.2701 50.702 37.1988 47.2759 34.1963 45.19C32.7959 44.2163 28.6836 44.5195 25.8874 47.1201Z"
                fill="#E1E6EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.9201 48.0416C47.8032 41.1184 40.7859 35.0171 32.9078 38.6154C27.1271 41.2562 25.3934 44.1942 25.3934 44.1942L35.6112 54.6129C35.6112 54.6129 39.2699 53.2129 42.9201 48.0416Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.4463 36.1843C41.2503 37.7239 45.1126 39.3809 50.0591 39.8861C55.0122 40.393 59.1804 39.5581 59.3754 38.0211C59.5677 36.4823 55.7091 34.8236 50.7579 34.3175C45.8076 33.8123 41.6376 34.6455 41.4463 36.1843Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.0471 27.3625L43.8804 36.209C43.8804 36.209 47.0913 37.7992 51.3257 37.9961C54.7884 38.158 57.2039 37.2768 57.2039 37.2768L57.4937 30.6105C57.4937 30.6105 55.2458 28.0158 51.6306 27.401C46.8015 26.579 43.0471 27.3625 43.0471 27.3625Z"
                fill="#E1E6EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.0471 27.3625L43.3946 31.06L43.4013 31.1174C43.4013 31.1174 45.3575 34.624 50.1658 35.3339C54.8481 36.0232 57.3251 34.5144 57.3251 34.5144L57.3279 34.4185L57.4937 30.6105C57.4937 30.6105 55.2458 28.0158 51.6306 27.401C46.8015 26.579 43.0471 27.3625 43.0471 27.3625Z"
                fill="#C3CCD9"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.9472 6.26519C25.0867 9.49434 24.2118 14.5174 25.421 19.0302C26.7514 24.0054 30.2255 25.9141 29.9935 29.8736C29.8268 32.7688 27.4719 34.0148 27.4719 34.0148C27.4719 34.0148 34.9977 33.3006 39.2341 29.4934C43.8775 25.3147 47.4672 15.0338 44.6701 9.24515C42.1485 4.02766 34.2345 1.83807 28.9472 6.26519Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.7603 17.7319C67.6116 26.0235 63.3374 32.6317 61.4294 33.8699C59.5792 35.0696 47.5785 33.5197 44.5532 30.9208C41.2458 28.083 37.6476 20.6442 37.3049 14.9438C36.8115 6.76086 42.1368 0.484966 49.7923 0.281164C62.9444 -0.0682098 67.9345 8.22343 67.7603 17.7319Z"
                fill="#E1E6EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.2116 17.6725C49.8746 17.6554 48.7762 18.6273 48.763 19.833C48.7459 21.0447 49.8169 22.0363 51.1548 22.0491C52.489 22.0628 53.5864 21.0943 53.6034 19.8852C53.6205 18.6778 52.5486 17.6862 51.2116 17.6725ZM63.4831 17.4768C62.1451 17.4605 61.0496 18.4298 61.0344 19.6381C61.0193 20.8472 62.0902 21.8379 63.4253 21.8534C64.7623 21.8671 65.8588 20.8986 65.8758 19.6912C65.891 18.4821 64.8219 17.4905 63.4831 17.4768Z"
                fill="#6F7D90"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.5168 14.4922C44.5168 14.4922 44.2867 18.1537 42.6249 20.8348C40.6914 23.9595 38.2825 25.3699 38.2825 25.3699C38.2825 25.3699 33.9316 19.7371 35.0859 12.4781C35.9456 7.0397 41.4546 0.629369 49.7918 0.224334C59.629 -0.256055 64.8046 4.61121 66.9464 8.85765C68.2294 11.3983 68.5239 13.7035 68.5239 13.7035L60.6355 13.8833C60.6355 13.8833 60.2747 11.9755 59.4604 9.99654C58.7313 8.2274 57.1387 6.7006 57.1387 6.7006C57.1387 6.7006 57.5629 8.23853 57.7598 10.3802C57.9416 12.3916 57.8214 13.9535 57.8214 13.9535L44.5168 14.4922Z"
                fill="#9BA8BA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.6737 19.6251C41.6737 19.6251 42.7929 10.8094 50.081 6.80012C58.2251 2.3182 64.6904 5.5696 64.6904 5.5696C64.6904 5.5696 64.7491 2.65987 59.4352 1.22383C54.1194 -0.213909 48.2166 1.65456 44.057 5.41461C38.0263 10.8736 39.3292 19.2089 39.3292 19.2089L41.6737 19.6251Z"
                fill="#4D586F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.3234 26.2339C36.4534 25.7133 35.6798 20.7587 37.2242 19.3423C38.7657 17.926 42.1508 18.9459 42.9925 21.013C43.8353 23.0784 42.7681 26.8556 39.3234 26.2339Z"
                fill="#E1E6EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M61.8867 26.5339C61.8867 26.5339 60.4191 25.83 57.6381 25.8686C53.2559 25.9302 49.8083 25.3848 49.8083 25.3848C49.8083 25.3848 51.383 30.2084 56.4318 30.6177C60.4427 30.9422 61.8867 26.5339 61.8867 26.5339Z"
                fill="#4D586F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.7569 27.1871C51.7569 27.1871 54.1933 27.0989 55.8295 27.6315C57.7336 28.2515 57.8813 29.6216 57.8813 29.6216C57.8813 29.6216 56.2631 30.346 54.215 29.3056C52.5713 28.469 51.7569 27.1871 51.7569 27.1871Z"
                fill="#9BA8BA"
            />
        </g>
    </svg>
);
export default PaintingGraphs;
