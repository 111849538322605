import React from 'react';
import { TooltipProps } from 'recharts';
import { numberWithSpaces } from '../../../helpers/functions';
import CustomTooltip from './CustomTooltip';

const ActiveAdsTooltip = ({ payload, active }: TooltipProps<string, number>) => (
    <>
        {active && payload && (
            <CustomTooltip payload={payload} graphId="active-ads">
                {payload[0].payload.private !== undefined && (
                    <>
                        <p className="flex flex-row items-center text-12 text-white m-0 mb-2">
                            <span className="mi-podlet-legend mi-podlet-private" />
                            Private
                        </p>
                        <p className="text-12 text-white font-bold">
                            {numberWithSpaces(payload[0].payload.private)} annonser
                        </p>
                    </>
                )}
                {payload[0].payload.forhandlere !== undefined && (
                    <>
                        <p className="flex text-12 text-white m-0 items-center mb-2">
                            <span className="mi-podlet-legend mi-podlet-dealers" />
                            Forhandlere
                        </p>
                        <p className="text-12 text-white font-bold">
                            {numberWithSpaces(payload[0].payload.forhandlere)} annonser
                        </p>
                    </>
                )}
            </CustomTooltip>
        )}
    </>
);

export default ActiveAdsTooltip;
