import { Alert } from '@fabric-ds/react';
import React from 'react';

const NoDataAlert = () => (
    <Alert type="info" show={true} className="py-48" style={{ height: 235, width: 173 }}>
        <p className="font-bold motor-insight-media-text12">Ingen data</p>
        <p className="motor-insight-media-text10">
            Au da! Nå er det noe som ikke fungerer. Vi finner ikke data til deg.
        </p>
    </Alert>
);

export default NoDataAlert;
