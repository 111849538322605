import React from 'react';
import { numberWithSpaces } from '../../helpers/functions';

type ColorSet = {
    positive: string;
    negative: string;
    neutral: string;
};

type ColorTypes = 'textColor' | 'centerLineColor' | 'leftColor' | 'rightColor';

const colorConfig: Record<ColorTypes, ColorSet> = {
    textColor: {
        positive: '#18797B',
        negative: '#8E001D',
        neutral: '#0F4880',
    },
    centerLineColor: {
        positive: '#005053',
        negative: '#D64C52',
        neutral: '#2271BF',
    },
    leftColor: {
        positive: '#F1F4F9',
        negative: '#FBD9D8',
        neutral: '#D6E2FB',
    },
    rightColor: {
        positive: '#CBE8E8',
        negative: '#F1F4F9',
        neutral: '#D6E2FB',
    },
};
const PopularityGraph = ({
    interestValue,
    marketSubject,
    fromAvgValue,
    interestPercentage,
}: {
    interestValue: number;
    marketSubject: string;
    fromAvgValue: number;
    interestPercentage: number;
}) => {
    const getColor = (type: ColorTypes): string => {
        if (fromAvgValue > 0) return colorConfig[type].positive;
        if (fromAvgValue < 0) return colorConfig[type].negative;
        return colorConfig[type].neutral;
    };

    return (
        <div className="mb-4">
            <div className="flex justify-between">
                <p className="text-12 font-bold m-1">
                    {numberWithSpaces(interestValue)} {marketSubject}
                </p>
                <p className="text-12 m-0">
                    <span className="font-bold" style={{ color: getColor('textColor') }}>
                        {numberWithSpaces(fromAvgValue)}
                    </span>
                    {` ${fromAvgValue >= 0 ? 'over' : 'under'} snitt`}
                </p>
            </div>
            <div className="h-12 w-full flex relative">
                <div className="w-1/2 relative">
                    <div
                        style={{
                            backgroundColor: getColor('leftColor'),
                        }}
                        className="h-full rounded-l-full"
                    />
                    {fromAvgValue < 0 && (
                        <div
                            style={{
                                backgroundColor: '#EE6564',
                                right: 0,
                                top: 0,
                                width: `${interestPercentage}%`,
                            }}
                            className="h-full rounded-l-full absolute"
                        />
                    )}
                </div>
                {fromAvgValue === 0 && (
                    <div
                        style={{
                            backgroundColor: '#2271BF',
                            top: 0,
                            right: '50%',
                            transform: 'translateX(50%)',
                            verticalAlign: 'middle',
                        }}
                        className="h-full w-12 rounded-full absolute z-10"
                    />
                )}
                <div style={{ borderColor: getColor('centerLineColor') }} className="filled-line"></div>
                <div className="w-1/2 relative">
                    <div
                        style={{
                            backgroundColor: getColor('rightColor'),
                        }}
                        className="h-full w-full rounded-r-full"
                    />
                    {fromAvgValue > 0 && (
                        <div
                            style={{
                                backgroundColor: '#0C6467',
                                left: 0,
                                top: 0,
                                width: `${interestPercentage}%`,
                            }}
                            className="h-full rounded-r-full absolute"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PopularityGraph;
