import React from 'react';
import { Button } from '@fabric-ds/react';
import FeedbackPerson from '../Logos/FeedbackPerson';
import Card from './Card';

const FeedbackCard = () => (
    <Card borderColor="#03A3DD" backgroundColor="#F1F9FF" hasData={true} isLoading={false}>
        <div className="flex flex-row mt-14 mb-8 mx-16 items-center" style={{ maxHeight: 85 }}>
            <p className="text-16 font-bold text-left mb-0" style={{ maxWidth: 82 }}>
                Vi vil høre fra deg!
            </p>

            <FeedbackPerson />
        </div>
        <p className="text-12 px-16 mb-8">
            Hva synes du om å få Diagnoseporten her også? Er det bra? Noe som mangler?{' '}
        </p>
        <Button
            onClick={() => {
                window.open('https://forms.gle/z96p4eYp314CKz1JA', '_blank');
            }}
            className="text-12 mb-14 mx-16 w-148 px-8 py-10"
            role="link"
        >
            Gi oss tilbakemelding
        </Button>
    </Card>
);

export default FeedbackCard;
