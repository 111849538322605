import React, { useEffect } from 'react';
import { Expandable } from '@fabric-ds/react';
import { createRoot } from 'react-dom/client';
import Header from './components/Header';
import Footer from './components/Footer';
import MarketView from './components/MarketView';
import { ParamsProvider } from './hooks/Params';
import trackEvent from './lib/tracking';

function App() {
    useEffect(() => {
        // ISP_ev24
        trackEvent('Show', 'View', 'Podlet', 'View search podlet');
    }, []);
    const [open, setOpen] = React.useState(true);

    const handleClosing = () => {
        // ISP_ev25, ISP_ev26
        trackEvent(open ? 'Hide' : 'View', 'Click', 'Button', `${open ? 'Hide' : 'View'} search podlet`);
        setOpen(!open);
    };
    return (
        <ParamsProvider>
            <Expandable
                onChange={handleClosing}
                title={<Header />}
                box
                animated
                expanded
                buttonClass="hover:no-underline focus:no-underline p-24"
                className="motor-insight-expandable mb-20 md:m-20 border border-bluegray-300"
            >
                <MarketView />
                <Footer />
            </Expandable>
        </ParamsProvider>
    );
}

const container = document.getElementById('motor-insight-podlet');

if (container !== null) {
    const root = createRoot(container!);
    root.render(<App />);
}
