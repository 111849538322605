import { ActiveAds, PublishingTimeMap } from './types';

export async function searchKeysMappingSchema() {
    const { origin } = window.location;
    let url = `${origin}/diagnoseporten/api/vt-schema`;
    if (origin.includes('local')) {
        url = 'http://local.finn.no:8080/diagnoseporten/api/vt-schema';
    }

    return fetch(url, {
        credentials: 'include',
    })
        .then((value) => value.json())
        .catch((err) => err);
}

export const getStandingTimeMap = async (
    setMarketData: (data: PublishingTimeMap[] | undefined) => void,
    filters: string,
    setLoading: (loading: boolean) => void,
) => {
    let url = `${window.location.origin}/diagnoseporten/api/searchpagepodlet/standingtimemap?${filters}`;
    if (window.location.href.includes('local')) {
        url = `http://local.finn.no:8080/diagnoseporten/api/searchpagepodlet/standingtimemap?${filters}`;
    }

    let data: PublishingTimeMap[] | undefined = await fetch(url, {
        credentials: 'include',
    })
        .then((res) => res.json())
        .catch(() => undefined);

    if (data !== undefined) {
        data = data.filter((item) => item.county !== 'Svalbard');
    }
    setMarketData(data);
    setLoading(false);
};

export const getActiveAds = async (
    setActiveAdsData: (data: ActiveAds | undefined) => void,
    filters: string,
    setLoading: (loading: boolean) => void,
) => {
    let url = `${window.location.origin}/diagnoseporten/api/searchpagepodlet/activeads?${filters}`;
    if (origin.includes('local')) {
        url = `http://local.finn.no:8080/diagnoseporten/api/searchpagepodlet/activeads?${filters}`;
    }

    const data: ActiveAds | undefined = await fetch(url, {
        credentials: 'include',
    })
        .then((res) => res.json())
        .catch(() => undefined);

    setActiveAdsData(data);
    setLoading(false);
};
