import React from 'react';
import Card from './Card';
import ChartCarsInAndOut from '../Graphs/ChartCarsInAndOut';
import { CardData } from '../../helpers/types';

type RateOfChange = 'increasing' | 'decreasing' | 'still';

type InAndOutData = {
    carsIn: number;
    carsOut: number;
    rateOfChange: RateOfChange;
    percentageChange: number | null;
    carsChange: number | null;
};

const InAndOutState = {
    increasing: {
        color: 'green-700',
        title: 'Økt',
        description: 'Det har kommet flere biler inn, enn det er registrert ut.',
    },
    still: {
        color: 'blue-500',
        title: 'Stabilt',
        description: 'Det har kommet ca. like mange biler inn, som det er registrert ut.',
    },
    decreasing: {
        color: 'red-700',
        title: 'Falt',
        description: 'Det er registrert flere biler ut, enn det er kommet inn.',
    },
};

const InAndOutCardContent = ({
    hasData,
    isLoading,
    carsOut,
    carsIn,
    rateOfChange,
    percentageChange,
    carsChange,
}: CardData & InAndOutData) => {
    const addsState = InAndOutState[rateOfChange];

    return (
        <Card title="Tilbud på FINN.no" subTitle="" hasData={hasData} isLoading={isLoading}>
            <div className="px-16 flex justify-between flex-col h-full">
                <h3 className="mb-0">
                    <span className="text-blue-500">{addsState.title} </span>
                    tilbud
                </h3>
                <p className="text-12">
                    <span className={`text-${addsState.color} font-bold`}>
                        {percentageChange && `${percentageChange}%`}
                        {carsChange && carsChange}
                    </span>{' '}
                    siste sju dager
                </p>
                <p className="text-12 mb-28">{addsState.description}</p>
                <ChartCarsInAndOut carsIn={carsIn} carsOut={carsOut} />
            </div>
        </Card>
    );
};

export default InAndOutCardContent;
