import React from 'react';
import { IconInfo16 } from '@fabric-ds/icons/react';
import trackEvent from '../lib/tracking';

// ISP_ev28
const Footer = () => (
    <div className="flex items-center gap-4 p-24">
        <IconInfo16 className="mr-4" />
        <p className="text-12 mb-0">
            Du ser dette fordi du har{' '}
            <span onClick={() => trackEvent('Open', 'Click', 'Link', 'Go to Diagnoseporten')}>
                <a href={`${window.location.origin}/diagnoseporten/sjekkbil`}>Diagnoseporten</a>
            </span>
        </p>
    </div>
);

export default Footer;
