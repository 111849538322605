import React from 'react';

const MapLegend = () => (
    <section className="absolute" style={{ right: 5, bottom: 2 }}>
        <ol className="text-12">
            <li>
                <div className="legend-circle circle-xlarge" />
                0–29 dager
            </li>
            <li>
                <div className="legend-circle circle-large" />
                30–59 dager
            </li>
            <li>
                <div className="legend-circle circle-medium" />
                60–89 dager
            </li>
            <li>
                <div className="legend-circle circle-small" />
                90+ dager
            </li>
            <li>
                <div className="legend-circle circle-xsmall" />
                Ikke nok biler
            </li>
        </ol>
    </section>
);

export default MapLegend;
