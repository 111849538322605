import React, { useEffect, useState } from 'react';

import { getActiveAds, getStandingTimeMap } from '../../helpers/fetchers';
import { ActiveAds, PublishingTimeMap } from '../../helpers/types';
import MoreContentComing from './MoreContentComing';
import { activeAdsIsValid, isPrivateMarketShown, isProMarketShown } from '../../helpers/functions';
import ActiveAdsCard from './ActiveAdsCard';
import FeedbackCard from './FeedbackCard';
import { useParams } from '../../hooks/Params';
import MapCardContent from '../Graphs/Map/MapCardContent';
import MarketPopularity from './MarketPopularity';
import PublishTimeCard from './PublishTimeCard';
import InAndOutCardContent from './InAndOutCardContent';

const CardSlider = ({ scrollToRef }: { scrollToRef: React.Ref<HTMLDivElement>; mapUrl: string }) => {
    const { searchParams } = useParams();
    const [isMapDataLoading, setMapDataLoading] = useState<boolean>(true);
    const [isActiveAdsDataLoading, setActiveAdsDataLoading] = useState<boolean>(true);
    const [mapData, setMapData] = useState<PublishingTimeMap[] | undefined>();
    const [activeAdsData, setActiveAdsData] = useState<ActiveAds | undefined>();
    const [hideData, setHideData] = useState<boolean>(false);
    async function loadStandingTimeMapData(urlQueryParams: URLSearchParams | undefined) {
        setMapDataLoading(true);

        if (urlQueryParams === undefined) {
            setMapDataLoading(false);
            setActiveAdsDataLoading(false);
        } else {
            const paramsString = urlQueryParams.toString();
            let multipleManufacturers = false;

            const manufacturers = urlQueryParams.get('manufacturer');

            if (manufacturers !== null) {
                const unique = Array.from(new Set(manufacturers.split(',')));
                multipleManufacturers = unique.length > 1;
                setHideData(multipleManufacturers);
            }

            if (!multipleManufacturers) {
                await getStandingTimeMap(setMapData, paramsString, setMapDataLoading).catch(() =>
                    setMapDataLoading(false),
                );

                await getActiveAds(setActiveAdsData, paramsString, setActiveAdsDataLoading).catch(() =>
                    setActiveAdsDataLoading(false),
                );
            } else {
                setMapDataLoading(false);
                setActiveAdsDataLoading(false);
            }
        }
    }

    useEffect(() => {
        (async () => {
            await loadStandingTimeMapData(searchParams);
        })();
    }, [searchParams]);

    const root = document.getElementById('motor-insight-podlet');

    const showMapCard = root?.getAttribute('showMapCard') === 'true';
    const showActiveAdsCard = root?.getAttribute('showActiveAdsCard') === 'true';
    const showCarsInAndOutCard = root?.getAttribute('showCarsInAndOutCard') === 'true';
    const showPublishingTimeCard = root?.getAttribute('showPublishingTimeCard') === 'true';
    const showPopularityCard = root?.getAttribute('showPopularityCard') === 'true';

    return (
        <div className="flex">
            <div
                id={'cardContainer'}
                className="flex gap-10 h-auto scrollbar overflow-x-auto pb-12 px-24 first-child-ml-24"
                style={{
                    scrollBehavior: 'smooth',
                }}
                ref={scrollToRef}
            >
                {showActiveAdsCard && (
                    <ActiveAdsCard
                        activeAdsData={activeAdsData}
                        hasData={activeAdsIsValid(activeAdsData) && !hideData}
                        isLoading={isActiveAdsDataLoading}
                        showPro={isProMarketShown()}
                        showPriv={isPrivateMarketShown()}
                    />
                )}
                {showMapCard && (
                    <MapCardContent
                        data={mapData}
                        hideData={hideData}
                        isLoading={isMapDataLoading}
                        hasData={mapData !== undefined}
                    />
                )}
                {showCarsInAndOutCard && (
                    <InAndOutCardContent
                        hasData={true}
                        isLoading={false}
                        carsIn={500}
                        carsOut={800}
                        rateOfChange={'still'}
                        percentageChange={10}
                        carsChange={null}
                    />
                )}
                {showPopularityCard && (
                    <MarketPopularity
                        adViews={{ title: 'visninger', value: 100, avgValue: -10, percentage: 20 }}
                        favourites={{ title: 'favoritter', value: 1115, avgValue: 415, percentage: 20 }}
                        popularityYesterday={1115}
                        lastSevenDays={7}
                    />
                )}
                {showPublishingTimeCard && <PublishTimeCard />}
                <FeedbackCard />
                <MoreContentComing />
            </div>
        </div>
    );
};

export default CardSlider;
