import React from 'react';
import FinnLogo from './Logos/FinnLogo';

const HeaderComponent = () => (
    <div className="text-gray-700">
        <p className="h3 inline-flex my-0">
            <span className="motor-insight-finn-logo mr-16 align-text-bottom">
                <FinnLogo />
            </span>
            Diagnoseporten
        </p>
    </div>
);

export default HeaderComponent;
