import React from 'react';

const Spinner = () => (
    <div
        className="card bg-white border overflow-hidden hover:shadow h-full flex items-center justify-center"
        style={{ height: 235, width: 173 }}
    >
        <div className="f-spinner" aria-label="Laster innhold..." role="img" />
    </div>
);

export default Spinner;
