import React from 'react';

const FeedbackPerson = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="87" fill="none" viewBox="0 0 67 87">
        <path
            fill="#06BEFB"
            fillRule="evenodd"
            d="M32.632 39.908c3.103-2.08 11.15-3.19 15.969-1.41 4.072 1.506 6.697 3.706 7.37 8.711.033.252.058.5.074.745 3.303 2.048 5.671 5.14 7.592 8.718.407.757.747 1.488 1.02 2.183 1.251 2.537 1.912 5.95 1.564 9.043-.886 7.895-5.028 7.734-8.066 4.039-.224 3.956-.683 5.887-.683 5.887s-5.602 2.648-12.584 3.158c-5.545.406-13.446.302-14.696-.212-1.125-.461-3.017-2.862-3.017-2.862l.043 2.478s-1.292.255-2.673-.941c-.822-.713-1.813-5.11-1.886-10.818-.096.001-.192 0-.287-.005-2.827.219-6.246-.865-8.625-2.965-5.574-4.922-2.662-8.862 2.156-9.705a13.81 13.81 0 014.614-.036c.97-2.077 2.355-4.106 4.17-5.794.785-.73 1.52-1.337 2.205-1.826l.018-.094c.615-3.105 2.731-6.287 5.722-8.294z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#0063FB"
            fillRule="evenodd"
            d="M31.382 74.493c.915 0 1.658.737 1.658 1.643 0 .91-.743 1.646-1.658 1.646-.917 0-1.661-.737-1.661-1.646 0-.906.744-1.643 1.66-1.643zm-.777-5.764c.917 0 1.66.737 1.66 1.643 0 .909-.743 1.644-1.66 1.644-.917 0-1.66-.736-1.66-1.644 0-.906.743-1.644 1.66-1.644z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#F0D9B4"
            fillRule="evenodd"
            d="M15.607 62.88c1.416-2.587.82-5.704-2.79-7.67-2.173-1.184-6.695-1.645-8.079.262-1.36 1.873.165 7.03 3.114 8.797 2.65 1.588 5.914 1.97 7.755-1.388z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#FF5844"
            fillRule="evenodd"
            d="M.042 60.962c-.253-.328.768-2.304 3.907-5 3.403-2.92 9.403-6.265 10.648-6.934 1.136-.61 2.209.71 1.209 1.674-.986.95-6.617 5.579-9.974 7.782-2.895 1.902-5.58 2.747-5.79 2.478z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#E4290A"
            fillRule="evenodd"
            d="M12.417 48.637c.833-.413 1.393-.498 1.872-.13.146.112.28.288.372.487 1.11-.523 2.12.763 1.14 1.707-.624.602-2.998 2.592-5.524 4.542l-.276.213-.138.106-.277.211a82.02 82.02 0 01-2.49 1.828l-.017.012L5.19 54.96l.062-.049c.627-.477 1.3-.955 1.985-1.422l.295-.2.295-.198.297-.196a94.378 94.378 0 014.925-3.006c-.146-.169-.292-.302-.61-.232-.953.21-1.988 1.544-3.177 2.008-1.45.565-2.593-.45-2.593-.45s1.48.17 2.41-.283c.817-.396 2.255-1.757 3.338-2.294z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#F0D9B4"
            fillRule="evenodd"
            d="M62.808 86.303c2.584-.304 4.538-2.28 4.14-5.79-.238-2.11-2.186-5.504-4.262-5.519-2.04-.015-5.002 3.633-4.792 6.581.186 2.646 1.556 5.123 4.914 4.728z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#0293F4"
            fillRule="evenodd"
            d="M54.748 43.224s.019.038.03.056c.575 1.08.974 2.369 1.184 3.93.743 5.54-2.942 9.417-6.789 11.786-3.715 2.29-12.639 3.332-16.387 4.645-3.564 1.248-6.886 4.323-6.886 4.323s1.41-2.237 3.912-4.195c3.847-3.012 12.094-4.283 15.062-5.637 4.073-1.857 6.1-3.543 8.082-7.11 1.655-2.982 1.79-6.557 1.795-7.538v-.104c0-.101-.002-.156-.002-.156zm-20.509-3.55c1.269-.837 3.343-1.511 6.433-1.286 3.599.26 4.578.993 5.119 2.95.566 2.046-.798 3.95-2.09 5.446-1.293 1.494-5.065 3.78-6.638 4.505-1.572.725-2.758 1.43-2.758 1.43s-.683-.475-1.789-2.284c-1.486-2.435-1.766-4.022-1.4-6.193.388-2.305 2.088-3.884 3.123-4.568z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#0063FB"
            fillRule="evenodd"
            d="M30.25 57.246s1.234.968 2.156 2.306c.123.178.348.668.57 1.185l.11.259c.279.661.526 1.289.526 1.289s-1.368-1.068-2.036-2.135c-.62-.992-1.325-2.904-1.325-2.904zm6.077-4.142s1.762 2.228 2.501 3.714c.764 1.537 1.55 4.014 1.55 4.014l-.007-.007-.009-.008-.027-.028c-.279-.28-1.845-1.897-2.552-3.426-.687-1.483-1.404-4.07-1.453-4.249l-.003-.01zm5.042-2.904l.085.083.153.152c.6.598 2.178 2.236 2.994 3.66.982 1.71 1.854 5.115 1.854 5.115s-2.09-2.616-2.919-4.313c-.21-.428-.47-.983-.738-1.56l-.095-.205c-.663-1.434-1.334-2.931-1.334-2.931zm4.04-3.919s2.988 1.731 4.333 3.748c1.195 1.793 1.971 5.68 1.971 5.68s-.764-1.49-1.596-2.966l-.12-.211c-.418-.736-.844-1.452-1.193-1.959a31.011 31.011 0 00-.76-1.056l-.145-.191-.146-.19a62.2 62.2 0 00-2.344-2.855zm-11.425 2.371l-.008.004c-.141.071-2.027 1.038-3.21 2.27-1.147 1.197-2.133 3.971-2.133 3.971s-.452-3.204 1.553-5.029c1.297-1.18 3.798-1.216 3.798-1.216zm-4.735-2.53c1.541-.967 3.07-.257 3.07-.257s-1.594.24-2.78.993c-1.26.8-2.263 2.594-2.263 2.594s.39-2.337 1.973-3.33zm22.939-2.669c2.029 1.41 3.165 5.151 3.23 5.37l.003.01s-1.962-2.925-3.794-4.42c-1.875-1.531-4.417-2.54-4.417-2.54h.109c.514.005 2.764.118 4.869 1.58z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#F0D9B4"
            fillRule="evenodd"
            d="M48.082 35.008c0 2.018-.126 5.644-1.972 8.935-2.37 4.227-8.565 7.153-8.565 7.153s-3.492-2.947-4.655-5.765c-1.164-2.819-1.164-6.577-1.035-8.53.13-1.953 1.13-7.591 8.017-7.91 6.272-.292 8.21 4.099 8.21 6.117z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#DDC59E"
            fillRule="evenodd"
            d="M48.082 35.008c0 1.002-.031 2.4-.288 3.957l-.014.079s-2.963 4.836-7.877 5.828c-4.782.967-7.394-.625-7.394-.625l-.026-.093c-.755-2.616-.74-5.66-.628-7.353.129-1.953 1.13-7.591 8.017-7.91 6.272-.292 8.21 4.099 8.21 6.117z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#F0D9B4"
            fillRule="evenodd"
            d="M27.182 8.736s-1.873 3.033-2.154 7.387c-.258 4.014.732 4.569.818 7.217.087 2.646-.56 3.97-.215 7.088.544 4.918 3.664 10.164 5 11.018 1.236.79 8.196.053 11.075-.725 2.844-.77 6.12-3.162 7.93-5.766.498-.717 1.423-2.413 1.423-2.413s1.912.737 3.405.021c1.337-.64 2.629-3.458 1.854-5.38-.777-1.921-2.587-2.092-2.587-2.092s.364-1.394.776-3.119c.56-2.347 1.273-8.668-1.637-13.023-2.185-3.27-5.82-5.188-7.888-5.51-2.07-.32-4.051-.041-4.051-.041s-.475-.47-.948-1.239c-.6-.97-.777-1.58-.777-1.58s-.775.555-1.55 1.28c-.745.696-1.682 1.923-1.682 1.923s-1.234-.38-1.81-1.154c-.732-.981-1.078-2.049-1.078-2.049s-1.292 1.28-1.81 2.135c-.61 1.008-1.034 2.391-1.034 2.391s-1.12 0-2.283-.598c-1.21-.621-2.587-2.006-2.587-2.006s-.366 2.328 0 3.63c.303 1.078 1.81 2.605 1.81 2.605z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#6D5A3F"
            fillRule="evenodd"
            d="M30.511 21.845c1.072 0 1.94.86 1.94 1.922a1.931 1.931 0 01-1.94 1.922 1.93 1.93 0 01-1.938-1.922 1.93 1.93 0 011.938-1.922zm12.737-.64a1.93 1.93 0 011.938 1.92 1.93 1.93 0 01-1.938 1.924c-1.071 0-1.94-.862-1.94-1.923 0-1.06.869-1.92 1.94-1.92z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#C9AC82"
            fillRule="evenodd"
            d="M32.615 28.811l.872 12.97c-.37.006-.718.005-1.038-.007l-.865-12.894a.514.514 0 01.481-.546.515.515 0 01.55.477zm3.394-.283l.874 13.015c-.344.038-.69.072-1.03.102l-.876-13.049a.517.517 0 011.032-.068zm3.684-.189l.849 12.65c-.322.065-.665.128-1.024.19l-.856-12.77a.516.516 0 011.03-.07zm-10.6.98l.764 11.387c-.352-.42-.745-.98-1.146-1.642l-.65-9.677a.515.515 0 01.482-.546.515.515 0 01.55.478zm14.478-.94l.754 11.25c-.333.184-.668.352-1.002.502l-.784-11.686a.514.514 0 01.481-.544.516.516 0 01.55.477zm3.941.196l.554 8.235c-.31.31-.637.611-.976.9l-.609-9.066a.516.516 0 011.031-.069z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#905F5A"
            fillRule="evenodd"
            d="M30.05 31.603s3.445-.14 7.258-.509c3.614-.347 6.987-.872 6.987-.872s-.539 2.615-1.685 3.94c-.904 1.048-2.595 2.19-4.398 2.436-1.731.236-4.194-.45-5.633-1.53-1.606-1.205-2.53-3.465-2.53-3.465z"
            clipRule="evenodd"
        ></path>
        <path
            fill="#FF937B"
            fillRule="evenodd"
            d="M37.262 32.774c-1.566.175-3.77.825-3.429 1.841.127.375 1.669 1.2 3.858.974 2.087-.212 3.5-1.33 3.489-1.872-.023-.924-2.18-1.138-3.918-.943z"
            clipRule="evenodd"
        ></path>
    </svg>
);

export default FeedbackPerson;
