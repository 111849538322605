import { Button, Alert } from '@fabric-ds/react';
import { IconTableSortUp16, IconTableSortDown16 } from '@fabric-ds/icons/react';
import React, { useEffect, useRef, useState } from 'react';
import CardSlider from './cards/CardSlider';
import { trackFilterChange } from '../helpers/functions';
import { useParams } from '../hooks/Params';
import trackEvent from '../lib/tracking';

const MarketView = () => {
    const { searchParams, setSearchParams } = useParams();
    const [marketShown, setMarketShown] = useState(window.location.search === '' ? 'Hele markedet' : 'Valgte filtre');
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [maxScrollRight, setMaxScrollRight] = useState(true);
    const [maxScrollLeft, setMaxScrollLeft] = useState(true);

    const dataSource = window.location.href;
    const scrollToRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const updateScrollMax = () => {
        const currScrollRef = scrollToRef.current;
        if (currScrollRef) {
            setMaxScrollRight(currScrollRef.scrollLeft === 0);

            setMaxScrollLeft(currScrollRef.scrollLeft + currScrollRef.clientWidth >= currScrollRef.scrollWidth);
        }
    };

    const scrollOnFocus = () => {
        if (document.activeElement) {
            document.activeElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        trackFilterChange(setSearchParams);
        updateScrollMax();
        const currScrollRef = scrollToRef.current;

        if (currScrollRef) {
            setMaxScrollLeft(currScrollRef.scrollWidth <= currScrollRef.clientWidth);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currScrollRef = scrollToRef.current;

        window.addEventListener('resize', updateScrollMax);

        if (currScrollRef) {
            currScrollRef.addEventListener('scroll', updateScrollMax);
            currScrollRef.addEventListener('focus', scrollOnFocus);
        }

        return () => {
            if (currScrollRef) {
                currScrollRef.removeEventListener('scroll', updateScrollMax);
                currScrollRef.removeEventListener('focus', scrollOnFocus);
            }
            window.removeEventListener('resize', updateScrollMax);
        };
    }, [scrollToRef]);

    useEffect(() => {
        if (searchParams === undefined) {
            setMarketShown('Hele markedet');
        } else if (searchParams.toString() !== '') {
            setMarketShown('Valgte filtre');
            const manufacturers = searchParams.get('manufacturer');

            if (manufacturers !== null) {
                const unique = Array.from(new Set(manufacturers.split(',')));
                setShowWarningMessage(unique.length > 1);
            } else {
                setShowWarningMessage(false);
            }
        }
    }, [searchParams]);

    const handleScroll = (scrollLeft: boolean) => {
        const currScrollRef = scrollToRef.current;
        if (currScrollRef) {
            currScrollRef.scrollLeft += currScrollRef.clientWidth * (scrollLeft ? 1 : -1);
        }
    };
    const trackArrowClick = (direction: string) => {
        // ISP_ev29
        trackEvent('Show', 'Click', 'Button', `${direction} - scroll cards arrow`);
    };

    return (
        <div className="flex flex-col">
            <p className="padded-x mb-24">
                <span className="font-bold m-0">Få et raskt overblikk over bruktbilmarkedet på FINN.no.</span>
                <br />
                Bruk filtrene for spesifikke søk, og få detaljert markedsdata om bilene.
            </p>

            <div className="inline-flex flex-row items-center justify-between padded-x mb-10">
                <div className="inline-flex items-center gap-4">
                    <p className="text-14 m-0">
                        Viser for <b>{marketShown}</b>
                    </p>
                </div>
                <div className="flex flex-nowrap">
                    <Button
                        pill
                        small
                        onClick={() => {
                            if (!maxScrollRight) {
                                trackArrowClick('left');
                                handleScroll(false);
                            }
                        }}
                        className={`arrow text-blue-600 ${!maxScrollRight ? 'active' : 'inactive'}`}
                        style={{
                            cursor: !maxScrollRight ? 'pointer' : 'default',
                        }}
                        tabIndex={!maxScrollRight ? 0 : -1}
                    >
                        <IconTableSortDown16 />
                    </Button>
                    <Button
                        pill
                        small
                        onClick={() => {
                            if (!maxScrollLeft) {
                                trackArrowClick('right');
                                handleScroll(true);
                            }
                        }}
                        className={`ml-6 arrow text-blue-600 ${!maxScrollLeft ? 'active' : 'inactive'}`}
                        style={{
                            cursor: !maxScrollLeft ? 'pointer' : 'default',
                        }}
                        tabIndex={!maxScrollLeft ? 0 : -1}
                    >
                        <IconTableSortUp16 />
                    </Button>
                </div>
            </div>
            <CardSlider scrollToRef={scrollToRef} mapUrl={dataSource} />
            <Alert className="mx-24 mt-16" type="warning" show={showWarningMessage}>
                <p className="font-bold">Du har valgt mer enn ett bilmerke</p>{' '}
                <p>
                    Vi støtter dessverre ikke å velge mer enn ett bilmerke om gangen. Dataen kan bli feil, og derfor
                    viser vi den ikke. Ta ett om gangen, så skal det funke helt fint!
                </p>
            </Alert>
        </div>
    );
};

export default MarketView;
