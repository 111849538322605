import React from 'react';
import NoDataAlert from '../NoDataAlert';
import Spinner from '../Spinner';
import { CardData } from '../../helpers/types';

const Card = ({
    children,
    isLoading,
    hasData,
    title,
    subTitle,
    toolTipModal,
    backgroundColor,
    borderColor,
}: CardData & {
    children?: React.ReactNode;
    title?: string;
    subTitle?: string;
    toolTipModal?: React.ReactNode;
    backgroundColor?: string;
    borderColor?: string;
}) => {
    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            {hasData ? (
                <div
                    className={`card border relative`}
                    tabIndex={0}
                    style={{ backgroundColor: backgroundColor || '#FFFFF', borderColor }}
                >
                    {title && (
                        <div className={`mt-16 mb-4 ${toolTipModal ? 'ml-16 mr-12' : 'mx-16'}`}>
                            <div className="flex flex-row items-center w-full justify-between">
                                <p className="text-12 font-bold m-0">{title}</p>
                                {toolTipModal}
                            </div>
                            {subTitle && <p className="text-12 m-0">{subTitle}</p>}
                        </div>
                    )}
                    <>{children}</>
                </div>
            ) : (
                <NoDataAlert />
            )}
        </>
    );
};

export default Card;
