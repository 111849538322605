import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';
import { activeAdsIsValid, mapActiveAdsToGraphData, numberWithSpaces } from '../../helpers/functions';
import { ActiveAds, ActiveAdsGraphPoint, CardData } from '../../helpers/types';
import Card from './Card';
import ActiveAdsTooltip from '../Graphs/Tooltip/ActiveAdsTooltip';
import ActiveAdsModal from '../Modal/ActiveAdsModal';

const ActiveAdsCard = ({
    activeAdsData,
    isLoading,
    hasData,
    showPriv,
    showPro,
}: CardData & {
    activeAdsData: ActiveAds | undefined;
    showPriv: boolean;
    showPro: boolean;
}) => {
    let graphData: ActiveAdsGraphPoint[] = [];

    if (activeAdsIsValid(activeAdsData) && activeAdsData) {
        graphData = mapActiveAdsToGraphData(
            activeAdsData.activeAdsPrivate,
            activeAdsData.activeAdsPro,
            showPriv,
            showPro,
        );
    }

    const yesterdayPrivate = activeAdsData?.activeAdsYesterdayPrivate ?? 0;
    const yesterdayPro = activeAdsData?.activeAdsYesterdayPro ?? 0;
    const total = yesterdayPro + yesterdayPrivate;

    return (
        <Card isLoading={isLoading} hasData={hasData} title={'FINN-annonser'} toolTipModal={<ActiveAdsModal />}>
            <div className="flex flex-col h-full justify-between">
                <div className="px-16">
                    <div>
                        {showPro && (
                            <p className="text-22 font-bold m-0">
                                <span className="text-blue-500">{numberWithSpaces(yesterdayPro)}</span> i går
                            </p>
                        )}
                        {showPriv && !showPro && (
                            <p className="text-22 font-bold m-0">
                                <span style={{ color: '#C03BC4' }}>{numberWithSpaces(yesterdayPrivate)}</span> i går
                            </p>
                        )}
                        {showPro && showPriv && (
                            <p className="text-12">
                                <span className="font-bold">{numberWithSpaces(total)}</span> inkl.{' '}
                                <span className="font-bold" style={{ color: '#C03BC4' }}>
                                    {yesterdayPrivate ? numberWithSpaces(yesterdayPrivate) : 0}
                                </span>{' '}
                                private
                            </p>
                        )}
                    </div>
                    <p className="font-light text-12 m-0">Antall aktive bilannonser på FINN.no i går.</p>
                </div>
                {graphData.length > 0 && (
                    <ResponsiveContainer width={'100%'} maxHeight={80}>
                        <AreaChart
                            data={graphData.reverse()}
                            margin={{
                                top: 10,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                            style={{ cursor: 'pointer', position: 'none' }}
                        >
                            <defs>
                                <pattern
                                    id="stripes"
                                    preserveAspectRatio="xMaxYMax"
                                    viewBox="0,0,20,20"
                                    width="8%"
                                    height="8%"
                                    patternTransform="rotate(-45 0 0)"
                                    patternUnits="userSpaceOnUse"
                                >
                                    <line x1="0" y1="0" x2="20" y2="20" stroke="#df4be1" strokeWidth="38" />
                                    <line x1="-20" y1="0" x2="-20" y2="50" stroke="#e6ace7" strokeWidth="55" />
                                </pattern>
                            </defs>
                            <Tooltip
                                allowEscapeViewBox={{
                                    x: true,
                                    y: true,
                                }}
                                wrapperStyle={{
                                    zIndex: 10,
                                }}
                                cursor={{ stroke: '#3F3F46', strokeDasharray: 5 }}
                                content={<ActiveAdsTooltip />}
                            />
                            {showPro && (
                                <Area
                                    type="linear"
                                    key={`area3`}
                                    dataKey={'forhandlere'}
                                    stroke="#3291ED"
                                    stackId="1"
                                    strokeWidth="2"
                                    fill="#D6E2FB"
                                />
                            )}
                            {showPriv && (
                                <Area
                                    key={'area1'}
                                    type="linear"
                                    dataKey={'private'}
                                    stroke="#C03BC4"
                                    stackId="1"
                                    strokeWidth="2"
                                    fill="url(#stripes)"
                                    fillOpacity={0.4}
                                />
                            )}
                        </AreaChart>
                    </ResponsiveContainer>
                )}
            </div>
        </Card>
    );
};

export default ActiveAdsCard;
