import React from 'react';
import MapLegend from './MapLegend';
import Map from './Map';
import { CardData, PublishingTimeMap } from '../../../helpers/types';
import Card from '../../cards/Card';

const MapCardContent = ({
    data,
    hideData,
    hasData,
    isLoading,
}: {
    data: PublishingTimeMap[] | undefined;

    hideData: boolean;
} & CardData) => (
    <Card
        title="Hvor fort selger bilen?"
        subTitle="Median forhandler/fylke"
        hasData={hasData && !hideData}
        isLoading={isLoading}
    >
        {data && (
            <div className="relative">
                <MapLegend />
                <Map data={data} />
            </div>
        )}
    </Card>
);

export default MapCardContent;
