import React from 'react';
import { calculatePercentShare } from '../../helpers/functions';

const ChartCarsInAndOut = ({ carsIn, carsOut }: { carsIn: number; carsOut: number }) => {
    const carPercentageShare = calculatePercentShare(carsIn, carsOut);

    return (
        <div className="flex flex-col">
            <div style={{ height: 24, width: '100%' }} className="flex gap-2 relative">
                <div
                    style={{
                        backgroundColor: '#2271BF',
                        height: 'inherit',
                        width: `${carPercentageShare.carsInPercent}%`,
                    }}
                    className="rounded-2"
                ></div>
                <div
                    className="dashed-line"
                    style={{
                        borderColor:
                            carPercentageShare.carsInPercent >= '49' && carPercentageShare.carsInPercent <= '51'
                                ? 'black'
                                : 'white',
                    }}
                ></div>
                <div
                    style={{
                        backgroundColor: '#D56F1B',
                        height: 'inherit',
                        width: `${carPercentageShare.carsOutPercent}%`,
                    }}
                    className="rounded-2"
                ></div>
            </div>
            <div className="flex justify-between mt-4 text-12">
                <p className="mb-24">
                    <span className="font-bold">{carsIn}</span> inn
                </p>
                <p className="mb-24">
                    <span className="font-bold">{carsOut}</span> ut
                </p>
            </div>
        </div>
    );
};

export default ChartCarsInAndOut;
