import React from 'react';
import PaintingGraphs from '../../lib/PaintingGraphsImg';

const MoreContentComing = () => (
    <>
        <div className={`dashedCard overflow-hidden flex flex-col`} tabIndex={0}>
            <div className="mx-auto mt-32">
                <PaintingGraphs />
            </div>
            <div className="text-center m-12">
                <p className="text-12 font-bold mb-6">Mer innsikt på vei!</p>

                <p className="text-12">Vi må bare bygge det ferdig først, og se at det virker.</p>
            </div>
        </div>
    </>
);

export default MoreContentComing;
