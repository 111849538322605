import pulse from '@finn-no/pulse-sdk';
import { TrackingType, IntentType, TrackingElementType } from '../helpers/types';

const NAME = 'ISP podlet searchpage';
const VERTICAL = 'motor';
const SUBVERTICAL = 'isp_motor';
const ID = 'isp-search-podlet';

const trackEvent = (intent: IntentType, eventType: TrackingType, elementType: TrackingElementType, name = '') => {
    pulse.trackEvent({
        type: eventType,
        intent,
        name: NAME,
        object: {
            id: ID,
            type: 'UIElement',
            elementType,
            name,
        },
        vertical: {
            name: VERTICAL,
            subVertical: SUBVERTICAL,
        },
    });
};
export default trackEvent;
