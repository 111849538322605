import React, { useEffect, useRef } from 'react';
import { Button } from '@fabric-ds/react';
import { IconQuestion16 } from '@fabric-ds/icons/react';
import { assertIsNode } from '../../helpers/functions';
import trackEvent from '../../lib/tracking';

const InfoModal = ({
    children,
    isOpen,
    setIsOpen,
    name,
}: {
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    name: string;
}) => {
    const refElement = useRef<HTMLDivElement | null>(null);
    const openReadMoreModal = () => {
        setIsOpen(true);
        // ISP_ev27
        trackEvent('View', 'Click', 'Button', `Open tooltip ${name}`);
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const { target } = event;
            assertIsNode(target);
            if (refElement.current && !refElement.current.contains(target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup: remove event listener on unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [refElement, setIsOpen]);

    return (
        <div ref={refElement} className="h-16 w-16 flex">
            <Button
                onClick={openReadMoreModal}
                className="m-0 p-0 border-0 h-16 w-16"
                aria-label="åpen les mer"
                style={{ minWidth: 16 }}
            >
                <IconQuestion16 className="text-blue-600" />
            </Button>

            {isOpen && <div className="info-modal bg-white">{children}</div>}
        </div>
    );
};

export default InfoModal;
