import React, { createContext, useContext, useState } from 'react';

const ParamsContext = createContext<
    | {
          searchParams: URLSearchParams | undefined;
          setSearchParams: React.Dispatch<React.SetStateAction<URLSearchParams | undefined>>;
      }
    | undefined
>(undefined);
const useParams = () => {
    const paramsContext = useContext(ParamsContext);

    if (paramsContext === undefined) {
        throw new Error('useMarketShown must be used within a MarketShownProvider');
    }

    return paramsContext;
};
const ParamsProvider = ({ children }: { children: React.ReactNode }) => {
    const [searchParams, setSearchParams] = useState<URLSearchParams | undefined>(new URLSearchParams());

    return <ParamsContext.Provider value={{ searchParams, setSearchParams }}>{children}</ParamsContext.Provider>;
};

export { ParamsContext, useParams, ParamsProvider };
