import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import React from 'react';
import { PublishTimeData } from '../../helpers/types';
import PublishTimeTooltip from './Tooltip/PublishTimeTooltip';

const PublishTime = ({ data }: { data: PublishTimeData }) => (
    <ResponsiveContainer width={'100%'}>
        <AreaChart
            data={data.graphPublishTime}
            height={80}
            margin={{
                top: 5,
                right: 0,
                bottom: 0,
                left: 0,
            }}
            style={{ cursor: 'pointer', position: 'none' }}
        >
            <defs>
                <linearGradient id="colorPublishTime" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#D6E2FB" stopOpacity={0.8} />
                    <stop offset="85%" stopColor="#D6E2FB" stopOpacity={0.2} />
                    <stop offset="90%" stopColor="#D6E2FB" stopOpacity={0.1} />
                </linearGradient>
            </defs>
            <Tooltip
                allowEscapeViewBox={{
                    x: true,
                    y: true,
                }}
                wrapperStyle={{
                    zIndex: 10,
                }}
                cursor={{ stroke: '#3F3F46', strokeDasharray: 5 }}
                content={<PublishTimeTooltip />}
            />
            <Area
                type="linear"
                key={`area1`}
                dataKey={'publishTime'}
                stroke="#3291ED"
                stackId="1"
                strokeWidth="2"
                fill="url(#colorPublishTime)"
            />
        </AreaChart>
    </ResponsiveContainer>
);

export default PublishTime;
